<template>
  <CCard class="send_moment_card pointer" @click="openLoyaltyMomentsPage();">
    <CCardBody class="p-0">
      <div class="d-flex align-items-center justify-content-center card_content_container">        
        <div class="w-100 card_background">
          <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/spark.png/' + clientToken"/>            
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center p-3 content">
          <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/spark.png/' + clientToken" class="mb-2"/>
          <span><b>{{$t('sm.Show_all_sparks')}}</b></span>
        </div>
      </div>
    </CCardBody>
  </CCard> 
</template>

<script>

export default {
  name: 'showAllLoyaltyMomentsCard',
  props: ['platformPermissions', 'modules'],
  data() {
    return {
      cdnBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null
    }
  },
  methods: {
    openLoyaltyMomentsPage() {
      this.$router.push('/loyalty/moments')
    }
  },
  mounted: function() {        
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');      
  }  
}
</script>