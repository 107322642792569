<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('insights.crossover.participation')">
    <CCol col="12" xl="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{ $t('insights.Overview_participation') }}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{ $t('common.activate_search') }}</b-switch>
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 link" color="primary" @click="exportData('csv')">
                  <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_csv') }}</span>
                </CButton>                
                <CButton class="ml-2 link" color="primary" @click="exportData('json');">
                  <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
                </CButton>
              </div>              
            </CCol>  
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pb-0 pt-0">
              <b-table 
                class="data_table" style="overflow: auto !important;" :data="audienceParticipation" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition" :default-sort-direction="defaultSortDirection" :sort-icon="sortIcon" :sort-icon-size="sortIconSize" default-sort="Name">
                <template slot-scope="props">

                  <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" :sortable="true">
                    <b>{{props.row.name}}</b>
                  </b-table-column>

                  <b-table-column field="function" :label="$t('common.Function')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.function}}
                  </b-table-column>

                  <b-table-column field="department" :label="$t('common.Department')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.department}}
                  </b-table-column>

                  <b-table-column field="team" :label="$t('common.Team')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.team}}
                  </b-table-column>

                  <b-table-column field="user_active" :label="$t('common.Active')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.user_active}}
                  </b-table-column>

                  <b-table-column field="current_activity_count" :label="$t('insights.Current_activity')" :searchable="false" :sortable="true">
                    {{props.row.current_activity_count}}
                  </b-table-column>

                  <b-table-column field="current_activity_threshold" :label="$t('common.Threshold')" :searchable="false" :sortable="true">
                    {{props.row.current_activity_threshold}}
                  </b-table-column>

                  <b-table-column field="total_activity_count" :label="$t('insights.Total_activities')" :searchable="false" :sortable="true">
                    {{props.row.total_activity_count}}
                  </b-table-column>

                  <b-table-column field="last_new_login" :label="$t('common.Last_login')" :searchable="false" :sortable="true">
                    {{props.row.last_new_login}}
                  </b-table-column>

                  <b-table-column field="last_sw_post_viewed" :label="$t('insights.Last_seen_sw_post')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.last_sw_post_viewed}}
                  </b-table-column>

                  <b-table-column field="last_sw_post_viewed_at" :label="$t('insights.Post_seen_at')" :searchable="false" :sortable="true">
                    {{props.row.last_sw_post_viewed_at}}
                  </b-table-column>

                  <b-table-column field="last_lms_step" :label="$t('insights.Last_lms_step')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.last_lms_step}}
                  </b-table-column>

                  <b-table-column field="last_lms_step_started_at" :label="$t('insights.LMS_step_started')" :searchable="false" :sortable="true">
                    {{props.row.last_lms_step_started_at}}
                  </b-table-column>

                  <b-table-column field="last_lms_step_finished_at" :label="$t('insights.LMS_step_finished')" :searchable="false" :sortable="true">
                    {{props.row.last_lms_step_finished_at}}
                  </b-table-column>

                  <b-table-column field="current_loyalty_points" :label="$t('insights.Loyalty_points')" :searchable="searchEnabled" :sortable="true">
                    {{props.row.current_loyalty_points}}
                  </b-table-column>

                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('insights.Loading_insights')"/>
                    <span v-else>{{ $t('insights.Statistics_not_found') }}</span>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'InsightsParticipation',
  components: {
    loadingSpinner,
    noPermission
  },
  data: () => {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      audienceParticipation: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: false
    }
  },
  methods: {
    getAudienceParticipation () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/audience/participation')
      .then(res => {
        this.audienceParticipation = res.data.data;
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    exportData(outputFormat){
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/audience/participation/export/' + outputFormat)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_ready'), type: 'is-success', duration: 2000 });
        if (!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.URL.createObjectURL(new Blob([outputData]));  
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.URL.createObjectURL(new Blob([outputData]));
          }
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute based on the output format
          if(outputFormat === 'csv') {
            link.setAttribute('download', 'insights-audience-participation.csv');
          } else if(outputFormat === 'json') {
            link.setAttribute('download', 'insights-audience-participation.json');
          }
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-participation.csv");
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-participation.json");
          }
        }
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getAudienceParticipation();
    
    this.$bus.$on('filter_updated', (e) => {
      this.getAudienceParticipation();
    });    
  },
  beforeDestroy() {
    this.$bus.$off('filter_updated');
  }  
}
</script>
