<template>
  <CCard :id="'loyalty_moment_card_' + momentData.loyalty_moment_id_external" class="moment loyalty moment_card" :class="{'old_style' : momentData.old_style, 'custom_design' : momentData.custom_design}">
    <CCardBody class="p-0">
      <div ref="momentContainer" class="moment_container">
        <div class="moment_content" :class="{ 'flipped': isFlipped }">
          <!-- Front side -->
          <div class="front" @click.stop="toggleFlip">
            <div class="flip_to_back">
              <span v-if="!momentData.old_style" class="mr-1">{{$t('common.Tap_to_flip')}}</span>
              <div class="icon position-relative d-flex align-items-center justify-content-center">
                <i class="fas fa-rotate"/>
                <i v-if="momentData.old_style" class="fas fa-slash"/>
              </div>
            </div>                        
            <div ref="momentCardContainer" class="moment_card_container h-100 w-100 m-0">
              <div ref="momentCard" 
                   class="moment_card"
                   v-html="momentData.html"
                   v-show="isContentLoaded"
                   @load="handleContentLoad">
              </div>
            </div>
                        
            <div v-if="showEditButton || showDeleteButton" class="moment_actions d-flex flex-row">
              <CButton v-if="showEditButton" 
                       @click.stop="openSidebarRight('edit_loyalty_moment', { modules: modules, platform_permissions: platformPermissions, loyalty_moment_id_external:  momentData.loyalty_moment_id_external });"
                       class="d-flex align-items-center" 
                       color="primary">
                <i class="fa-solid fa-pen"></i>
              </CButton>
              <CButton v-if="showDeleteButton" 
                       @click.stop="deleteLoyaltyMoment(momentData)" 
                       class="d-flex align-items-center m-0" 
                       color="primary">
                <i class="fa-solid fa-trash"></i>
              </CButton>
            </div>

            <div v-if="showInsights && !momentData.old_style && !momentData.custom_design" class="moment_content_overlay">
              <div v-if="momentData.total_recipients > 1" class="h-100 d-flex align-items-center justify-content-center">
                <span class="ml-2">{{momentData.total_recipients}} {{momentData.total_recipients > 1 ? $t('common.recipients') : $t('common.recipient')}} | {{momentData.total_filters}} {{momentData.total_filters > 1 ? $t('common.filters') : $t('common.filter')}}</span>
              </div>
              <div v-else-if="momentData.total_recipients === 1 && momentData.recipients[0]" class="h-100 d-flex align-items-center justify-content-center">
                <div v-if="momentData.recipients[0].profile_image" 
                     class="profile_image"
                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + momentData.recipients[0].user_id_external + '/' + momentData.recipients[0].profile_image + '/' + clientToken + ')' }">
                </div>
                <div v-else-if="momentData.recipients[0].name">
                  <userProfileAvatar :username="momentData.recipients[0].name" :size="25"/>
                </div>
                <span class="ml-2">{{momentData.recipients[0].name}}</span>           
              </div>
            </div>

            <div v-if="momentData.old_style" class="moment_content_overlay">
              <CRow class="h-100 m-0">
                <CCol cols="8" xl="8">
                  <div class="h-100 d-flex align-items-center justify-content-start">
                    <div v-if="momentData.sender" class="d-flex align-items-center moment_sender">
                      <div v-if="cdnBaseUrl"
                          class="profile_image mr-1"
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-sender/' + momentData.sender.loyalty_moment_sender_id_external + '/' + momentData.sender.profile_image_id + '/' + clientToken + ')' }">                      
                      </div>
                      <div v-else-if="momentData.sender.sender_alias" class="profile_icon mr-1">
                        <userProfileAvatar :username="momentData.sender.sender_alias" :size="25"/>
                      </div>
                      <span v-line-clamp="1">{{momentData.sender.sender_alias}}</span>
                    </div>                                    
                    <div v-else class="d-flex align-items-center moment_sender">                  
                      <div v-if="momentData.sent_by_system_account == 'Y' && cdnBaseUrl" 
                          class="profile_image mr-1" 
                          v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }">
                      </div>
                      <div v-else-if="momentData.sent_by_system_account !== 'Y' && momentData.sent_by_profile_image" 
                          class="profile_image mr-1"
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + momentData.sent_by_user_id_external + '/' + momentData.sent_by_profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="momentData.sent_by_name" class="profile_icon mr-1">
                        <userProfileAvatar :username="momentData.sent_by_name" :size="25"/>
                      </div>                      
                      <span v-line-clamp="1">{{momentData.sent_by_name}}</span>
                    </div>                    
                  </div>
                </CCol>
                <CCol cols="4" xl="4">
                  <div class="h-100 d-flex align-items-center justify-content-end">
                    <span v-if="momentData.sent">{{momentData.sent | moment("DD-MM-YYYY")}}</span>
                    <span v-else-if="momentData.scheduled_for">{{momentData.scheduled_for | moment("DD-MM-YYYY")}}</span>
                  </div>
                </CCol>                               
              </CRow>
            </div>
          </div>

          <!-- Back side -->
          <div v-if="!momentData.old_style" class="back d-flex flex-column justify-content-start" @click.stop="toggleFlip">
            <!-- <div class="flip_to_front">
              <span>{{$t('common.Tap_to_flip')}}</span>
              <div class="icon position-relative display-flex align-items-center justify-content-center ml-1">
                <i class="fas fa-rotate"/>
                <i v-if="momentData.old_style" class="fas fa-slash"/>
              </div>
            </div> -->
            <div class="w-100">
              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12" md="12" sm="12" class="pb-0">
                  <div class="d-flex">
                    <div class="flex-grow-1 moment_date">
                      <span v-if="momentData.sent">{{momentData.sent | moment("DD-MM-YYYY")}}</span>
                      <span v-else-if="momentData.scheduled_for">{{momentData.scheduled_for | moment("DD-MM-YYYY")}}</span>
                    </div>
                    <div class="stamp d-flex flex-grow-1 justify-content-end" :class="{'position-absolute' : !momentData.custom_message}">
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/logo/harryhr_stamp.png/' + clientToken"/>
                    </div>
                  </div>
                </CCol>             
              </CRow>
            </div>

            <div v-if="momentData.custom_message" class="w-100 d-flex flex-grow-1 moment_custom_message">
              <CRow class="h-100 w-100 m-0">
                <CCol cols="12" lg="12" md="12" sm="12">
                  <div class="h-100 w-100 text-center position-relative">
                    <div class="h-100 w-100 d-flex align-items-center justify-content-center custom_message_container">
                      <span class="text-center">{{momentData.custom_message}}</span>                      
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>

            <div class="w-100 moment_details">
              <CRow v-if="showInsights && momentData.recipients.length > 0" class="w-100 m-0">
                <CCol cols="12" lg="12" md="12" sm="12" class="pb-0">
                  <div class="d-flex align-items-center">
                    <div class="h-100 d-flex align-items-center label_container">
                      <label class="m-0"><b>{{momentData.recipients.length !== 1 ? $t('common.Recipients') : $t('common.Recipient')}}:</b></label>
                    </div>
                    <div class="d-flex align-items-center moment_recipients">
                      <div v-for="(recipient, index) in momentData.recipients.slice(0, 5)" :key="index" class="recipient">
                        <div v-if="recipient.profile_image" 
                            class="profile_image" 
                            v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + recipient.user_id_external + '/' + recipient.profile_image + '/' + clientToken + ')' }">
                        </div>
                        <div v-else class="profile_icon">
                          <userProfileAvatar :username="recipient.name" :size="30"/>
                        </div>
                      </div>
                      <div v-if="momentData.recipients.length > 5" class="ml-1">
                        <span><b>{{momentData.recipients.length - 5}}+</b></span>
                      </div>
                      <div @click.stop="setMomentRecipientsPagination(); momentRecipientsModal = true;" class="ml-1 pointer">
                        <span>{{$t('common.Details')}}</span>
                      </div>
                    </div>                  
                  </div>
                </CCol>
              </CRow>

              <CRow class="w-100 m-0">
                <CCol cols="12" lg="12" md="12" sm="12" class="pb-0">
                  <div class="d-flex align-items-center">
                    <div class="h-100 d-flex align-items-center label_container">
                      <label class="m-0"><b>{{$t('common.Sender')}}:</b></label>
                    </div>
                    <div v-if="momentData.sender" class="d-flex align-items-center moment_sender">
                      <div v-if="cdnBaseUrl"
                          class="profile_image mr-1"
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-sender/' + momentData.sender.loyalty_moment_sender_id_external + '/' + momentData.sender.profile_image_id + '/' + clientToken + ')' }">                      
                      </div>
                      <div v-else-if="momentData.sender.sender_alias" class="profile_icon mr-1">
                        <userProfileAvatar :username="momentData.sender.sender_alias" :size="25"/>
                      </div>
                      <span>{{momentData.sender.sender_alias}}</span>
                    </div>                                    
                    <div v-else class="d-flex align-items-center moment_sender">                  
                      <div v-if="momentData.sent_by_system_account == 'Y' && cdnBaseUrl" 
                          class="profile_image mr-1" 
                          v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }">
                      </div>
                      <div v-else-if="momentData.sent_by_system_account !== 'Y' && momentData.sent_by_profile_image" 
                          class="profile_image mr-1"
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + momentData.sent_by_user_id_external + '/' + momentData.sent_by_profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="momentData.sent_by_name" class="profile_icon mr-1">
                        <userProfileAvatar :username="momentData.sent_by_name" :size="25"/>
                      </div>                      
                      <span>{{momentData.sent_by_name}}</span>
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow class="w-100 m-0 mt-2_5">
                <CCol cols="12" lg="12" md="12" sm="12">
                  <div class="d-flex align-items-center">
                    <div v-if="momentData.grant_points && momentData.points > 0" class="d-flex flex-grow-1 align-items-center currency">
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken" class="mr-1"/>
                      <span class="d-flex align-items-center">
                        <span class="count mr-1">{{momentData.points}}</span> {{momentData.points !== 1 ? $t('common.coins') : $t('common.coin')}}
                      </span>
                    </div>
                    <div v-if="showInsights" class="h-100 d-flex flex-grow-1 align-items-center reach" :class="{'justify-content-end' : momentData.grant_points && momentData.points > 0}">
                      <div class="position-relative">
                        <i class="icon top far fa-eye mr-1"/>
                        <div v-if="momentData.recipients.length === 1" class="reach_status">
                          <i v-if="momentData.insights.reach.percentage === 100" class="fas fa-check-circle"/>
                          <i v-else class="fas fa-times-circle"/>
                        </div>
                      </div>
                      <div v-if="momentData.recipients.length > 1" class="d-flex align-items-center">
                        <span class="count mr-1">{{(momentData.insights.reach.percentage).toFixed(0)}}%</span> ({{momentData.insights.reach.count}}/{{momentData.insights.reach.targeted}})
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>      
     
      <b-modal :can-cancel="['x']" :active.sync="momentRecipientsModal" :width="960" scroll="keep">
        <CCard class="m-0">
          <CCardHeader>
            <span>{{$t('loyalty.Moment_recipients')}} ({{momentData.recipients.length}})</span>
          </CCardHeader>
          <CCardBody class="pt-0 pb-0">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="p-0" v-bind:class="{ 'pb-0' : listViewEnabled === false || isPaginated === false }">                      
                <div class="d-flex align-items-center" v-bind:class="{ 'mb-2_5' : listViewEnabled === true }">
                  <b-switch v-if="momentData.recipients.length > 0" 
                           class="mb-0" 
                           v-model="listViewEnabled" 
                           size="is-small">
                    {{ $t('common.activate_list_view') }}
                  </b-switch>
                </div>
                <div v-if="listViewEnabled">
                  <CRow>
                    <CCol cols="12" sm="12" md="12" lg="12" class="p-0">
                      <b-table class="data_table" 
                               :data="momentData.recipients"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="isPaginated"
                               :per-page="perPage"
                               :current-page.sync="currentPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition">
                        
                        <template slot-scope="props">
                          <b-table-column field="name" :label="$t('common.Name')">                            
                            <div v-if="props.row.profile_image" 
                                 class="profile_image d-inline-block align-middle mr-2" 
                                 v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }">
                            </div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </b-table-column>
                          <b-table-column field="department_name" :label="$t('common.Department')">
                            <span>{{props.row.department_name}}</span>
                          </b-table-column>                        
                          <b-table-column field="team_name" :label="$t('common.Team')">
                            <span>{{props.row.team_name}}</span>
                          </b-table-column>                        
                        </template>
                      </b-table>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow class="tile-container">
                    <CCol cols="3" xl="3" lg="3" md="3" sm="6" 
                          v-for="recipient in currentMomentRecipients[currentMomentRecipientsPage - 1]" 
                          v-bind:key="recipient.user_id_external" 
                          class="p-0">
                      <userProfileCard :userData="recipient" class="member"/>
                    </CCol>
                  </CRow>
                  <hr v-if="momentData.recipients.length > momentRecipientsPerPage" class="m-0">
                  <CRow v-if="momentData.recipients.length > momentRecipientsPerPage">
                    <CCol cols="12" md="12">
                      <v-pagination class="justify-content-end" 
                                  v-model="currentMomentRecipientsPage" 
                                  :length="momentRecipientsPages" 
                                  :total-visible="9" 
                                  prev-icon="mdi-chevron-left" 
                                  next-icon="mdi-chevron-right">
                      </v-pagination>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            </CRow>  
          </CCardBody>
          <CCardFooter>          
            <CButton color="secondary" @click="momentRecipientsModal = false">
              <i class="fas fa-times mr-1"/>{{$t('close')}}
            </CButton>
          </CCardFooter>
        </CCard>
      </b-modal>

    </CCardBody>
  </CCard>
</template>

<script>
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import userProfileCard from "@/components/common/userProfileCard.vue";

export default {
  name: 'loyaltyMomentCard',
  props: ['modules', "platformPermissions", "momentData", "showEditButton", "showDeleteButton", "showInsights"],
  components: {
    userProfileAvatar,
    userProfileCard
  },
  data() {
    return {
      cdnBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,    
      environmentTag: null,
      isFlipped: false,
      isContentLoaded: false,
      updateTimer: null,
      heightUpdateAttempts: 0,
      maxHeightUpdateAttempts: 5,
      minHeight: 450,
      minHeightOldStyle: 250,
      momentRecipientsModal: false,
      currentMomentRecipientsPage: 1,
      currentMomentRecipients: {},
      momentRecipientsPerPage: 12,
      momentRecipientsPages: 0,    
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      contentObserver: null
    }
  },
  methods: {
    getLogoSrc() {
      if(!this.companyImageId) {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/logo/${this.environmentTag}_logo.png/${this.clientToken}`;
      } else {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/${this.companyIdExternal}/${this.companyImageId}.png/${this.clientToken}`;
      }
    },
    openLoyaltyMomentDetails(momentIdExternal) {
      this.$router.push({ path: '/loyalty/moment/' + momentIdExternal });
    },
    deleteLoyaltyMoment(momentData) {
      this.$bus.$emit('delete_loyalty_moment', momentData);      
    },
    handleContentLoad() {
      this.isContentLoaded = true;
      this.updateCardHeight();
    },
    updateCardHeight() {
      if(this.updateTimer) clearTimeout(this.updateTimer);

      const updateHeight = () => {
        const momentCard = this.$refs.momentCard;
        const momentContainer = this.$refs.momentContainer;
        
        if (!momentCard || !momentContainer) {
          if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
          return;
        }

        // Get all images within the moment card
        const images = momentCard.getElementsByTagName('img');
        const imagePromises = Array.from(images).map(img => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = () => resolve();
              img.onerror = () => resolve(); // Handle failed image loads
            }
          });
        });

        // Wait for all images to load before calculating height
        Promise.all(imagePromises).then(() => {
          const minHeight = (this.momentData.old_style || this.momentData.custom_design) ? this.minHeightOldStyle : this.minHeight;
          const height = Math.max(momentCard.offsetHeight, minHeight);

          if (height > 0) {
            momentContainer.style.height = `${height}px`;
            this.isContentLoaded = true;
          } else if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
        });
      };

      this.$nextTick(updateHeight);
    },
    resetHeightUpdate() {
      this.heightUpdateAttempts = 0;
      this.isContentLoaded = false;
      this.updateCardHeight();
    },
    setMomentRecipientsPagination() {
      // Reset pagination to first page
      this.currentMomentRecipientsPage = 1;
      this.momentRecipientsPages = 0;
      
      // Calculate pages based on recipients
      for (let i = 0; i < this.momentData.recipients.length; i += this.momentRecipientsPerPage) {
        this.currentMomentRecipients[this.momentRecipientsPages] = this.momentData.recipients.slice(i, i + this.momentRecipientsPerPage);
        this.momentRecipientsPages++;
      }
    },
    toggleFlip() {
      if(!this.momentData.old_style) {
        this.isFlipped = !this.isFlipped;

        const targetElement = document.querySelector('#loyalty_moment_card_' + this.momentData.loyalty_moment_id_external);
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });        
      }
    },
    setupContentObserver() {
      // Cleanup existing observer if any
      if(this.contentObserver) this.contentObserver.disconnect();

      // Create new observer
      this.contentObserver = new MutationObserver((mutations) => {
        let shouldUpdate = false;
        mutations.forEach(mutation => {
          if(mutation.type === 'childList' || mutation.type === 'characterData' || mutation.type === 'attributes') shouldUpdate = true;
        });

        if(shouldUpdate) this.updateCardHeight();
      });

      // Start observing if momentCard exists
      if (this.$refs.momentCard) {
        this.contentObserver.observe(this.$refs.momentCard, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true
        });
      }
    }
  },
  watch: {
    'momentData.html': {
      handler() {
        this.resetHeightUpdate();
      },
      immediate: true
    },
    isContentLoaded(newVal) {
      if(newVal) {
        this.updateCardHeight();
      }
    }
  },
  mounted() {
    // Initialize environment variables
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');

    // Load company details
    if(localStorage.getItem('companyIdExternal')) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId')) this.companyImageId = localStorage.getItem('companyImageId');
    if(localStorage.getItem('environmentTag')) this.environmentTag = localStorage.getItem('environmentTag');

    // Setup observers and event listeners
    this.$nextTick(() => {
      this.setupContentObserver();
      this.resetHeightUpdate();
    });
    
    // Add resize listener
    window.addEventListener('resize', this.updateCardHeight);
  },
  beforeDestroy() {
    // Cleanup
    window.removeEventListener('resize', this.updateCardHeight);
    
    if(this.updateTimer) clearTimeout(this.updateTimer);
    if(this.contentObserver) this.contentObserver.disconnect();
  }
}
</script>