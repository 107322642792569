<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{$t('groups.Accessible_content')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <div v-if="groupContentLoaded && modulesLoaded">
          <div v-if="(modules.satisquestions == 1 && groupContent.measure.categories.length > 0) ||
                     (modules.employee_loyalty_programme == 1 && groupContent.loyalty.store_categories.length > 0) ||
                     (modules.social_wall == 1 && groupContent.connect.flows.length > 0) ||
                     (modules.social_kb == 1 && (groupContent.knowledge.categories.length > 0 || groupContent.knowledge.subcategories.length > 0)) || 
                     (modules.academy == 1 && (groupContent.learn.categories.length > 0 || groupContent.learn.courses.length > 0 || groupContent.learn.onboarding_packages.length > 0)) ||
                     (modules.resources_in_app == 1 && groupContent.hub.resources.length > 0)">

            <b-tabs class="target_group_content_tabs no_borders mb-0" type="is-boxed" :animated="false">
              <b-tab-item v-if="modules.satisquestions == 1 && groupContent.measure.categories.length > 0">
                <template slot="header">
                  <span>SatisQuestions</span>
                </template>
                <template>
                  <b>{{$t('groups.Measure_pillars')}} ({{groupContent.measure.categories.length}})</b>
                  <CRow class="tile-container">
                    <CCol v-for="category in groupContent.measure.categories" v-bind:key="category.sq_category_id_external" cols="6" md="6" class="p-0">            
                      <measureCategoryCard :category="category"/>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item v-if="modules.employee_loyalty_programme == 1 && groupContent.loyalty.store_categories.length > 0">
                <template slot="header">
                  <span>Spark Moments</span>
                </template>
                <template>
                  <b>{{$t('groups.Loyalty_store_categories')}} ({{groupContent.loyalty.store_categories.length}})</b>
                  <CRow class="tile-container">
                    <CCol v-for="category in groupContent.loyalty.store_categories" v-bind:key="category.loyalty_store_category_id_external" cols="6" md="6" class="p-0">
                      <loyaltyStoreCategoryCard :category="category"/>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item v-if="modules.social_wall == 1 && groupContent.connect.flows.length > 0">
                <template slot="header">
                  <span>Company Topics</span>
                </template>
                <template>
                  <b>{{$t('groups.Connect_flows2')}} ({{groupContent.connect.flows.length}})</b>
                  <CRow class="tile-container">
                    <CCol v-for="flow in groupContent.connect.flows" v-bind:key="flow.sw_group_id_external" cols="6" md="6" class="p-0">
                      <connectFlowCard :flow="flow"/>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
              <b-tab-item v-if="modules.social_kb == 1 && (groupContent.knowledge.categories.length > 0 || groupContent.knowledge.subcategories.length > 0)">
                <template slot="header">
                  <span>Knowledge</span>
                </template>
                <template>
                  <div v-if="groupContent.knowledge.categories.length > 0">
                    <b>{{$t('groups.Knowledge_categories')}} ({{groupContent.knowledge.categories.length}})</b>
                    <CRow class="tile-container">
                      <CCol v-for="category in groupContent.knowledge.categories" v-bind:key="category.kb_intent_category_id_external" cols="6" md="6" class="p-0">            
                        <knowledgeCategoryCard :category="category"/>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-if="groupContent.knowledge.subcategories.length > 0">
                    <b>{{$t('groups.Knowledge_subcategories')}} ({{groupContent.knowledge.subcategories.length}})</b>
                    <CRow class="tile-container">
                      <CCol v-for="subcategory in groupContent.knowledge.subcategories" v-bind:key="subcategory.kb_intent_subcategory_id_external" cols="6" md="6" class="p-0">            
                        <knowledgeSubcategoryCard :subcategory="subcategory"/>
                      </CCol>
                    </CRow>
                  </div>
                </template>
              </b-tab-item>
              <b-tab-item v-if="modules.academy == 1 && (groupContent.learn.categories.length > 0 || groupContent.learn.courses.length > 0 || groupContent.learn.onboarding_packages.length > 0)">
                <template slot="header">
                  <span>Learn</span>
                </template>
                <template>
                  <div v-if="groupContent.learn.categories.length > 0">
                    <b>{{$t('groups.Learn_categories')}} ({{groupContent.learn.categories.length}})</b>
                    <CRow class="tile-container">
                      <CCol v-for="category in groupContent.learn.categories" v-bind:key="category.lms_course_category_id_external" cols="6" md="6" class="p-0">
                        <learnCategoryCard :category="category"/>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-if="groupContent.learn.courses.length > 0">
                    <b>{{$t('groups.Learn_courses')}} ({{groupContent.learn.courses.length}})</b>
                    <CRow class="tile-container">
                      <CCol v-for="course in groupContent.learn.courses" v-bind:key="course.lms_course_id_external" cols="6" md="6" class="p-0">
                        <learnCourseCard :course="course"/>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-if="groupContent.learn.onboarding_packages.length > 0">
                    <b>{{$t('groups.Onboarding_packages')}} ({{groupContent.learn.onboarding_packages.length}})</b>
                    <CRow class="tile-container">
                      <CCol v-for="onboardingPackage in groupContent.learn.onboarding_packages" v-bind:key="onboardingPackage.onboarding_package_id_external" cols="6" md="6" class="p-0">            
                        <onboardingPackageCard :onboardingPackage="onboardingPackage"/>
                      </CCol>
                    </CRow>
                  </div>
                </template>
              </b-tab-item>
              <b-tab-item v-if="modules.resources_in_app == 1 && groupContent.hub.resources.length > 0">
                <template slot="header">
                  <span>Hub</span>
                </template>
                <template>
                  <b>{{$t('groups.Resources')}} ({{groupContent.hub.resources.length}})</b>
                  <CRow class="tile-container">
                    <CCol v-for="resource in groupContent.hub.resources" v-bind:key="resource.resource_id_external" cols="6" md="6" class="p-0">
                      <resourceCard :resource="resource"/>
                    </CCol>
                  </CRow>
                </template>
              </b-tab-item>
            </b-tabs>
          </div>
          <div v-else>
            <CRow class="m-0">
              <CCol cols="12" class="pt-0 pb-0">
                <span>{{$t('groups.No_accessible_content')}}</span>
              </CCol>
            </CRow>            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import measureCategoryCard from "@/components/measure/measureCategoryCard.vue";
import loyaltyStoreCategoryCard from "@/components/loyalty/loyaltyStoreCategoryCard.vue";
import connectFlowCard from "@/components/connect/connectFlowCard.vue";
import knowledgeCategoryCard from "@/components/knowledge/knowledgeCategoryCard.vue";
import knowledgeSubcategoryCard from "@/components/knowledge/knowledgeSubcategoryCard.vue";
import learnCategoryCard from "@/components/learn/learnCategoryCard.vue";
import learnCourseCard from "@/components/learn/learnCourseCard.vue";
import onboardingPackageCard from "@/components/learn/onboardingPackageCard.vue";
import resourceCard from "@/components/hub/resourceCard.vue";

export default {
  name: 'targetGroupContent',
  props: ['history', 'groupIdExternal'],
  components: {
    measureCategoryCard,
    loyaltyStoreCategoryCard,
    connectFlowCard,
    knowledgeCategoryCard,
    knowledgeSubcategoryCard,
    learnCategoryCard,
    learnCourseCard,
    onboardingPackageCard,
    resourceCard
  },
  watch: {
    $props: {
      handler() {
        this.getGroupContent();
        this.checkModules();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      modules: {
        satisquestions: 0,
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0,
        resources_in_app: 0
      },
      modulesLoaded: false,  
      groupContent: {
        measure: { categories: [] },
        loyalty: { store_categories: [] },
        connect: { flows: [] },
        knowledge: { 
          categories: [],
          subcategories: []
        },
        learn: {
          categories: [],
          courses: [],
          onboarding_packages: []
        },
        hub: { resources: [] }
      },
      groupContentLoaded: false
    }
  },
  methods: {
    getGroupContent() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + this.groupIdExternal + '/content')
      .then(res => {
        this.groupContent = res.data.data;
        // Update groupContentLoaded value
        this.groupContentLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;
        // Update modulesLoaded value
        this.modulesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>