<template>
  <div>
    <div class="sidebar_container moment_sidebar">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="9" lg="9" md="10" sm="10" class="text-left pb-0">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('sm.Send_a_spark')}}
            </span>      
          </CCol>
          <CCol cols="3" lg="3" md="2" sm="2" class="text-right pb-0">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content events">
        <!-- Moment Templates -->
        <div v-if="!moment.loyalty_moment_template_id_external">
          <CRow v-if="momentTemplatesLoading === true || momentTemplatesTagsLoaded === false || momentTemplateLanguagesLoaded === false">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="momentTemplatesLoading === false && momentTemplatesTagsLoaded === true && momentTemplateLanguagesLoaded === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">                       
              <div v-if="momentTemplateLanguages.length > 0">
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="d-flex">
                      <div class="mt-2 mr-2 d-flex align-items-center" style="height:30px;">
                        <span class="d-flex align-items-center"><i class="fas fa-tags mr-1"/>{{$t('common.Language')}}</span>
                      </div>
                      <div>
                        <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateLanguages}">
                          <b-tag v-for="language in momentTemplateLanguages" 
                                v-bind:key="language.language_code"
                                @click.native="setSelectedMomentTemplateLanguages(language.language_name)"
                                class="pointer"
                                v-bind:class="{'selected' : momentTemplateFilters.moment_languages.includes(language.language_name)}">
                            <span>{{ language.language_name }} ({{ language.moments_with_language }})</span>
                          </b-tag>
                        </div>
                        <div v-if="momentTemplateLanguages.length > 20" class="mt-2">
                          <span class="pointer" @click="toggleMomentTemplateLanguages()">{{!showAllMomentTemplateLanguages ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <div v-if="momentTemplateTags.length > 0">
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="d-flex">
                      <div class="mt-2 mr-2 d-flex align-items-center" style="height:30px;">
                        <span class="d-flex align-items-center"><i class="fas fa-tags mr-1"/>{{$t('common.Subject')}}</span>
                      </div>
                      <div>
                        <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateTags}">
                          <b-tag v-for="tag in momentTemplateTags" 
                                  v-bind:key="tag.moment_tag"
                                  @click.native="setSelectedMomentTemplateTags(tag.moment_tag)"
                                  class="pointer"
                                  v-bind:class="{'selected' : momentTemplateFilters.moment_tags.includes(tag.moment_tag)}">
                            {{tag.moment_tag}} ({{tag.moments_with_tag}})
                          </b-tag>
                        </div>
                        <div v-if="momentTemplateTags.length > 30" class="mt-2">
                          <span class="pointer" @click="toggleMomentTemplateTags()">{{!showAllMomentTemplateTags ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <div v-if="momentTemplates.length > 0">
                <CRow class="w-100 m-0 moment_catalog">
                  <CCol v-for="momentTemplate in currentPageMomentTemplates[currentMomentTemplatePage - 1]" :key="momentTemplate.loyalty_moment_template_id_external" cols="3" xl="3" lg="3" md="4" sm="6" class="p-0">
                    <loyaltyMomentTemplateCard :momentData="momentTemplate" 
                                               :showEditButton="false"
                                               :showMomentTag="false"
                                               parent="wizard"
                                               @click.native="getMomentTemplateDetails(momentTemplate.loyalty_moment_template_id_external);"
                                               class="pointer">
                    </loyaltyMomentTemplateCard>
                  </CCol>
                </CRow>
                <CRow v-if="momentTemplates.length > momentTemplatesPerPage" class="m-0">
                  <CCol cols="12" md="12" class="pt-0">
                    <v-pagination class="justify-content-end" v-model="currentMomentTemplatePage" :length="momentTemplatePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('sm.No_sparks_available')}}</span>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </div>
        <!-- Custom Design Editor -->
        <div v-if="showCustomDesignEditor">
          <CRow v-if="checkPermission('core.emailtemplates') && customDesignTemplates.length > 0 && emailEditorReady" class="m-0">
            <CCol cols="12" lg="12">
              <div class="mt-1 mt-xl-0 d-flex justify-content-lg-start">
                <CButton @click="showCustomDesignTemplates = true" color="secondary" class="btn_small">
                  <span><i class="fa-solid fa-envelope mr-1"></i>{{$t('common.Load_email_template')}}</span>
                </CButton>
                <CButton @click="resetMomentTemplateModalData(); saveMomentTemplateModal = true;" color="secondary" class="btn_small">
                  <span><i class="fa-solid fa-envelope-circle-check mr-2"></i>{{$t('common.Save_as_email_template')}}</span>
                </CButton>
              </div>
            </CCol>
          </CRow>

          <transition name="slide-big">
            <div v-if="showCustomDesignTemplates">
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <span>{{$t('common.Pick_mail_template')}}</span>
                </CCol>
              </CRow>
              <CRow class="m-0 p-1_5">
                <CCol cols="3" lg="3" class="p-1_5">
                  <navigationCard @click.native="showCustomDesignTemplates = false;" path="" :cardTitle="$t('common.Current_template')" :cardDescription="$t('common.Keep_using_current_template')" :cardIcon="null" class="h-100"/>
                </CCol>                                     
                <CCol v-for="template in customDesignTemplates" v-bind:key="template.platform_event_email_template_id_external" cols="3" lg="3" class="p-1_5">
                  <navigationCard @click.native="loadCustomDesignTemplate(template.json)" path="" :cardTitle="template.name" :cardDescription="template.description" :cardIcon="null" class="h-100"/>
                </CCol>
              </CRow>
            </div>
          </transition>

          <hr v-if="customDesignTemplates.length > 0 && emailEditorReady" class="m-0">

          <CRow class="m-0">
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" xs="12" class="pt-0">
              <EmailEditor ref="emailEditor_loyalty_moment"
                           :appearance="emailEditorConfig.appearance"
                           :min-height="emailEditorConfig.minHeight"
                           :project-id="emailEditorConfig.projectId"
                           :locale="emailEditorConfig.locale"
                           :tools="emailEditorConfig.tools"
                           :options="emailEditorConfig.options"
                           v-on:ready="editorReady()">
              </EmailEditor>
            </CCol>
          </CRow>
        </div>
        <!-- Moment Details -->
        <div ref="momentDetails" v-if="showMomentDetails" class="h-100">
          <CRow class="h-100 m-0">
            <CCol cols="4" xl="4" lg="5" md="6" sm="12" xs="12">
              <div ref="momentDetailsDesign" class="moment_details_design">
                <div ref="momentContainer" class="moment_container">
                  <div class="moment_content" :class="{ 'flipped': isFlipped }">
                    <div class="front" @click.stop="toggleFlip">                    
                      <div ref="momentCard" class="moment_card moment_card_html" v-html="currentCardHtml" v-show="isContentLoaded"/>                  
                    </div>
                    <div class="back d-flex flex-column justify-content-start" @click.stop="toggleFlip">
                      <div class="w-100">
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" md="12" sm="12" class="pb-0">
                            <div class="d-flex">
                              <div class="flex-grow-1 moment_date">
                                <span v-if="moment.scheduled_for">{{moment.scheduled_for | moment("DD-MM-YYYY")}}</span>
                                <span v-else>{{new Date() | moment("DD-MM-YYYY")}}</span>
                              </div>
                              <div class="stamp d-flex flex-grow-1 justify-content-end" :class="{'position-absolute' : useCustomDesignTemplate}">
                                <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/logo/harryhr_stamp.png/' + clientToken"/>
                              </div>
                            </div>
                          </CCol>             
                        </CRow>
                      </div>
                      <div v-if="!useCustomDesignTemplate" class="w-100 d-flex flex-grow-1 moment_custom_message">
                        <CRow class="h-100 w-100 m-0">
                          <CCol cols="12" lg="12" md="12" sm="12">
                            <div class="h-100 w-100 text-center position-relative">
                              <div class="h-100 w-100 d-flex align-items-center justify-content-center custom_message_container">
                                <span @click.stop="setCustomMessageFieldFocus" class="text-center">{{moment.custom_message ? moment.custom_message : $t('sm.Add_your_custom_message')}}</span>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      </div>
                      <div class="w-100 moment_details">
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" md="12" sm="12">
                            <div class="align-items-center" :class="{'d-flex' : !useCustomDesignTemplate, 'd-block' : useCustomDesignTemplate}">
                              <div class="d-flex flex-grow-1 align-items-center">
                                <div class="h-100 d-flex align-items-center label_container">
                                  <label class="m-0"><b>{{$t('common.From')}}:</b></label>
                                </div>
                                <div v-if="moment.sender" class="d-flex align-items-center moment_sender">
                                  <div v-if="cdnBaseUrl"
                                      class="profile_image mr-1"
                                      v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-sender/' + moment.sender.loyalty_moment_sender_id_external + '/' + moment.sender.profile_image_id + '/' + clientToken + ')' }">                      
                                  </div>
                                  <div v-else-if="moment.sender.sender_alias" class="profile_icon mr-1">
                                    <userProfileAvatar :username="moment.sender.sender_alias" :size="30"/>
                                  </div>
                                  <span>{{moment.sender.sender_alias}}</span>
                                </div>
                                <div v-else class="d-flex align-items-center moment_sender">
                                  <div v-if="user.profile_image && user.original_company_id_external && user.original_company_id_external !== user.company_id_external"
                                      class="profile_image d-inline-block align-middle"           
                                      v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                                  </div>
                                  <div v-else-if="user.profile_image"
                                      class="profile_image d-inline-block align-middle"           
                                      v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                                  </div>
                                  <div v-else-if="user.name" class="profile_icon d-inline-flex align-middle">
                                    <userProfileAvatar :username="user.name" :size="20"/>
                                  </div>
                                  <div class="ml-1">
                                    <span>{{user.name}}</span>
                                  </div>
                                </div>
                              </div>                            
                              <div v-if="moment.grant_points && moment.points > 0" class="d-flex flex-grow-1 align-items-center currency" :class="{'justify-content-end' : !useCustomDesignTemplate, 'justify-content-start mt-2_5' : useCustomDesignTemplate}">
                                <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken" class="mr-1"/>
                                <span class="d-flex align-items-center">
                                  <span class="count mr-1">{{moment.points}}</span> {{moment.points !== 1 ? $t('common.coins') : $t('common.coin')}}
                                </span>
                              </div>
                            </div>                          
                          </CCol>
                        </CRow>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol cols="8" xl="8" lg="7" md="6" sm="12" xs="12">
              <div class="moment_details">
                <!-- Recipients -->
                <CRow>
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-user-check"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('To')}}</b></label>
                      </div>
                    </div>
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <div v-if="momentRecipientsType === 'colleagues'">
                      <CRow>
                        <CCol cols="12" lg="12" class="pt-0 pb-0">
                          <multiselect class="data_table"
                                      v-model="moment.recipients"
                                      label="label" 
                                      track-by="user_id_external" 
                                      :placeholder="$t('Search_for_a') + ' ' + $t('common.employee')"
                                      open-direction="bottom" 
                                      :options="recipients" 
                                      :multiple="['business', 'enterprise'].includes(productLicenses.loyalty_license_tag) ? true : false" 
                                      :searchable="true" 
                                      :loading="false" 
                                      :internal-search="false"
                                      :clear-on-select="true"
                                      :close-on-select="['business', 'enterprise'].includes(productLicenses.loyalty_license_tag) ? false : true"
                                      :options-limit="300"
                                      :max-height="600"
                                      :show-no-results="true"
                                      :hide-selected="false"
                                      @search-change="asyncFindUser"
                                      @input="checkRemainingBudget(moment);">
                            <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                            <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                          </multiselect>
                        </CCol>
                      </CRow>
                    </div>                    
                    <div v-else-if="momentRecipientsType === 'smart_filters'">
                      <CRow v-for="(filter, index) in moment.filters" v-bind:key="index">
                        <CCol cols="12" lg="12" class="pt-0 pb-0">
                          <div class="mb-2_5 d-flex filters">
                            <div class="filter_type">
                              <multiselect class="data_table open_absolute"
                                            v-model="filter.type" 
                                            :options="groupFilterTypes" 
                                            :multiple="false"                
                                            :placeholder="$t('common.Select_filter_type')" 
                                            :selectLabel="$t('common.Add_filter_type')" 
                                            :selectedLabel="$t('Selected')"
                                            :deselectLabel="$t('common.Remove_filter_type')"
                                            track-by="type_tag" 
                                            label="type_name"
                                            :custom-label="customFilterTypeLabel"
                                            :disabled="filter.type !== null && filter.type !== undefined"
                                            @input="setupTypeFilter(filter.type.type_tag, index);">
                                <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                              </multiselect>
                            </div>

                            <div v-if="filter.type" class="filter_condition ml-2" v-bind:class="{'mr-2' : filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)}">
                              <multiselect class="data_table open_absolute"
                                          v-model="filter.condition" 
                                          :options="typeFilterConditions" 
                                          :multiple="false"                
                                          :placeholder="$t('common.Select_filter_condition')" 
                                          :selectLabel="$t('common.Add_filter_condition')" 
                                          :selectedLabel="$t('Added')"
                                          :deselectLabel="$t('common.Remove_filter_condition')"
                                          track-by="condition_tag" 
                                          label="condition_name"
                                          :custom-label="customFilterConditionLabel"
                                          :disabled="filter.condition !== null && filter.condition !== undefined"
                                          @input="filter.condition && conditionsWithoutValue.includes(filter.condition.condition_tag) ? getMomentRecipients() : null;">
                                <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>
                            </div>

                            <div v-if="filter.type && filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)" class="filter_value">
                              <div v-if="filter.type.type_tag == 'department'">
                                <multiselect class="data_table open_absolute"
                                              v-model="filter.value" 
                                              :options="groupFilterDepartments" 
                                              :multiple="false"                
                                              :placeholder="$t('common.Select_department')"
                                              :selectLabel="$t('common.Add_department')" 
                                              :selectedLabel="$t('Selected')"
                                              :deselectLabel="$t('common.Remove_department')"
                                              track-by="department_id" 
                                              label="department_name"
                                              @search-change="asyncFindDepartment"
                                              @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>
                              </div>
                              <div v-if="filter.type.type_tag == 'team'">
                                <multiselect class="data_table open_absolute"
                                              v-model="filter.value" 
                                              :options="groupFilterTeams" 
                                              :multiple="false"                
                                              :placeholder="$t('common.Select_team')" 
                                              :selectLabel="$t('common.Add_team')" 
                                              :selectedLabel="$t('Selected')"
                                              :deselectLabel="$t('common.Remove_team')"
                                              track-by="team_id" 
                                              label="team_name"
                                              @search-change="asyncFindTeam"
                                              @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>
                              </div>
                              <div v-if="filter.type.type_tag == 'user'">
                                <multiselect class="data_table open_absolute"
                                              v-model="filter.value" 
                                              :options="groupFilterRecipients" 
                                              :multiple="false"                
                                              :placeholder="$t('common.Set_employee')" 
                                              :selectLabel="$t('common.Select_employee')" 
                                              :selectedLabel="$t('Selected')"
                                              :deselectLabel="$t('common.Deselect_employee')"
                                              track-by="user_id_external" 
                                              label="label"                        
                                              @search-change="asyncFindRecipient"
                                              @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>  
                              </div>
                              <div v-if="filter.type.type_tag == 'department_manager'">
                                <multiselect class="data_table open_absolute"
                                            v-model="filter.value" 
                                            :options="groupFilterDepartmentManagers" 
                                            :multiple="false"                
                                            :placeholder="$t('common.Set_manager')" 
                                            :selectLabel="$t('common.Select_manager')" 
                                            :selectedLabel="$t('Selected')"
                                            :deselectLabel="$t('common.Deselect_manager')"
                                            track-by="user_id_external" 
                                            label="label"                        
                                            @search-change="asyncFindDepartmentManager"
                                            @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.No_managers_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>
                              </div>                               
                              <div v-if="filter.type.type_tag == 'team_manager'">
                                <multiselect class="data_table open_absolute"
                                            v-model="filter.value" 
                                            :options="groupFilterTeamManagers" 
                                            :multiple="false"                
                                            :placeholder="$t('common.Set_employee')" 
                                            :selectLabel="$t('common.Select_employee')" 
                                            :selectedLabel="$t('Selected')"
                                            :deselectLabel="$t('common.Deselect_employee')"
                                            track-by="user_id_external" 
                                            label="label"                        
                                            @search-change="asyncFindTeamManager"
                                            @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>
                              </div>                              
                              <div v-if="filter.type.type_tag == 'target_group'">
                                <multiselect class="data_table open_absolute"
                                              v-model="filter.value" 
                                              :options="groupFilterTargetGroups" 
                                              :multiple="false"                
                                              :placeholder="$t('common.Select_group')" 
                                              :selectLabel="$t('common.Add_group')" 
                                              :selectedLabel="$t('Selected')"
                                              :deselectLabel="$t('common.Remove_group')"
                                              track-by="group_id" 
                                              label="title"                      
                                              @search-change="asyncFindTargetGroup"
                                              @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                </multiselect>  
                              </div>                  
                              <div v-if="filter.type.type_tag == 'date_started' || filter.type.type_tag == 'date_left'">
                                <multiselect class="data_table open_absolute"
                                              v-model="filter.value" 
                                              :options="groupFilterValues" 
                                              :multiple="false"                
                                              :placeholder="$t('common.Select_filter_value')" 
                                              :selectLabel="$t('common.Add_filter_value')" 
                                              :selectedLabel="$t('Added')"
                                              :deselectLabel="$t('common.Remove_filter_value')"
                                              track-by="type_value_tag" 
                                              label="type_value_name"
                                              :custom-label="customFilterTypeValueLabel"
                                              @input="getMomentRecipients();">
                                  <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                                </multiselect>   
                              </div>
                              <div v-if="userAttributes.includes(filter.type.type_tag)">
                                <CInput v-debounce:1s="getMomentRecipients"
                                        type="text"
                                        class="attribute mb-0"
                                        v-model="filter.value">
                                </CInput>
                              </div>
                            </div>

                            <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                              <i class="fas fa-times"/>
                            </CButton>
                          </div>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol cols="12" lg="12" class="pt-0 pb-0">
                          <CButton color="primary" @click="addFilterOption();">
                            <i class="fas fa-plus mr-1"/>
                            <span>{{$t('common.Add_filter')}}</span>
                          </CButton>
                        </CCol>
                      </CRow>
                    </div>
                    <div v-else-if="momentRecipientsType === 'csv_upload'">
                      <CRow>
                        <CCol cols="12" lg="12" class="pt-0 pb-0">
                          <div class="d-flex">
                            <div class="mr-1_5">
                              <input id="csvUpload" type="file" accept="text/csv" :value="recipientsCSV" @change="onRecipientsCSVChange" hidden>
                              <CButton @click="pickRecipientsCSV()" color="primary" class="m-0">
                                <span><i class="fa-solid fa-file-csv mr-1"/>{{$t('common.Upload_CSV_file')}}</span>
                              </CButton>
                            </div>
                            <div>
                              <CButton @click="downloadExampleRecipientsCSV()" color="primary orange" class="m-0">
                                <span><i class="fa-solid fa-download mr-1"/>{{$t('common.Download_example_CSV_file')}}</span>
                              </CButton>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                      <CRow v-if="csvEmployeesLoading">
                        <CCol cols="12" lg="12" class="pb-0">
                          <loadingSpinner mode="inline" :content="$t('common.Loading')"/>
                        </CCol>
                      </CRow>
                      <CRow v-if="recipientsCSVUploaded && !csvEmployeesLoading && moment.recipients.length === 0">
                        <CCol cols="12" lg="12" class="pb-0">
                          <span>{{$t('common.No_employees_based_on_CSV_file')}}</span>
                        </CCol>
                      </CRow>
                    </div>

                    <div v-if="moment.recipients.length > 0 && momentRecipientsType !== 'colleagues'" class="d-flex align-items-center mt-2 moment_recipients">
                      <div v-for="(recipient, index) in moment.recipients.slice(0, 10)" :key="index" class="recipient">
                        <div v-if="recipient.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + recipient.user_id_external + '/' + recipient.profile_image + '/' + clientToken + ')' }"></div>
                        <div v-else class="profile_icon">
                          <userProfileAvatar :username="recipient.name" :size="30"/>
                        </div>
                      </div>                                        
                      <div v-if="moment.recipients.length > 10" class="ml-1">
                        <span><b>{{moment.recipients.length - 10}}+</b></span>
                      </div>
                      <div @click="setMomentRecipientsPagination(); momentRecipientsModal = true;" class="ml-1 pointer">
                        <span>{{$t('common.Details')}}</span>
                      </div>
                    </div>                  

                    <div class="set_recipient_type mt-2">
                      <span v-if="momentRecipientsType !== 'colleagues'" @click="switchMomentRecipientsView('colleagues');">
                        <i class="fa-solid fa-people-group mr-1"/>{{$t('common.Colleagues')}}
                      </span>
                      <span v-if="momentRecipientsType !== 'smart_filters'" @click="checkFeatureWithinLicense('smart_filters');">                             
                        <i class="fa-solid fa-users-gear mr-1"/>{{$t('core.Smart_filters')}}
                      </span>
                      <span v-if="momentRecipientsType !== 'csv_upload'" @click="checkFeatureWithinLicense('csv_upload');">
                        <i class="fa-solid fa-file-csv mr-1"/>{{$t('common.CSV_upload')}}
                      </span>
                    </div>
                  </CCol>
                </CRow>
                <!-- From -->
                <CRow>
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-user-pen"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.From')}}</b></label>
                      </div>
                    </div>
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <div class="d-flex align-items-center moment_sender">
                      <div v-if="user.profile_image && user.original_company_id_external && user.original_company_id_external !== user.company_id_external"
                           class="profile_image d-inline-block align-middle"           
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="user.profile_image"
                           class="profile_image d-inline-block align-middle"           
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="user.name" class="profile_icon d-inline-flex align-middle">
                        <userProfileAvatar :username="user.name" :size="20"/>
                      </div>
                      <div class="ml-1">
                        <span>{{user.name}}</span>
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <!-- Alias -->
                <CRow v-if="momentSenderAliases.length > 0">
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-at"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.Send_as_alias')}}</b></label>
                      </div>
                    </div>
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <multiselect class="data_table"
                                 v-model="moment.sender" 
                                 :options="momentSenderAliases"
                                 :multiple="false"
                                 :hide-selected="false"
                                 :close-on-select="true"
                                 :placeholder="$t('common.Select_alias')" 
                                 :selectLabel="$t('common.Add_alias')" 
                                 :selectedLabel="$t('Added')"
                                 :deselectLabel="$t('common.Remove_alias')"
                                 track-by="loyalty_moment_sender_id_external" 
                                 label="sender_alias">
                      <span slot="noResult">{{ $t('common.No_aliases_found') }}</span>
                    </multiselect>
                  </CCol>
                </CRow>
                <!-- Points -->
                <CRow>
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center align-items-center">
                      <div class="d-flex details_icon">
                        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.Coins')}}</b></label>
                        <remainingBudget v-if="['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)"/>
                      </div>
                    </div>                    
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <div class="w-100">
                      <CRow class="w-100 moment_points">
                        <CCol class="pt-0 pb-0">
                          <CButton v-for="(value, name, key) in [0, 50, 100, 250]" :key="key" color="primary" @click="moment.points = value; setMomentPointsGranted('button');" :class="{'selected' : moment.points === value && !showCustomPointsSlider}">
                            <div class="d-flex align-items-center justify-content-center">
                              <div class="currency mr-1">
                                <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                              </div>
                              <div>
                                <span class="d-block">{{value}}</span>                                    
                              </div>
                            </div>
                          </CButton>
                          <CButton color="primary" @click="showCustomPointsSlider = true;" :class="{'selected' : showCustomPointsSlider}">
                            <div class="d-flex align-items-center justify-content-center">
                              <span>Custom</span>                                    
                            </div>
                          </CButton>                        
                        </CCol>
                      </CRow>
                      <CRow v-if="showCustomPointsSlider" class="w-100 m-0 mt-2 mb-2">
                        <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">                                
                          <b-slider size="is-medium" v-model="moment.points" @input="setMomentPointsGranted('slider')" :min="0" :max="500" :step="10" rounded>
                            <div class="b-slider-tick null">
                              <span class="b-slider-tick-label">0</span>
                            </div>
                            <template v-for="val in 500">                                      
                              <b-slider-tick v-if="(val % 50 === 0)" :value="val" :key="val">{{ val ? val : 0 }}</b-slider-tick>                                   
                            </template>
                          </b-slider>
                        </CCol>        
                      </CRow>
                    </div>
                  </CCol>
                </CRow>
                <!-- Color -->
                <CRow v-if="!useCustomDesignTemplate">
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-palette"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.Color')}}</b></label>
                      </div>
                    </div>                      
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <div class="card_color_picker">
                      <v-swatches v-model="selectedColor"
                                  :swatches="momentTemplateColorOptions"
                                  @input="updateMomentCardColor"
                                  inline
                                  :shapes="'circles'"
                                  :show-border="true"
                                  :swatch-size="36"
                                  :swatch-style="{ 'margin-right': '10px' }">
                      </v-swatches>
                    </div>
                  </CCol>
                </CRow>
                <!-- Custom Message -->
                <CRow v-if="!useCustomDesignTemplate">
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-message"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.Message')}}</b><span class="d-block">{{$t('common.optional')}}</span></label>
                      </div>
                    </div>                      
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <b-input ref="customMessageInput" @input="countdown()" @focus="showMomentCardBack" v-model="moment.custom_message" type="textarea" rows="5"></b-input>
                    <p v-if="remainingCount.custom_message > 0" class="countdown mt-2 mb-0">{{remainingCount.custom_message}} {{ $t('common.characters_remaining') }}</p>
                    <p v-if="remainingCount.custom_message < 0" class="countdown warning mt-2 mb-0">{{moment.custom_message.length - maxCount.custom_message}} {{ $t('common.characters_too_much') }}</p>
                  </CCol>
                </CRow>
                <!-- Schedule -->
                <CRow>
                  <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                    <div class="d-flex align-items-center">
                      <div class="d-flex details_icon">
                        <i class="fa-solid fa-calendar"/>
                      </div>
                      <div>
                        <label class="m-0"><b>{{$t('common.When')}}</b></label>
                      </div>
                    </div>                      
                  </CCol>
                  <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                    <div class="d-flex align-items-center moment_option_cards">
                      <CCard @click="setMomentScheduled(false);" class="pointer" v-bind:class="{'selected' : !moment.schedule_moment || moment.schedule_moment === false}">
                        <CCardBody class="d-flex align-items-center justify-content-center">
                          <i class="fa-solid fa-bolt mr-2"></i>
                          <span>{{$t('common.Instant')}}</span>
                        </CCardBody>
                      </CCard>
                      <CCard @click="setMomentScheduled(true);" class="pointer" v-bind:class="{'selected' : moment.schedule_moment === true}">
                        <CCardBody class="d-flex align-items-center justify-content-center">
                          <i class="fa-solid fa-clock mr-2"></i>                              
                          <span>{{$t('common.Schedule')}}</span>
                        </CCardBody>
                      </CCard>
                    </div>
                    <div v-if="moment.schedule_moment" class="w-100 mt-3">
                      <CRow class="w-100 m-0">
                        <CCol cols="12" xl="12" class="p-0">  
                          <b-datepicker v-model="moment.scheduled_for" :placeholder="$t('common.click_to_select')" inline editable :min-date="new Date()" :datepicker="datepickerOptions"></b-datepicker>
                        </CCol>
                      </CRow>
                    </div>
                  </CCol>
                </CRow>                            
              </div>
            </CCol>          
          </CRow>
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="text-right">
            <CButton color="secondary" @click="closeSidebarRight();">
              <span><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</span>
            </CButton>
            <CButton v-if="showCustomDesignEditor && emailEditorReady" color="primary" @click="setCustomDesignTemplate();">
              <span><i class="fas fa-chevron-right mr-1"/>{{$t('common.Next')}}</span>
            </CButton>
            <CButton v-if="showMomentDetails" color="primary" @click="sendMoment();" :disabled="sendButtonDisabled">
              <div v-if="!sendingMoment">
                <span><i class="fa-solid mr-1" v-bind:class="{'fa-calendar' : moment.schedule_moment, 'fa-paper-plane' : !moment.schedule_moment}"/>{{moment.schedule_moment ? $t('sm.Schedule_spark') : $t('sm.Send_spark')}}</span>                          
              </div>
              <div v-else class="d-flex align-items-center">
                <loadingSpinner mode="inline"/>
                <span class="ml-1">{{moment.schedule_moment ? $t('sm.Scheduling_spark') : $t('sm.Sending_spark')}}</span>
              </div>
            </CButton>            
          </CCol>
        </CRow>
      </div>
    </div>

    <b-modal :can-cancel="['x']" :active.sync="momentRecipientsModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader>
          <span>{{$t('loyalty.Moment_recipients')}} ({{moment.recipients.length}})</span>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0 ">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="p-0" v-bind:class="{ 'pb-0' : listViewEnabled === false || isPaginated === false }">                      
              <div class="d-flex align-items-center" v-bind:class="{ 'mb-2_5' : listViewEnabled === true }">
                <b-switch v-if="moment.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
              </div>
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="p-0">
                    <b-table class="data_table" 
                              :data="moment.recipients"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="isPaginated"
                              :per-page="perPage"
                              :current-page.sync="currentPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition">
                      
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name}}</span>
                        </b-table-column>                        
                      </template>
                    </b-table>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="tile-container">
                  <CCol cols="3" xl="3" lg="3" md="3" sm="6" v-for="recipient in currentMomentRecipients[currentMomentRecipientsPage - 1]" v-bind:key="recipient.user_id_external" class="p-0">
                    <userProfileCard :userData="recipient" class="member"/>
                  </CCol>
                </CRow>
                <hr v-if="moment.recipients.length > momentRecipientsPerPage" class="m-0">
                <CRow v-if="moment.recipients.length > momentRecipientsPerPage">
                  <CCol cols="12" md="12">
                    <v-pagination class="justify-content-end" v-model="currentMomentRecipientsPage" :length="momentRecipientsPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>  
        </CCardBody>
        <CCardFooter>          
          <CButton color="secondary" @click="momentRecipientsModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="momentRecipientsTypeModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('loyalty.Confirm_switching_recipients_view')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('loyalty.You_are_switching_recipients_view')}}</span>
            </CCol>
          </CRow>    
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="setMomentRecipientsView(momentRecipientsTypeSelected); momentRecipientsTypeModal = false;"><i class="fas fa-check mr-1"/>{{$t('Confirm')}}</CButton>
          <CButton color="secondary" @click="momentRecipientsTypeModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="momentExceedsBudgetModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('loyalty.Monthly_budget_will_be_exceeded')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('loyalty.Your_monthly_budget_will_be_exceeded')}}</span>
            </CCol>
          </CRow>    
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="momentExceedsBudgetModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal :can-cancel="['x']" :active.sync="saveMomentTemplateModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('common.Save_email_template')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0">
              <CInput type="text" :label="$t('common.Name')" class="mb-0" v-model="saveMomentTemplateModalData.name" required was-validated/>
            </CCol>
          </CRow>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CTextarea @input="countdown()" :maxlength="maxCount.moment_template_description" :label="$t('common.Description')" rows="5" class="mb-0" v-model="saveMomentTemplateModalData.description"/>
              <p class="countdown mt-1 mb-0" v-if="remainingCount.moment_template_description > 0">{{remainingCount.moment_template_description}} {{ $t('common.characters_remaining') }}</p>
            </CCol>
          </CRow>              
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="saveMomentTemplate();"><i class="fas fa-check mr-1"/>{{$t('common.Save_as_template')}}</CButton>
          <CButton color="secondary" @click="saveMomentTemplateModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';

import VSwatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.css"

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import navigationCard from '@/components/common/navigationCard';
import userProfileCard from "@/components/common/userProfileCard.vue";
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

import loyaltyMomentTemplateCard from '@/components/loyalty/loyaltyMomentTemplateCard.vue';
import remainingBudget from '@/components/loyalty/remainingBudget.vue';

export default {
  name: 'sendMoment',
  props: ['history', 'modules', 'platformPermissions', 'platformRoles'],
  components: {
    Multiselect,
    EmailEditor,
    VSwatches,
    loadingSpinner,
    navigationCard,
    userProfileCard,
    userProfileAvatar,
    loyaltyMomentTemplateCard,
    remainingBudget  
  },
  data() {
    return {
      // Common
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      companyIdExternal: null,
      defaultCompanyLanguage: null,
      productLicenses: {
        loyalty_licence_tag: null
      },
      // Moment Templates
      momentTemplates: [],
      momentTemplateData: [],
      momentTemplatesLoading: false,
      momentTemplatesLoaded: false,
      momentTemplateLoading: false,     
      momentTemplateFilters: {
        moment_tags: [],
        moment_languages: []
      },
      momentTemplateColorOptions: [],
      momentTemplateColorMappings: {},
      currentMomentTemplatePage: 1,
      currentPageMomentTemplates: {},
      momentTemplatesPerPage: 8,
      momentTemplatePages: 0,
      showMomentDetails: false,
      showCustomDesignEditor: false,
      showCustomDesignTemplates: false,
      useCustomDesignTemplate: false,
      customDesignTemplates: [],
      saveMomentTemplateModal: false,
      saveMomentTemplateModalData: {},
      // Moment Template Tags
      momentTemplateTags: [],
      momentTemplatesTagsLoaded: false,
      showAllMomentTemplateTags: false,
      // Moment Template Languages
      momentTemplateLanguages: [],
      momentTemplateLanguagesLoaded: false,
      showAllMomentTemplateLanguages: false,      
      // Moment    
      moment: { recipients: [] },
      momentSenderAliases: [],
      showCustomPointsSlider: false,     
      currentMomentRecipientsPage: 1,
      currentMomentRecipients: {},
      momentRecipientsPerPage: 12 ,
      momentRecipientsPages: 0,      
      sendButtonDisabled: false,
      sendingMoment: false,
      selectedColor: null,
      isFlipped: false,
      isContentLoaded: false,
      updateTimer: null,
      heightUpdateAttempts: 0,
      maxHeightUpdateAttempts: 5,
      minHeight: 550,      
      fullHtml: '',
      topHtml: '',
      bottomHtml: '',
      bodyContent: '',
      defaultCardHtml: '',
      currentCardHtml: '',
      user: [],
      // Moment Budgets
      remainingBudget: 0,
      momentExceedsBudgetModal: false,
      // Moment Recipients
      recipients: [],
      momentRecipientsType: 'colleagues',
      momentRecipientsModal: false,
      momentRecipientsTypeModal: false,
      momentRecipientsTypeSelected: null,          
      // Moment Recipients - CSV
      recipientsCSV: null,
      recipientsCSVUploaded: false,
      csvEmployeesLoading: false,
      // Moment Recipients - Smart Filters
      groupFilterTypes: [],
      groupFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [], 
      groupFilterRecipients: [],
      groupFilterDepartmentManagers: [],
      groupFilterTeamManagers: [],      
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterConditions: [],      
      typeFilterValues: [],
      currentPageEmployees: {},
      employeesCurrentPage: 1,
      employeesPerPage: 12,
      employeePages: 0,       
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service', 'days_of_service'],
      conditionsWithoutValue: ['is_not_set', 'is_today'],
      // Tables
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      // Others
      maxCount: {
        custom_message: 1000,
        moment_template_description: 1000
      },
      remainingCount: {
        custom_message: 1000,
        moment_template_description: 1000
      },
      emailEditorReady: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935,
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {},
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        }              
      },      
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true      
      }
    }
  },
  methods: {
    checkFeatureWithinLicense(recipientsType) {
      let licenseTag = this.productLicenses.loyalty_license_tag;
      let licenseName = this.productLicenses.loyalty_license_name;

      if((['business', 'enterprise'].includes(licenseTag) && recipientsType === 'smart_filters') || (['enterprise'].includes(licenseTag) && recipientsType === 'csv_upload')) {    
        this.switchMomentRecipientsView(recipientsType)
      } else {
        this.openFeatureUnavailableModal({ license_name: licenseName });
      }
    }, 
    getDefaultCompanyLanguage() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company/language/default')
      .then(res => {      
        this.defaultCompanyLanguage = res.data.data;  
        // Check if the default company language is available
        if(this.defaultCompanyLanguage && this.defaultCompanyLanguage.name) {
          // Set the default moment language filter
          this.momentTemplateFilters.moment_languages.push(this.defaultCompanyLanguage.name);
          this.momentTemplateLanguagesLoaded = true;
          this.momentTemplatesTagsLoaded = true;
        }
        // Get the moment templates
        this.getMomentTemplates();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Moment Templates
    getMomentTemplates() {
      let params = {};
      params.momentFilters = this.momentTemplateFilters;
      params.include_default_templates = true;
      params.include_custom_design = true;
      params.active_only = true;
      params.randomize_templates = true;
      // Start the loader
      if(this.momentTemplatesLoaded === false) this.momentTemplatesLoading = true;
      // Get the Moment templates
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates', params)
      .then(res => {
        this.momentTemplates = res.data.data;
        // Set the pagination to the first page
        this.currentMomentTemplatePage = 1;        
        // Reset the momentTemplatePages value
        this.momentTemplatePages = 0;        
        // Loop through the Moment templates to set the pagination
        for (let i = 0; i < this.momentTemplates.length; i = i + this.momentTemplatesPerPage) {
          this.currentPageMomentTemplates[this.momentTemplatePages] = this.momentTemplates.slice(i, i + this.momentTemplatesPerPage);
          this.momentTemplatePages++;
        }
        // Set the available tags
        this.setMomentTemplateTags();
        // Set the available languages
        this.setMomentTemplateLanguages();
        // Stop the loader
        this.momentTemplatesLoading = false;
        // Update the momentTemplatesLoaded value
        this.momentTemplatesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateDetails(templateIdExternal) {
      // Reset the moment data
      this.resetMomentData();
      // Get the Moment template details      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-template/' + templateIdExternal)
      .then(res => {
        this.momentTemplateData = res.data.data;
        // Set the external Moment template ID
        this.moment.loyalty_moment_template_id_external = this.momentTemplateData.loyalty_moment_template_id_external;       
        // Set the tag
        this.moment.tag = this.momentTemplateData.tag;
        // Set the name
        this.moment.name = this.momentTemplateData.name;
        // Set the description
        this.moment.description = this.momentTemplateData.description;        
        // Set the subject
        this.moment.subject = this.momentTemplateData.subject;
        // Set the email content tags
        this.moment.email_content_tags = this.momentTemplateData.email_content_tags;
        // Set the email json
        if(this.momentTemplateData.json) this.moment.json = this.momentTemplateData.json;
        // Set the email json
        if(this.momentTemplateData.html) this.moment.html = this.momentTemplateData.html;        
        // Set the email html
        this.moment.html = this.momentTemplateData.html;
        // Set the push title
        this.moment.push_title = this.momentTemplateData.push_title;
        // Set the push message
        this.moment.push_message = this.momentTemplateData.push_message;
        // Set the Moment image ID
        if(this.momentTemplateData.loyalty_moment_image_id) this.moment.loyalty_moment_image_id = this.momentTemplateData.loyalty_moment_image_id;                
        // Set the points
        this.moment.points = this.momentTemplateData.points;
        // Set the grant points value
        (this.moment.points > 0) ? this.moment.grant_points = true : this.moment.grant_points = false;        
        // Check if the points slider should be shown
        (![0, 50, 100, 250].includes(this.moment.points)) ? this.showCustomPointsSlider = true : this.showCustomPointsSlider = false;
        // Set the points title
        this.moment.points_title = this.momentTemplateData.points_title;
        // Set the points description
        this.moment.points_description = this.momentTemplateData.points_description;

        // Check if momentTemplateData is available and contains HTML
        if(this.momentTemplateData && this.momentTemplateData.html) {
          // Extract relevant HTML elements from the template
          this.extractMomentCardElements(this.momentTemplateData.html);        
          // Store the extracted body content as the default card HTML
          this.defaultCardHtml = this.bodyContent;        
          // Initialize the current card HTML with the default value. This will be used for displaying the card and can be modified when colors change
          this.currentCardHtml = this.defaultCardHtml;
          // Select a random color
          this.selectRandomColor();
          // Setup observers and event listeners
          setTimeout(function() {
            this.setupContentObserver();
            this.resetHeightUpdate();
            // Add resize listener
            window.addEventListener('resize', this.updateCardHeight);
          }.bind(this), 100);
        }

        if(this.moment.tag !== 'custom_design') {          
          // Update the showMomentDetails value
          this.showMomentDetails = true;
        
          setTimeout(function() {
            const momentDetails = this.$refs.momentDetails;
            const momentDetailsHeight = momentDetails.offsetHeight;
            const momentDetailsDesign = this.$refs.momentDetailsDesign;
    
            momentDetailsDesign.style.height = `${momentDetailsHeight - 24}px`;                
          }.bind(this), 100);

        } else {
          // Get custom design templates
          this.getCustomDesignTemplates();
          // Update the useCustomDesignTemplate value
          this.useCustomDesignTemplate = true;
          // Update the showCustomDesignEditor value
          this.showCustomDesignEditor = true;          
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateColors() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/colors')
      .then(res => {
        this.momentTemplateColorOptions = res.data.data.color_options;
        this.momentTemplateColorMappings = res.data.data.color_mappings;
      })
      .catch(err => {
        console.error(err); 
      });
    }, 
    getCustomDesignTemplates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/templates/list')
      .then(res => {      
        this.customDesignTemplates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });              
    },
    loadCustomDesignTemplate(templateData) {
      // Update showCustomDesignTemplates value to close the view
      this.showCustomDesignTemplates = false;
      // Parse the template data to a valid JSON object
      let customDesign = JSON.parse(templateData);
      // Set the json value of the moment
      this.moment.json = customDesign;
      // Load the custom design in the editor
      setTimeout(function(){          
        this.$refs['emailEditor_loyalty_moment'].editor.loadDesign(customDesign);
      }.bind(this), 1000);
    },
    setCustomDesignTemplate() {
      if(this.$refs !== undefined && this.$refs['emailEditor_loyalty_moment'] !== undefined ) {
        this.$refs['emailEditor_loyalty_moment'].editor.saveDesign((design) => {
          this.moment.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_loyalty_moment'].editor.exportHtml((data) => {
          this.moment.html = data.html;
          this.currentCardHtml = data.html;
          this.fullHtml = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to moment
      setTimeout(function() {
        // Update the showCustomDesignEditor value
        this.showCustomDesignEditor = false;
        // Update the showMomentDetails value
        this.showMomentDetails = true;
        // Wait 100ms
        setTimeout(function() {

          const momentDetails = this.$refs.momentDetails;
          const momentDetailsHeight = momentDetails.offsetHeight;
          const momentDetailsDesign = this.$refs.momentDetailsDesign;
  
          momentDetailsDesign.style.height = `${momentDetailsHeight - 24}px`;

          // Setup observers and event listeners
          this.setupContentObserver();
          this.resetHeightUpdate();
          // Add resize listener
          window.addEventListener('resize', this.updateCardHeight);
        }.bind(this), 100);
      }.bind(this), 500);
    },
    resetMomentTemplateModalData() {
      this.saveMomentTemplateModalData = {
        name: null,      
        description: null,
        json: null
      }
    },
    saveMomentTemplate() {
      let params = {};
      params.templateData = this.saveMomentTemplateModalData;

      if(this.$refs !== undefined && this.$refs['emailEditor_loyalty_moment'] !== undefined ) {
        this.$refs['emailEditor_loyalty_moment'].editor.saveDesign((design) => {
          params.templateData.json = JSON.stringify(design);
        });
      }   

      let name = params.templateData.name;
      let description = params.templateData.description;

      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/core/email/template', params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_added'), type: 'is-success', duration: 3000 });
            // Update the custom design templates
            this.getCustomDesignTemplates();
            // Close the modal
            this.saveMomentTemplateModal = false;
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },      
    // Moment Template Tags
    setMomentTemplateTags() {
      if(this.momentTemplatesTagsLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/tags')
        .then(res => {
          this.momentTemplateTags = res.data.data;
          // Update the momentTemplatesTagsLoaded value
          this.momentTemplatesTagsLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateTags
        let momentTemplateTags = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.momentTemplates.length; g++) {
          momentTemplateTags = momentTemplateTags.concat(this.momentTemplates[g].loyalty_moment_tags);
        }
        // Create momentTemplateTagsCount array with counts of remaining tags
        var momentTemplateTagsCount = momentTemplateTags.reduce((tagCount, tag) => {
          if(tagCount.hasOwnProperty(tag) === false) tagCount[tag] = 0;
          tagCount[tag]++;
          return tagCount;
        }, {});
        
        // Update the momentTemplateTags array
        this.momentTemplateTags = Object.keys(momentTemplateTagsCount).map(tag => {
          return {moment_tag: tag, moments_with_tag: momentTemplateTagsCount[tag]}; 
        });
        // Sort the momentTemplateTags
        this.momentTemplateTags.sort((a,b)=> (b.moments_with_tag - a.moments_with_tag || a.moment_tag.localeCompare(b.moment_tag)));
      }
    },
    setSelectedMomentTemplateTags(tag) {
      // Get the index of the Moment template tag in the moment_tags array of the momentTemplateFilters
      let momentTagIndex = this.momentTemplateFilters.moment_tags.findIndex(x => x === tag);
      // Remove or add the moment tag based on the index
      (momentTagIndex >= 0) ? this.momentTemplateFilters.moment_tags.splice(momentTagIndex, 1) : this.momentTemplateFilters.moment_tags.push(tag);      
      // Get the Moment templates
      this.getMomentTemplates();
    },
    toggleMomentTemplateTags() {
      (this.showAllMomentTemplateTags === false) ? this.showAllMomentTemplateTags = true : this.showAllMomentTemplateTags = false;
    },
    // Moment Template Languages
    setMomentTemplateLanguages() {
      if(this.momentTemplateLanguagesLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/languages')
        .then(res => {
          this.momentTemplateLanguages = res.data.data;
          // Update the momentTemplateLanguagesLoaded value
          this.momentTemplateLanguagesLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateLanguages
        let momentTemplateLanguages = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.momentTemplates.length; g++) {
          momentTemplateLanguages = momentTemplateLanguages.concat(this.momentTemplates[g].loyalty_moment_languages);
        }
        // Create momentTemplateLanguagesCount array with counts of remaining languages
        var momentTemplateLanguagesCount = momentTemplateLanguages.reduce((languageCount, language) => {
          if(languageCount.hasOwnProperty(language) === false) languageCount[language] = 0;
          languageCount[language]++;
          return languageCount;
        }, {});
        // Update the momentTemplateLanguages array
        this.momentTemplateLanguages = Object.keys(momentTemplateLanguagesCount).map(language => {
          return {language_name: language, moments_with_language: momentTemplateLanguagesCount[language]}; 
        });
        // Sort the momentTemplateLanguages
        this.momentTemplateLanguages.sort((a,b)=> (b.moments_with_language - a.moments_with_language || a.language_name.localeCompare(b.language_name)));
      }
    },    
    setSelectedMomentTemplateLanguages(language) {
      // Get the index of the Moment template language in the moment_languages array of the momentTemplateFilters
      let momentLanguageIndex = this.momentTemplateFilters.moment_languages.findIndex(x => x === language);
      // Remove or add the moment language based on the index
      (momentLanguageIndex >= 0) ? this.momentTemplateFilters.moment_languages.splice(momentLanguageIndex, 1) : this.momentTemplateFilters.moment_languages.push(language);      
      // Get the Moment templates
      this.getMomentTemplates();
    },
    toggleMomentTemplateLanguages() {
      (this.showAllMomentTemplateLanguages === false) ? this.showAllMomentTemplateLanguages = true : this.showAllMomentTemplateLanguages = false;
    },     
    // Moment
    setMomentScheduled(scheduleMoment) {
      this.moment.schedule_moment = scheduleMoment;
      
      if(scheduleMoment === true) {
        // Generate a new date
        let tomorrow = new Date();
        // Set the date to tomorrow
        tomorrow.setDate(tomorrow.getDate() + 1);
        // Update the scheduled_for value
        this.moment.scheduled_for = tomorrow;
      } else {
        this.moment.scheduled_for = null;
      }
      this.$forceUpdate();
    },
    setMomentPointsGranted(inputSource) {
      // Set the grant_points value
      this.moment.grant_points = (this.moment.points > 0) ? true : false;
      // Hide the slider if a button was used to set the points
      if(inputSource === 'button') this.showCustomPointsSlider = false;
      // Check the remaining budget if necessary
      if(['business', 'enterprise'].includes(this.productLicenses.loyalty_license_tag)) this.checkRemainingBudget(this.moment);        
    },
    getMomentSenderAliases() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-sender/aliases/my')
      .then(res => {
        this.momentSenderAliases = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    extractMomentCardElements(html) {
      // Find the start and end indices of the <body> tag
      const bodyStartIndex = html.indexOf('<body');
      const bodyEndIndex = html.lastIndexOf('</body>') + 7; // +7 to include '</body>'
      
      // Check if both <body> and </body> tags are present and in the correct order
      if (bodyStartIndex !== -1 && bodyEndIndex !== -1 && bodyEndIndex > bodyStartIndex) {
        // Extract HTML before the <body> tag
        this.topHtml = html.substring(0, bodyStartIndex);
        // Extract the entire <body> content, including the tags
        this.bodyContent = html.substring(bodyStartIndex, bodyEndIndex);
        // Extract HTML after the </body> tag
        this.bottomHtml = html.substring(bodyEndIndex);
        // Store the full HTML
        this.fullHtml = html;
      }
    },
    updateMomentCardColor(color) {
      this.$nextTick(() => {
        // If fullHtml is not set, extract elements from the template
        if(!this.fullHtml) {
          const cardElement = document.querySelector('.moment_card_html');
          if(cardElement) {
            this.extractMomentCardElements(this.momentTemplateData.html);
            this.defaultCardHtml = this.bodyContent;
          }
        }
        // Set current card HTML to default
        this.currentCardHtml = this.defaultCardHtml;
        
        if (color) {
          // Get color values from the mapping
          const colors = this.momentTemplateColorMappings[color];
          // Create a temporary div to manipulate HTML
          const tempDiv = document.createElement('div');        
          tempDiv.innerHTML = this.bodyContent;
          
          // Update background color of the card
          const momentCardBackground = tempDiv.querySelector('.u-col.u-col-100 > div');
          if (momentCardBackground) momentCardBackground.style.backgroundColor = colors.bg;

          // Update background color of the container
          const momentCardContainer = tempDiv.querySelector('.u-row-container');
          if (momentCardContainer) momentCardContainer.style.backgroundColor = colors.bg;

          // Update button background color
          const momentCardButton = tempDiv.querySelector('.v-button');
          if (momentCardButton) momentCardButton.style.backgroundColor = colors.button;
          
          // Update button text color
          const momentCardButtonText = tempDiv.querySelector('.v-button span');
          if (momentCardButtonText) momentCardButtonText.style.color = colors.text;
          
          // Preserve the original body tag, but update its content
          const bodyOpenTagEnd = this.bodyContent.indexOf('>') + 1;
          const bodyCloseTagStart = this.bodyContent.lastIndexOf('</body>');
          this.currentCardHtml = tempDiv.innerHTML;
          this.bodyContent = this.bodyContent.substring(0, bodyOpenTagEnd) + tempDiv.innerHTML + this.bodyContent.substring(bodyCloseTagStart);
        } else {
          // If no color is specified, reset to default
          this.currentCardHtml = this.defaultCardHtml;
          this.bodyContent = this.defaultCardHtml;
        }
        
        // Reconstruct the full HTML with updated body content
        this.fullHtml = this.topHtml + this.bodyContent + this.bottomHtml;
      });
    },
    selectRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.momentTemplateColorOptions.length);
      this.selectedColor = this.momentTemplateColorOptions[randomIndex];
      this.updateMomentCardColor(this.selectedColor);
    },    
    resetMomentData() {
      this.moment = {
        loyalty_moment_template_id_external: null,        
        recipients: [],
        filters: [],
        subject: null,        
        push_title: null,
        max_push_title_length: 20,
        remaining_push_title_length: 20,
        push_message: null,
        max_push_message_length: 60,
        remaining_push_message_length: 60,
        grant_points: null,
        points: 0,        
        schedule_moment: false,
        scheduled_for: null,
        send_moment_email: true,
        send_moment_push: true
      };      
    },
    checkRemainingBudget(momentData) {
      let params = {};
      params.checkData = {};
      params.checkData.requested_points_amount = (momentData.points * momentData.recipients.length);

      return new Promise((resolve, reject) => {
        if(this.checkRole('admin') === false && this.checkRole('super_admin') === false) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/budget/check', params)
          .then(res => {
            if(res.data.data.within_remaining_budget === true) {
              // Resolve the Promise as true
              resolve(true);
            } else {
              // Resolve the Promise as false
              resolve(false);
              // Show the momentExceedsBudgetModal
              this.momentExceedsBudgetModal = true;
              // Stop the loader
              this.sendingMoment = false;
              // Enable the send button
              this.sendButtonDisabled = false;            
            }
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
        } else {
          // Resolve the Promise as true
          resolve(true);          
        }
      });
    },
    validateMoment(params) {
      let recipients = params.momentData.recipients;
      let schedule_moment = params.momentData.schedule_moment;
      let scheduled_for = params.momentData.scheduled_for;
      let grant_points = params.momentData.grant_points;
      let points = params.momentData.points;

      // Check if base information for the moment is available and if the moment is scheduled when enabled
      if(recipients.length === 0 || (schedule_moment === true && !scheduled_for) || grant_points === null) {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        return false;
      } else {
        // Check if the correct license is available
        if(['business', 'enterprise'].includes(this.productLicenses.loyalty_license_tag)) {
          // Check budget cap
          return this.checkRemainingBudget(params.momentData);
        } else {
          return true;
        }
      }
    },
    async sendMoment() {
      // Disable the send button
      this.sendButtonDisabled = true;
      // Show the loader
      this.sendingMoment = true;
      // Set the params
      let params = {};
      params.momentData = this.moment;
      params.momentData.recipients_type = this.momentRecipientsType;
      if(!this.moment.json) params.momentData.json = null;
      (!this.fullHtml) ? params.momentData.html = null : params.momentData.html = this.fullHtml;

      if(this.moment.points) params.momentData.points = parseInt(this.moment.points);
      
      (params.momentData.send_moment_email == true) ? params.momentData.send_email = 1 : params.momentData.send_email = 0;
      (params.momentData.send_moment_push == true) ? params.momentData.send_push = 1 : params.momentData.send_push = 0;
      
      (this.useCustomDesignTemplate == true) ? params.momentData.custom_design = 1 : params.momentData.custom_design = 0;
      
      if(params.momentData.schedule_moment == true) {
        params.momentData.scheduled = 1;
        // Generate a new date
        let momentScheduledFor = new Date(params.momentData.scheduled_for);
        // Set the time to noon
        momentScheduledFor.setHours(12, 0, 0, 0);        
        // Set the moment_scheduled_for value in the correct format
        params.momentData.moment_scheduled_for = this.$luxon(momentScheduledFor.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.momentData.scheduled = 0;
        params.momentData.moment_scheduled_for = null;
      }
      
      if(params.momentData.grant_points == true) {
        params.momentData.points_granted = 1;
      } else {
        params.momentData.points_granted = 0;
        params.momentData.points = null;
      }

      if(params.momentData.filters) {
        params.momentData.recipient_filters = params.momentData.filters.filter(function (el) {
          return el.type != undefined && el.value != undefined;
        });
      }

      // Convert recipients array to nested array for explorer license 
      if(this.momentRecipientsType === 'colleagues' && this.productLicenses.loyalty_license_tag === 'explorer' && !params.momentData.recipients[0]) {
        params.momentData.recipients = [params.momentData.recipients];
      }

      // Validate the Moment
      const momentValid = await this.validateMoment(params);

      if(momentValid) {
        await axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment', params)
        .then(res => {  
          this.$buefy.toast.open({ message: this.moment.schedule_moment ? this.$t('sm.Spark_scheduled') : this.$t('sm.Spark_sent'), type: 'is-success', duration: 2000 });
          // Close the sidebar
          this.closeSidebarRight();
          // Emit the update_loyalty_moments
          this.$bus.$emit('update_loyalty_moments');
          // Stop the loader
          this.sendingMoment = false;
          // Enable the send button
          this.sendButtonDisabled = false;
        })
        .catch(err => {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          // Stop the loader
          this.sendingMoment = false;
          // Enable the send button
          this.sendButtonDisabled = false;
        });  
      }
    },
    // Moment Recipients
    switchMomentRecipientsView(recipientsView) {
      // Check if a the momentRecipientsType has already been set
      if(this.momentRecipientsType && (this.momentRecipientsType !== recipientsView)) {
        // Check if any recipient have been selected
        if(this.moment.recipients.length > 0) {
          // Update the momentRecipientsTypeSelected value
          this.momentRecipientsTypeSelected = recipientsView;
          // Show the momentRecipientsTypeModal
          this.momentRecipientsTypeModal = true;        
        } else {
          // Set the new recipients views
          this.setMomentRecipientsView(recipientsView);
        }
      }
    },
    setMomentRecipientsView(recipientsView) {
      this.momentRecipientsType = recipientsView;
      // Clear the recipients array of the moment
      this.moment.recipients = [];
      // Clear the recipients array
      if(this.momentRecipientsType === 'colleagues') this.recipients = [];  
      // Clear the filters array of the moment when the smart filters view is selected
      if(this.momentRecipientsType === 'smart_filters') this.moment.filters = [{ priority: 1 }];      

      if(this.momentRecipientsType === 'csv_upload') this.recipientsCSVUploaded = false;
      // Force update
      this.$forceUpdate();
    },
    setMomentRecipientsPagination() {
      // Set the pagination to the first page
      this.currentMomentRecipientsPage = 1;
      // Reset the momentRecipientsPages value
      this.momentRecipientsPages = 0;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < this.moment.recipients.length; i = i + this.momentRecipientsPerPage) {
        this.currentMomentRecipients[this.momentRecipientsPages] = this.moment.recipients.slice(i, i + this.momentRecipientsPerPage);
        this.momentRecipientsPages++;
      }
    },
    // Moment Recipients - CSV
    onRecipientsCSVChange(e) {
      // Start the loader
      this.csvEmployeesLoading = true;
      // Get the file        
      let recipientsCSV = e.target.files[0];
      // Create new formData
      const formData = new FormData();
      // Add the file to the formData
      formData.append('file', recipientsCSV);
      // Get the employees based on the CSV
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        this.moment.recipients = res.data.data;
        // Enable the pagination if necessary
        (this.moment.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
        // Set the pagination to the first page
        this.employeesCurrentPage = 1;
        // Reset the employeePages value
        this.employeePages = 0;
        // Loop through the Moment to set the pagination            
        for (let i = 0; i < this.moment.recipients.length; i = i + this.employeesPerPage) {
          this.currentPageEmployees[this.employeePages] = this.moment.recipients.slice(i, i + this.employeesPerPage);
          this.employeePages++;
        }
        // Check if the pagination for the table should be enables
        this.moment.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;
        // Update the recipientsCSVUploaded value
        this.recipientsCSVUploaded = true;               
        // Stop the loader
        this.csvEmployeesLoading = false;
        // Check the remaining budget if necessary                      
        if(['business', 'enterprise'].includes(this.productLicenses.loyalty_license_tag)) this.checkRemainingBudget(this.moment);
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    pickRecipientsCSV() {
      document.getElementById("csvUpload").click();
    },
    downloadExampleRecipientsCSV() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/csv')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });

        if(!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL
          outputData = res.data;
          url = window.URL.createObjectURL(new Blob([outputData]));
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute
          link.setAttribute('download', this.environmentTag + '_employees.csv');
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL
          outputData = res.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), this.environmentTag + "_employees.csv");
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    }, 
    // Moment Recipients - Smart Filters
    getMomentRecipients() {
      let _this = this;
      let params = {};
      params.use_priority = false;      
      
      params.filters = this.moment.filters.filter(function (el) {
        return el.type !== undefined && 
               el.condition !== undefined && (
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
               );
      });

      if(params.filters.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
        .then(res => {
          this.moment.recipients = res.data.data;
          // Enable the pagination if necessary
          (this.moment.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
          // Set the pagination to the first page
          this.employeesCurrentPage = 1;
          // Reset the employeePages value
          this.employeePages = 0;
          // Loop through the Moment to set the pagination            
          for (let i = 0; i < this.moment.recipients.length; i = i + this.employeesPerPage) {
            this.currentPageEmployees[this.employeePages] = this.moment.recipients.slice(i, i + this.employeesPerPage);
            this.employeePages++;
          }
          // Check if the pagination for the table should be enables
          this.moment.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;
          // Check the remaining budget if necessary                      
          if(['business', 'enterprise'].includes(this.productLicenses.loyalty_license_tag)) this.checkRemainingBudget(this.moment);
          // Force update
          this.$forceUpdate();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.moment.recipients = [];
      }
    },
    addFilterOption() {
      // Add new filter to filters array
      this.moment.filters.push({});
      // Force update
      this.$forceUpdate();
    },
    removeFilterOption(index) {
      // Remove filter from filters array
      this.moment.filters.splice(index, 1);
      // Update the recipients
      this.getMomentRecipients();
      // Force update
      this.$forceUpdate();
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {      
        this.groupFilterTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) {
        this.moment.filters[filterIndex].condition = this.typeFilterConditions[0];      

        if(this.conditionsWithoutValue.includes(this.moment.filters[filterIndex].condition.condition_tag)) {
          this.getMomentRecipients();
        }
      }
    },    
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.typeFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter type values
      this.getTypeFilterValues(filterTypeTag);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'department_manager') {
        this.getGroupFilterDepartmentManagers();        
      } else if(filterTypeTag === 'team_manager') {
        this.getGroupFilterTeamManagers();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartmentManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department-managers/multiselect')
      .then(res => {
        this.groupFilterDepartmentManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterTeamManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team-managers/multiselect')
      .then(res => {
        this.groupFilterTeamManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Async Search
    asyncFindUser(query) {
      let searchTerm = query;
      this.recipients = [];

      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.recipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },    
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindRecipient(query) {
      let searchTerm = query;
      this.groupFilterRecipients = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.groupFilterRecipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartmentManager(query) {
      let searchTerm = query;
      this.groupFilterDepartmentManagers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/department-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartmentManagers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeamManager(query) {
      let searchTerm = query;
      this.groupFilterTeamManagers = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/team-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterTeamManagers = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },       
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    // Other
    getUserDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.user = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    countdown() {
      if(this.moment.custom_message) this.remainingCount.custom_message = this.maxCount.custom_message - this.moment.custom_message.length;
      if(this.saveMomentTemplateModalData.description) this.remainingCount.moment_template_description = this.maxCount.moment_template_description - this.saveMomentTemplateModalData.description.length;
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    toggleFlip() {
      this.isFlipped = !this.isFlipped;
    },    
    showMomentCardBack() {
      if(!this.isFlipped) this.isFlipped = true;
    },
    setCustomMessageFieldFocus() {
      this.$refs.customMessageInput.focus();
    },
    updateCardHeight() {
      if(this.updateTimer) clearTimeout(this.updateTimer);

      const updateHeight = () => {
        const momentCard = this.$refs.momentCard;
        const momentContainer = this.$refs.momentContainer;
        
        if (!momentCard || !momentContainer) {
          if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
          return;
        }

        // Get all images within the moment card
        const images = momentCard.getElementsByTagName('img');
        const imagePromises = Array.from(images).map(img => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = () => resolve();
              img.onerror = () => resolve(); // Handle failed image loads
            }
          });
        });

        // Wait for all images to load before calculating height
        Promise.all(imagePromises).then(() => {
          const height = Math.max(momentCard.offsetHeight, this.minHeight);

          if (height > 0) {
            momentContainer.style.height = `${height}px`;
            this.isContentLoaded = true;
          } else if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
        });
      };

      this.$nextTick(updateHeight);
    },
    resetHeightUpdate() {
      this.heightUpdateAttempts = 0;
      this.isContentLoaded = false;
      this.updateCardHeight();
    },
    setupContentObserver() {
      // Cleanup existing observer if any
      if(this.contentObserver) this.contentObserver.disconnect();
      // Create new observer
      this.contentObserver = new MutationObserver((mutations) => {
        let shouldUpdate = false;
        mutations.forEach(mutation => {
          if(mutation.type === 'childList' || mutation.type === 'characterData' || mutation.type === 'attributes') shouldUpdate = true;
        });

        if(shouldUpdate) this.updateCardHeight();
      });

      // Start observing if momentCard exists
      if (this.$refs.momentCard) {
        this.contentObserver.observe(this.$refs.momentCard, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true
        });
      }
    },
    editorReady() {
      this.emailEditorReady = true;
            
      this.$refs['emailEditor_loyalty_moment'].editor.setBodyValues({
        backgroundColor: "#F6F6F6",
        textColor: '#4A4A4A',
        contentAlignment: 'center',
        links: {
          color: '#4A4A4A',
          underline: true
        }
      });
    }
  },
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    this.productLicenses = await this.fetchProduuctLicenseData();    

    this.getDefaultCompanyLanguage();
    this.getMomentTemplateColors();
    this.getMomentSenderAliases();

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();    
    this.getUserDetails();

  },
  beforeDestroy() {        
    // Cleanup
    window.removeEventListener('resize', this.updateCardHeight);
    
    if(this.updateTimer) clearTimeout(this.updateTimer);
    if(this.contentObserver) this.contentObserver.disconnect();  
  }    
}
</script>