<template>
  <div v-if="showReminder">
    <b-collapse  class="panel dashboard show mb-0" :open="true">
      <div slot="trigger" slot-scope="props" class="panel-heading p-0" role="button">
        <CRow class="w-100 m-0">
          <CCol cols="12" lg="12" class="d-flex" v-bind:class="{'pb-0' : props.open}">
            <h1 class="dashboard_page_title mb-0">{{$t('sm.Last_sent_spark')}}</h1>
            <i class="fas" v-bind:class="{'fa-chevron-up' : props.open, 'fa-chevron-down' : !props.open}"/>
          </CCol>
        </CRow>
      </div>
      <div class="panel-block p-0">
        <CRow class="w-100 m-0">
          <CCol cols="12" lg="12">
            <CCard class="mb-0 events moments last_sent_moment">
              <CCardHeader v-if="showHeader" class="pb-0">
                <CRow>
                  <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
                    <span>{{$t('sm.Last_sent_spark')}}</span>
                  </CCol>          
                </CRow>
              </CCardHeader>
              <CCardBody class="p-0">
                <div v-if="momentLoading === true">           
                  <CRow>
                    <CCol cols="12" lg="12">
                      <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>          
                  <CRow class="m-0">
                    <CCol cols="12" lg="12" md="12">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center">
                          <div class="flex-grow-1 d-flex flex-column">
                            <label><b>{{$t('sm.Spark')}}</b></label>
                            <span>{{moment.name}}</span>
                          </div>
                          <div class="flex-grow-1 d-flex flex-column">
                            <label><b>{{$t('communications.Total_recipients')}}</b></label>
                            <span v-if="moment.total_recipients">{{moment.total_recipients}} {{moment.total_recipients !== 1 ? $t('common.recipients') : $t('common.recipient')}}</span>
                          </div>
                          <div class="flex-grow-1 d-flex flex-column">
                            <label><b>{{$t('groups.Filters_used')}}</b></label>
                            <div v-if="moment.total_filters" class="d-flex align-items-center">
                              <span class="mr-2">{{moment.total_filters}} {{moment.total_filters !== 1 ? $t('common.filters') : $t('common.filter')}}</span>                  
                              <targetGroupFiltersPopover targetGroupsType="loyalty moment" :targetGroupsIdExternal="moment.loyalty_moment_id_external" popoverPlacement="left"/>
                            </div>
                            <span v-else>-</span>
                          </div>
                          <div class="flex-grow-1 d-flex flex-column">
                            <label><b>{{$t('common.Coins_granted')}}</b></label>
                            <div v-if="moment.points_granted === 1" class="d-flex align-items-center credits">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                              <span>{{moment.points}} {{moment.points !== 1 ? $t('common.coins') : $t('common.coin')}}</span>
                            </div>
                          </div>
                          <div class="flex-grow-1 d-flex flex-column">
                            <label><b>{{$t('common.Sent')}}</b></label>
                            <div v-if="moment.sent" class="d-flex align-items-center date">
                              <i class="fa-regular fa-calendar"></i>
                              <span v-if="moment.sent">{{moment.sent | moment("DD-MM-YYYY HH:mm")}}</span>
                            </div>
                            <span v-else>-</span>              
                          </div>                                                      
                        </div>
                        <div v-if="checkPermission('loyalty.moments.send') && company.payment_status === true && showWizardButton" class="send_moment_button">
                          <CCard class="navigation_card pointer fit-content m-0" @click="openLoyaltyMomentWizard();">
                            <CCardBody class="p-2">
                              <div class="d-flex align-items-center">
                                <div class="mr-2">                              
                                  <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/spark.png/' + clientToken"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span><b>{{$t('sm.Send_a_spark')}}</b></span>
                                </div>
                              </div>
                            </CCardBody>
                          </CCard>            
                        </div>
                      </div>
                    </CCol>                  
                  </CRow>
                </div>
              </CCardBody>
            </CCard>      
          </CCol>
        </CRow> 
      </div>
    </b-collapse>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import targetGroupFiltersPopover from '@/components/common/targetGroupFiltersPopover.vue'

export default {
  name: 'loyaltyMomentsReminder',
  props: ['platformPermissions', 'showHeader', 'showWizardButton'], 
  components: {
    loadingSpinner,
    targetGroupFiltersPopover
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      company: {},
      moment: [],      
      momentLoading: false,
      showReminder: false
    }
  },
  methods: {    
    getLastSentMoment() {
      // Start the loader
      this.momentLoading = true;
      // Get the Moments
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/last')
      .then(res => {              
        this.moment = res.data.data;
        // Update the showReminder variabale
        if(this.moment) this.showReminder = true;
        // Stop the loader
        this.momentLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openLoyaltyMomentWizard() {
      this.$router.push({path: '/loyalty/moments/send'});
    },
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }                
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
        
    this.getCompanyDetails();
    this.getLastSentMoment();
  }
}
</script>