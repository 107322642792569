<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.organization.import')" class="user_import">
    <CCol col="6" xl="6" lg="12" md="12" sm="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('users.Import_users') }} ({{ $t('users.Maximum_amount') }} {{licenseData.allowed_company_users}} {{ $t('common.users') }})      
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0">
              <p v-html="$t('users.Import_users_explanation')" class="m-0"></p>              
            </CCol>
          </CRow> 
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <vue-csv-import id="vueCSVInput" v-show="fieldsLoaded" ref="vueCSVImport" v-model="importData" input-class="csv_select" table-class="table csv_mapping" :map-fields="importFields" :auto-match-fields="true" headers="true">
                <template slot="thead">
                  <tr>
                    <th><span>{{ $t('users.Necessary_data') }}</span></th>
                    <th><span>{{ $t('users.Column_in_csv_file') }}</span></th>
                  </tr>
                </template>
                <template slot="next" slot-scope="{load}">
                  <CButton v-show="!hideMappingButton" color="primary" @click.prevent="load" class="m-0 mt-2_5">
                    <span><i class="fas fa-file-upload mr-1"/>{{$t('users.Map_csv_columns')}}</span>
                  </CButton>
                </template>
              </vue-csv-import>

              <CButton v-show="!hideValidationButton" color="primary" @click="validateImportData();" class="m-0 mt-2_5">
                <span><i class="fas fa-check mr-1"/>{{$t('user_import.Validate_mapping')}}</span>
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>        
      </CCard>

      <b-modal class="import" :can-cancel="[]" :active.sync="validationModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('user_import.Validating_your_csv_file')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow v-if="!isValidating">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <div v-if="csvErrorMessages.length > 0">
                    <span>{{$t('user_import.Your_csv_file_has_been_validated')}} {{$t('user_import.Your_csv_file_contains_errors')}}:</span>
                    <div v-for="(errorMessage, index) in csvErrorMessages" :key="index" class="error_messages mt-2 mb-2">
                      <span>{{errorMessage.message}}</span>
                    </div>
                    <span>{{$t('user_import.Please_fix_the_errors')}}</span>
                  </div>
                  <div v-else>
                    <span>{{$t('user_import.Your_csv_file_has_been_validated')}} {{$t('user_import.Your_csv_file_contains_no_errors')}}</span>
                  </div>
                </CCol>            
              </CRow>    
            </CCardBody>
            <CCardFooter>            
              <CButton v-if="csvErrorMessages.length === 0" color="primary" @click="submitImportData()"><i class="fas fa-file-upload mr-1"/>{{ $t('user_import.Continue_with_import') }}</CButton>
              <CButton color="secondary" @click="clearImportData();"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
            </CCardFooter>
            <b-loading :is-full-page="isFullPage" :active.sync="isValidating" :can-cancel="true">
              <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin"></b-icon>
              <span style="margin-left:10px">{{ $t('user_import.Validating_csv_file2') }}</span>
            </b-loading>
          </b-notification>
        </CCard>
      </b-modal>

      <b-modal class="import" :can-cancel="[]" :active.sync="maxUserModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('users.Import_not_allowed')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0">
                  <p class="mb-0">{{ $t('users.Your_csv_contains') }} {{importData.length}} {{ $t('common.users') }}. {{ $t('users.This_exceeds_maximum') }} ({{licenseData.allowed_company_users}}). {{ $t('users.change_csv_or_update_license') }}</p>
                </CCol>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CButton v-if="checkPermission('core.license')" color="primary" @click="openLicenseSettings()"><i class="fas fa-key mr-1"/>{{$t('common.View_current_license')}}</CButton>
                  <CButton color="secondary" @click="maxUserModal = false"><i class="fas fa-times mr-1"/>{{ $t('close') }}</CButton>
                </CCol>                                        
              </CRow>    
            </CCardBody>
          </b-notification>
        </CCard>
      </b-modal>

      <b-modal class="import" :can-cancel="[]" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('users.Confirm_user_import')}}
          </CCardHeader>
          <b-notification :closable="false">
            <CCardBody>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <p class="mb-0">{{ $t('users.Sure_to_import') }} {{importData.length}} {{importData.length != 1 ? $t('common.users') : $t('common.user')}} {{ $t('users.will_be_imported') }}.</p>                        
                </CCol>            
              </CRow>    
            </CCardBody>
            <CCardFooter>
              <CButton color="primary" @click="importUsers()"><i class="fas fa-check mr-1"/>{{ $t('users.Confirm_import') }}</CButton>
              <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
            </CCardFooter>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true">
              <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin"></b-icon>
              <span style="margin-left:10px">{{ $t('users.Importing_users') }}</span>
            </b-loading>
          </b-notification>
        </CCard>
      </b-modal>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import { VueCsvImport } from 'vue-csv-import';

import noPermission from '@/components/common/noPermission.vue';
import howToLink from '@/components/common/howToLink.vue';

export default {
  name: 'ImportUsers',
  components: {
    VueCsvImport,
    noPermission,
    howToLink
  },
  data: () => {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      environmentTag: null,
      environmentHelpers: {
        environment_name: null,
        environment_help_url: null
      },       
      importData: [],
      licenseData: {
        allowed_company_users: 0
      },
      companyData: {
        nr_of_departments: 0,
        nr_of_teams: 0
      },
      confirmModal: false,
      maxUserModal: false,
      validationModal: false,
      isLoading: false,
      isValidating: false,
      isFullPage: false,
      importFields: {},
      fieldsLoaded: false,
      hideMappingButton: true,
      hideValidationButton: true,    
      submitButtonDisabled: true,        
      csvEmployeeNumbers: [],
      csvErrorMessages: []
    }
  },
  methods: {
    getCompanyData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {
        this.companyData = res.data.data;
        // Set the import fields
        this.setImportFields();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    importUsers() {
      let params = {};
      params.users = this.importData;
      this.isLoading = true;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/users/import', params)
      .then(res => {
        // Hide the loader
        this.isLoading = false;
        // Hide the confirmation modal
        this.confirmModal = false;
        // Show succes toast
        this.$buefy.toast.open({ message: this.$t('users.Users_imported'), type: 'is-success', duration: 4000 });
        // Navigate to user overview
        this.$router.push({path: '/core/organization'});
      })
      .catch(err => {
        // Hide the loader
        this.isLoading = false;
        // Hide the confirmation modal
        this.confirmModal = false;
        // Show correct error message according to error
        if(err.response.data.error === 'License upgrade needed') {          
          this.$buefy.toast.open({ message: this.$t('users.Upgrade_license_for_import'), type: 'is-danger', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        }
      })
    },
    setImportFields() {
      // Set the importFields
      this.importFields = {
        name: this.$t('user_import.Employee') + '*',
        email: this.$t('user_import.Email') + '*',
        department_name: this.$t('user_import.Department') + '*',
        team_name: this.$t('user_import.Team') + '*',
        dob: this.$t('user_import.Date_of_birth'),
        gender: this.$t('user_import.Gender'),
        phone_number: this.$t('user_import.Phone'),
        external_id: this.$t('user_import.External_id'),
        user_function: this.$t('user_import.Function'), 
        active: this.$t('common.Active'),
        date_started: this.$t('user_import.Date_started'),
        date_left: this.$t('user_import.Date_left'),
        hours_on_contract: this.$t('user_import.Hours_on_contract'),
        meyer_briggs: this.$t('user_import.Meyer_briggs'),
        disc: this.$t('user_import.Disc'),
        office_based: this.$t('user_import.Office_based'),
        division: this.$t('user_import.Division'),
        language: this.$t('user_import.Language'),
        persona_1: this.$t('user_import.Persona_1'),
        persona_2: this.$t('user_import.Persona_2'),
        persona_3: this.$t('user_import.Persona_3'),
	      work_location: this.$t('user_import.Work_location')
      };

      // Force the file input to only accept CSV files
      setTimeout(function() {
        const fileInput = document.querySelector('input[name="csv"]');
        if(fileInput) fileInput.accept = '.csv';
        // Update the fieldsLoaded value
        this.fieldsLoaded = true;
      }.bind(this), 500);
      
      // Wait 1000ms and set the event listener on the CSV input
      setTimeout(function() {
        let csvSelect = document.querySelector('.csv_select');
        csvSelect.addEventListener('change', e => {
          // Load the new CSV file if a new one is uploaded
          this.$refs.vueCSVImport.load();
          // Reset the csvErrorMessages array
          this.csvErrorMessages = [];
          // Update the hideMappingButton value
          this.hideMappingButton = false;
          // Update the hideValidationButton value
          this.hideValidationButton = false;
        });
      }.bind(this), 1000);    
    },
    validateImportData() {
      // Trigger the submit function of the import plugin
      this.$refs.vueCSVImport.submit();     
      
      // Check if the importData contains data
      if(this.importData.length > 0) {
        // Show the validation modal
        this.validationModal = true;
        // Update the isValidating value
        this.isValidating = true;
        // Disable the submit button
        this.submitButtonDisabled = true;
        // Reset the csvErrorMessages array
        this.csvErrorMessages = [];

        // Define valid values for Y/N fields
        const validYesNoValues = ['Y', 'N'];
        // Define valid gender values
        const validGenderValues = ['M', 'F', 'G', 'T', 'N'];
        
        // Updated date format regular expressions
        const dateRegexPatterns = [
          /^\d{4}-(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[12]\d|3[01])$/, // YYYY-MM-DD and YYYY-M-D
          /^(?:0?[1-9]|[12]\d|3[01])-(?:0?[1-9]|1[0-2])-\d{4}$/, // DD-MM-YYYY and D-M-YYYY
        ];

        // Helper function to format date as YYYY-MM-DD
        const formatDate = (date) => {
          const d = date || new Date();
          return d.toISOString().slice(0, 10);
        };

        // Helper function to parse date string to Date object
        const parseDateString = (dateStr) => {
          // Try YYYY-MM-DD format first
          if (dateRegexPatterns[0].test(dateStr)) {
            const [year, month, day] = dateStr.split('-');
            return new Date(year, parseInt(month) - 1, parseInt(day));
          }
          // Try DD-MM-YYYY format
          else if (dateRegexPatterns[1].test(dateStr)) {
            const [day, month, year] = dateStr.split('-');
            return new Date(year, parseInt(month) - 1, parseInt(day));
          }
          return null;
        };

        // Helper function to validate date format and value
        const validateDate = (date, fieldName, user, index) => {
          if (!date || date.trim() === '') {
            return true; // Empty dates are allowed
          }

          // Check if the date matches any of the valid formats
          const isValidFormat = dateRegexPatterns.some(regex => regex.test(date));
          
          if (!isValidFormat) {
            this.csvErrorMessages.push({
              index, 
              user, 
              message: this.$t('user_import.error.invalid_date_format', {
                row: index + 2,
                field_name: fieldName,
                date: date,
                example_date: formatDate(new Date())
              })
            });
            return false;
          }

          // Parse the date
          const dateObj = parseDateString(date);
          
          if (!dateObj || isNaN(dateObj.getTime())) {
            this.csvErrorMessages.push({
              index, 
              user, 
              message: this.$t('user_import.error.invalid_date', {
                row: index + 2,
                date: date,
                field_name: fieldName
              })
            });
            return false;
          }

          return true;
        };

        // Check if importData is an array
        if(!Array.isArray(this.importData)) {
          return { isValid: false, errors: ['Input must be an array of user objects'] };
        }

        // Keep track of seen email addresses and external IDs
        const seenEmails = new Map();
        const seenExternalIds = new Map();

        // Validate each object
        this.importData.forEach((user, index) => {
          const rowNumber = index + 2; // Adding 2 to account for 0-based index and header row

          // Name validation
          if(!user.name || typeof user.name !== 'string' || user.name.trim() === '') {
            this.csvErrorMessages.push({
              index, user, message: this.$t('user_import.error.missing_name', { row: rowNumber })
            });
          }

          // Email validation
          if (!user.email || typeof user.email !== 'string') {
            this.csvErrorMessages.push({
              index, user, message: this.$t('user_import.error.missing_email', { row: rowNumber })
            });
          } else {
            // Regular expression for email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            
            if (!emailRegex.test(user.email)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.invalid_email', {
                  row: rowNumber,
                  email: user.email
                })
              });
            }

            // Check for duplicate emails
            const normalizedEmail = user.email.toLowerCase();
            if (seenEmails.has(normalizedEmail)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.duplicate_email', {
                  row: rowNumber,
                  email: user.email
                })
              });
            } else {
              seenEmails.set(normalizedEmail, user);
            }
          }

          // Department name validation
          if(!user.department_name || typeof user.department_name !== 'string' || user.department_name.trim() === '') {
            this.csvErrorMessages.push({
              index, user, message: this.$t('user_import.error.missing_department_name', { row: rowNumber })
            });
          }
          
          // Team name validation
          if(!user.team_name || typeof user.team_name !== 'string' || user.team_name.trim() === '') {
            this.csvErrorMessages.push({
              index, user, message: this.$t('user_import.error.missing_team_name', { row: rowNumber })
            });
          }          

          // External ID validation (only if the field exists and has a non-empty value)
          if ('external_id' in user && user.external_id && user.external_id.trim() !== '') {
            // Check for duplicate external IDs
            if (seenExternalIds.has(user.external_id)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.duplicate_external_id', {
                  row: rowNumber,
                  external_id: user.external_id
                })
              });
            } else {
              seenExternalIds.set(user.external_id, user);
            }
          }

          // Gender validation (only if the field exists in the object)
          if ('gender' in user && user.gender !== '') {
            if (!validGenderValues.includes(user.gender)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.invalid_gender', {
                  row: rowNumber,
                  gender: user.gender,
                  valid_values: validGenderValues.join(', ')
                })
              });
            }
          }

          // Date validations
          validateDate(user.dob, 'dob', user, index);
          validateDate(user.date_started, 'date_started', user, index);
          
          if ('date_left' in user && user.date_left && user.date_left.trim() !== '') {
            const isValidEndDate = validateDate(user.date_left, 'date_left', user, index);
            
            // Additional validation: date_left should be after date_started if both exist
            if (isValidEndDate && user.date_started && user.date_started.trim() !== '') {
              const startDateObj = parseDateString(user.date_started);
              const endDateObj = parseDateString(user.date_left);
              
              if (startDateObj && endDateObj && startDateObj > endDateObj) {
                this.csvErrorMessages.push({
                  index, user, message: this.$t('user_import.error.end_date_before_start', {
                    row: rowNumber,
                    end_date: user.date_left,
                    start_date: user.date_started
                  })
                });
              }
            }
          }

          // Active status validation
          if ('active' in user && user.active !== '') {
            if (!validYesNoValues.includes(user.active)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.invalid_active_status', {
                  row: rowNumber,
                  active: user.active
                })
              });
            }
          }

          // Office based validation
          if ('office_based' in user && user.office_based !== '') {
            if (!validYesNoValues.includes(user.office_based)) {
              this.csvErrorMessages.push({
                index, user, message: this.$t('user_import.error.invalid_office_based', {
                  row: rowNumber,
                  office_based: user.office_based
                })
              });
            }
          }
        });

        // Wait 2000ms before resetting the isValidating value
        setTimeout(function() {
          this.isValidating = false;
        }.bind(this), 2000);
      } else {          
        this.$buefy.toast.open({ 
          message: this.$t('user_import.error.no_columns_mapped'), 
          type: 'is-danger', 
          duration: 2000 
        });
      }        
    },
    submitImportData() {
      // Close the validation modal
      this.validationModal = false;
      // Wait 1000ms and open the next modal based on the current license
      setTimeout(function() {
        this.licenseData.seats_available ? this.confirmModal = true : this.maxUserModal = true;
      }.bind(this), 500);

    },
    clearImportData() {
      // Close the validation modal
      this.validationModal = false;
      // Reset the value of the file input
      document.querySelector('.csv_select').value = null;
      // Clear the csvEmployeeNumbers array
      this.csvEmployeeNumbers = [];
      // Reset the csvErrorMessages array
      this.csvErrorMessages = [];         
    },
    getLicenseData() {
      // Get the license data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/license/seatsAvailable')
      .then(res => {
        this.licenseData = res.data.data;  
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openLicenseSettings() {
      this.$router.push({path: '/core/license'});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');  
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));

    this.getLicenseData(); 
    this.getPlatformPermissions();
    this.getCompanyData();
  }
}
</script>
