<template>
  <div>  
    <CCard class="mb-0 events moments">
      <CCardHeader v-if="showHeader" class="pb-0">
        <CRow>
          <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
            <span>{{$t('sm.Sparks')}}</span>
          </CCol>
          <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
            <div class="mt-1 mt-xl-0 d-flex align-items-start justify-content-xl-end">          
              <CButton v-if="checkRole('super_admin') || checkRole('admin') || checkRole('loyalty')" color="primary" class="orange m-0" @click="openLoyaltyMomentTemplatesPage()">
                <span><i class="fas fa-pen mr-1"/>{{$t('sm.Edit_spark_templates')}}</span>
              </CButton>
            </div>
          </CCol>          
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">        
        <CRow class="moment_filters m-0">
          <CCol cols="3" xl="3" lg="6" md="6" sm="12">
            <div class="d-flex align-items-center">
              <b-datepicker v-model="dateFrom"
                            icon="calendar-day"
                            icon-pack="fas"
                            :first-day-of-week="1"
                            :show-week-number="true"
                            :min-date="minDateFrom"
                            :max-date="maxDateFrom"
                            :years-range="[-3, 10]"
                            :placeholder="$t('common.Date_from')"
                            @input="setMinDateBoundary(); getMoments();"
                            class="d-inline-block align-middle">
                <CButton color="primary" @click="setDateFromValue(true); getMoments();">
                  <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                </CButton>
                <CButton color="secondary" @click="setDateFromValue(false); getMoments();">
                  <i class="fas fa-times mr-1"/>{{$t('Clear')}}
                </CButton>              
              </b-datepicker>
              <CButton color="secondary" @click="setDateFromValue(false); getMoments();" class="reset ml-2">
                <i class="fas fa-undo"/>
              </CButton>             
            </div>            
          </CCol>
          <CCol cols="3" xl="3" lg="6" md="6" sm="12">
            <div class="d-flex align-items-center">
              <b-datepicker v-model="dateTo"
                            icon="calendar-day"
                            icon-pack="fas"
                            :first-day-of-week="1"
                            :show-week-number="true"
                            :min-date="minDateTo"
                            :max-date="maxDateTo"
                            :years-range="[-3, 10]"
                            :placeholder="$t('common.Date_to')"
                            @input="setMaxDateBoundary(); getMoments();"
                            class="d-inline-block align-middle">
                <CButton color="primary" @click="setDateToValue(true); getMoments();">
                  <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
                </CButton>
                <CButton color="secondary" @click="setDateToValue(false); getMoments();">
                  <i class="fas fa-times mr-1"/>{{$t('Clear')}}
                </CButton>            
              </b-datepicker>
              <CButton color="secondary" @click="setDateToValue(false); getMoments();" class="reset ml-2">
                <i class="fas fa-undo"/>
              </CButton>             
            </div>            
          </CCol>
          <CCol cols="3" xl="3" lg="6" md="6" sm="12">
            <div class="d-flex align-items-center">
              <multiselect class="data_table open_absolute with_icon moment_sent"
                           v-model="momentSentBy" 
                           :options="senders" 
                           :multiple="false"                
                           :placeholder="$t('common.Sent_by')" 
                           :selectLabel="$t('common.Add_user')" 
                           :selectedLabel="$t('Added')"
                           :deselectLabel="$t('common.Remove_user')"
                           track-by="user_id_external" 
                           label="label"                        
                           @search-change="asyncFindSender"
                           @input="getMoments();">
                <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
              </multiselect>
              <CButton color="secondary" @click="momentSentBy = null; getMoments();" class="reset ml-2">
                <i class="fas fa-undo"/>
              </CButton>             
            </div>
          </CCol>
          <CCol cols="3" xl="3" lg="6" md="6" sm="12">
            <div class="d-flex align-items-center">
              <multiselect class="data_table open_absolute with_icon moment_received"
                           v-model="momentSentTo" 
                           :options="receivers" 
                           :multiple="false"                
                           :placeholder="$t('common.Sent_to')" 
                           :selectLabel="$t('common.Add_user')" 
                           :selectedLabel="$t('Added')"
                           :deselectLabel="$t('common.Remove_user')"
                           track-by="user_id_external" 
                           label="label"                        
                           @search-change="asyncFindReceiver"
                           @input="getMoments();">
                <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
              </multiselect>
              <CButton color="secondary" @click="momentSentTo = null; getMoments();" class="reset ml-2">
                <i class="fas fa-undo"/>
              </CButton>             
            </div>              
          </CCol>          
        </CRow>

        <CRow class="moment_list">
          <CCol cols="12" lg="12" md="12" :class="{'pt-0 pb-0' : !momentsLoading}">
            <div class="card_container" id="masonryWall">
              <loadingSpinner mode="auto" v-if="momentsLoading" :content="$t('common.Loading')"/>

              <vue-masonry-wall v-else-if="!momentsLoading && moments.length > 0" :items="moments" :options="{width: 400}" :ssr="{columns: 2}" class="pt-0 pb-0">
                <template #default="{item}">                  
                  <loyaltyMomentCard v-if="item"
                                     :platformPermissions="platformPermissions"
                                     :modules="modules"
                                     :momentData="item" 
                                     :showEditButton="item.moment_scheduled && item.moment_editable && checkPermission('loyalty.moments.edit')"
                                     :showDeleteButton="item.moment_scheduled && item.moment_deletable && checkPermission('loyalty.moments.delete')"
                                     :showInsights="true">
                  </loyaltyMomentCard>
                </template>                
              </vue-masonry-wall>

              <div v-else-if="!momentsLoading && moments.length === 0">
                <CRow class="m-0">
                  <CCol cols="12" lg="12" :class="{'pt-0' : showHeader}">
                    <span>{{$t('sm.No_sparks_sent_yet')}}</span>
                  </CCol>
                </CRow>
              </div>
            </div>

            <CButton v-if="moments.length > 0 && showScrollToTopButton" class="scroll_top m-0" color="primary" @click="scrollToTop()">
              <span><i class="fas fa-arrow-up mr-1"/>{{$t('common.Scroll_to_top')}}</span>
            </CButton>            
          </CCol>                 
        </CRow>

        <CRow v-if="!momentsLoading && !allMomentsLoaded">
          <CCol cols="12" lg="12" md="12" class="pt-0">
            <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
          </CCol>
        </CRow>        
      </CCardBody>
    </CCard>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('sm.Remove_scheduled_spark', { moment_name : confirmModalData.name})}} ({{confirmModalData.scheduled_for | moment("DD-MM-YYYY HH:mm")}})
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0" v-html="$t('sm.Are_you_sure_to_delete_scheduled_spark')"></p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deleteLoyaltyMoment(confirmModalData.loyalty_moment_id_external);"><i class="fas fa-trash mr-1"/>{{$t('Delete')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect';
import VueMasonryWall from "vue-masonry-wall";

import userProfilePopover from '@/components/common/userProfilePopover.vue';
import targetGroupFiltersPopover from '@/components/common/targetGroupFiltersPopover.vue'
import loyaltyMomentCard from '@/components/loyalty/loyaltyMomentCard.vue';

export default {
  name: 'loyaltyMoments',
  props: ['platformPermissions', 'platformRoles', 'modules', 'showHeader'],
  components: {
    loadingSpinner,
    Multiselect,
    VueMasonryWall,
    userProfilePopover,
    targetGroupFiltersPopover,
    loyaltyMomentCard
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      company: {},
      moments: [],
      momentsLoading: false,
      momentsLoaded: false,
      showScrollToTopButton: false,
      defaultMomentAmount: 10,
      allMomentsLoaded: false,
      confirmModal: false,
      confirmModalData: {},
      dateFrom: null,
      dateTo: null,
      minDateTo: null,
      maxDateTo: null,
      minDateFrom: null,
      maxDateFrom: null,      
      momentSentBy: null,
      momentSentTo: null,
      senders: [],
      receivers: []
    }
  },
  methods: {
    setMinDateBoundary() {
      this.minDateTo = this.dateFrom;
    },
    setMaxDateBoundary() {
      this.maxDateFrom = this.dateTo;
    },
    setDateToValue(isToday) {
      this.dateTo = (isToday) ? new Date() : null;      
      this.maxDateFrom = new Date();
    },
    setDateFromValue(isToday) {
      if(isToday) {
        this.dateFrom = new Date();
        (this.dateTo) ? this.dateTo = new Date() : this.dateTo = null;
        this.minDateTo = new Date();
        this.maxDateFrom = new Date();
      } else {
        this.dateFrom = null;
        (this.dateTo) ? this.maxDateFrom = this.dateTo : new Date();
        this.minDateTo = null;        
      }      
    },
    getMoments() {
      // Set the params
      let params = {};
      params.senders = [];
      params.receivers = [];
      params.date_from = null;
      params.date_to = null;

      // Format the date params for the API       
      if(this.dateFrom) params.date_from = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      if(this.dateTo) params.date_to = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");      
      
      // Check if momentSentBy array has been set
      if(this.momentSentBy) {
        // Convert momentSentBy array to nested array
        let momentSentBy = !this.momentSentBy[0] ? [this.momentSentBy] : this.momentSentBy;
        // Add the external user ID of all senders to the senders param
        for(let t = 0; t < momentSentBy.length; t++) {
          params.senders.push(momentSentBy[t].user_id_external);
        }
      }

      // Check if momentSentTo array has been set
      if(this.momentSentTo) {
        // Convert momentSentTo array to nested array
        let momentSentTo = !this.momentSentTo[0] ? [this.momentSentTo] : this.momentSentTo;
        // Add the external user ID of all senders to the senders param
        for(let t = 0; t < momentSentTo.length; t++) {
          params.receivers.push(momentSentTo[t].user_id_external);
        }
      }

      // Start the loader
      this.momentsLoading = true;
      // Set default moments array
      this.moments = [];
      // Get the current number of items      
      let currentItemNumber = this.moments.length;
      // Get the Moments
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moments/' + currentItemNumber, params)
      .then(res => {                
        let moments = res.data.data.moments;
        let totalMoments = res.data.data.total_moments;
        // Add the Moments to the Moments array
        for (let m = 0; m < moments.length; m++) {     
          this.moments.push(moments[m]);
        }
        // Stop the loader
        this.momentsLoading = false;
        // Check if additional moments are available
        if(totalMoments <= this.defaultMomentAmount) this.allMomentsLoaded = true;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMoreMoments() {
      // Set the params
      let params = {};
      params.senders = [];
      params.receivers = [];
      params.date_from = null;
      params.date_to = null;      
     
      // Format the date params for the API       
      if(this.dateFrom) params.date_from = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      if(this.dateTo) params.date_to = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      
      // Check if momentSentBy array has been set
      if(this.momentSentBy) {
        // Convert momentSentBy array to nested array
        let momentSentBy = !this.momentSentBy[0] ? [this.momentSentBy] : this.momentSentBy;
        // Add the external user ID of all senders to the senders param
        for(let t = 0; t < momentSentBy.length; t++) {
          params.senders.push(momentSentBy[t].user_id_external);
        }
      }

      // Check if momentSentTo array has been set
      if(this.momentSentTo) {
        // Convert momentSentTo array to nested array        
        let momentSentTo = !this.momentSentTo[0] ? [this.momentSentTo] : this.momentSentTo;
        // Add the external user ID of all senders to the senders param
        for(let t = 0; t < momentSentTo.length; t++) {
          params.receivers.push(momentSentTo[t].user_id_external);
        }
      }

      // Get the current number of items      
      let currentItemNumber = this.moments.length;
      // Get the Moments
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moments/' + currentItemNumber, params)
      .then(res => {
        let additionalMoments = res.data.data.moments;
        // Check if there are additional Moments available
        if(additionalMoments.length > 0) {         
          // If so, add them to the Moments array
          for (let m = 0; m < additionalMoments.length; m++) {      
            this.moments.push(additionalMoments[m]);    
          }
          // Update the showScrollToTopButton value
          this.showScrollToTopButton = true;
        } else {
          // Update the allMomentsLoaded value
          this.allMomentsLoaded = true;
          // Update the showScrollToTopButton value
          this.showScrollToTopButton = true;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMomentDetails(momentIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + momentIdExternal)
      .then(res => {
        let updatedMoment = res.data.data;
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_moment_id_external == updatedMoment.loyalty_moment_id_external);
        // Update the data    
        this.moments[momentIndex].points = updatedMoment.points;          
        this.moments[momentIndex].total_recipients = updatedMoment.total_recipients;
        this.moments[momentIndex].total_filters = updatedMoment.total_filters;
        this.moments[momentIndex].custom_message = updatedMoment.custom_message;
        this.moments[momentIndex].sender = updatedMoment.sender;        
        this.moments[momentIndex].scheduled_for = updatedMoment.scheduled_for;
        this.moments[momentIndex].sent = updatedMoment.sent;
        this.moments[momentIndex].insights = updatedMoment.insights;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    openLoyaltyMomentTemplatesPage() {
      this.$router.push({path: '/loyalty/moments/templates'});
    },
    deleteLoyaltyMoment(momentIdExternal) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + momentIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('sm.Scheduled_spark_deleted'), type: 'is-success', duration: 2000 });
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_moment_id_external == momentIdExternal);
        // Remove the deleted Moment from the moments array
        this.moments.splice(momentIndex, 1);
        // Close the modal
        this.confirmModal = false;        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },    
    asyncFindSender(query) {
      let searchTerm = query;
      this.senders = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.senders = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindReceiver(query) {
      let searchTerm = query;
      this.receivers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.receivers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },        
    scrollToTop() {
      const appContainer = document.querySelector('.c-app');     
      // Scroll to the top of the element
      appContainer.scrollTo({top: 0, behavior: 'smooth'})
    }                  
  },
  mounted: function() {
    this.maxDateTo = new Date();
    this.maxDateFrom = new Date();

    this.getMoments();

    setTimeout(function() {
      const appContainer = document.querySelector('.c-app');
      
      appContainer.addEventListener('scroll', e => {
        if(appContainer.scrollTop + appContainer.clientHeight >= appContainer.scrollHeight) {
          this.getMoreMoments();
        }
      });
    }.bind(this), 100);

    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
        
    this.getCompanyDetails();

    this.$bus.$on('update_loyalty_moments', () => {
      this.getMoments();
    });

    this.$bus.$on('update_loyalty_moment_details', (momentIdExternal) => {
      this.getMomentDetails(momentIdExternal);
    });

    this.$bus.$on('delete_loyalty_moment', (momentData) => {      
      this.confirmModalData = momentData;
      this.confirmModal = true;
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_loyalty_moments');
    this.$bus.$off('update_loyalty_moment_details');
    this.$bus.$off('delete_loyalty_moment');
  }  
}
</script>