<template>
  <div>
    <div class="sidebar_container profile">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">{{profile.team_name}}</span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="4" lg="4" class="pt-0 text-center">              
            <i class="fas fa-building fa-2x"/><br/>
            {{profile.department_name !== null ? profile.department_name : $t('profile.no_department')}}              
          </CCol>
          <CCol cols="4" lg="4" class="pt-0 text-center">
            <i class="fas fa-user fa-2x"/><br/>
            <span>{{profile.team_manager !== null ? profile.team_manager : $t('profile.no_team_manager')}}</span>
          </CCol>            
          <CCol cols="4" lg="4" class="pt-0 text-center">
            <i class="fas fa-users fa-2x"/><br/>
            <span>{{ profile.employees.length}} {{ profile.employees.length != 1 ? $t('profile.employees') : $t('profile.employee') }}</span>
          </CCol>                     
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="text-center">
            <h3><b>{{ $t('profile.team_employees') }} {{profile.team_name}}</b></h3>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <CRow v-if="profile.employees.length > 0">
              <CCol v-for="employee in profile.employees" v-bind:key="employee.user_id_external" cols="12" sm="12" md="6" lg="4" class="pt-0 pb-0 employee_column">
                <CCard class="employee mb-0">
                  <CCardBody>
                    <CRow>
                      <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                        <center>
                          <div v-if="employee.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken + ')' }"></div>
                          <div v-else class="profile_icon mb-2">
                            <userProfileAvatar :username="employee.name" :size="50"/>
                          </div>
                        </center>
                      </CCol>
                      <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                        <a @click="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="d-block"><b>{{employee.name}}</b></a>
                        <span>{{employee.function !== null && employee.function !== '' ? employee.function : '-'}}</span>
                      </CCol>                                               
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>  
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 pb-0 text-center">
                <span>{{profile.team_name}} {{ $t('profile.no_employees') }}</span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>          

        <CRow v-if="checkPermission('learn.catalog.overview') && modules.academy == 1" class="m-0">
          <CCol cols="12" lg="12" class="text-center">
            <h3><b>{{$t('profile.courses_followed_by_employees')}}</b></h3>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <CRow v-if="courses.length > 0" class="lms courses">
              <CCol cols="6" lg="6" class="course_column pt-0 pb-0" v-for="course in courses" v-bind:key="course.lms_course_id_external">
                <CCard class="mb-0">
                  <CCardBody class="pt-0 pb-0">
                    <CRow>
                      <CCol class="meta">
                        <div class="pr-2">
                          <div v-if="course.image_id && course.from_marketplace === 'N'" 
                               class="content_picture" 
                               v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + course.lms_course_id_external + '/' + course.image_id + '.jpg' + '/' + clientToken + ')' }">
                          </div>
                          <div v-else-if="course.image_id && course.from_marketplace === 'Y'" 
                               class="content_picture" 
                               v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + course.lms_course_id_external + '/' + course.image_id + '.jpg' + '/' + clientToken + ')' }">
                          </div>                          
                          <div v-else class="course_icon mb-2 text-center">
                            <f7-icon material="school"></f7-icon>
                          </div>                                        
                        </div>
                        <div class="details">
                          <p v-line-clamp="1" class="title m-0">{{course.course_name}}</p>
                          <p class="category mt-1 mb-0">{{course.course_category_name}}</p>                  
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" lg="12" class="pt-0">
                        <b-collapse class="panel dashboard show m-0" :open="false">
                          <div slot="trigger" class="panel-heading d-block p-0" role="button">
                            <span>{{ $t('profile.followed_by')}} {{course.followed_by_employees.length}} {{course.followed_by_employees.length != 1 ? $t('profile.employees') : $t('profile.employee') }}</span>
                          </div>
                          <div class="panel-block p-0">
                            <div class="w-100 pt-2 course_employees">
                              <div v-for="employee in course.followed_by_employees" v-bind:key="employee.user_id_external" class="w-100 d-flex align-items-center employee">
                                <div class="pr-2">
                                  <img v-if="employee.profile_image !== null" v-bind:src="apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken" class="profile_image" />
                                  <div v-else class="profile_icon">
                                    <userProfileAvatar :username="employee.name" :size="25"/>
                                  </div>
                                </div>
                                <div>
                                  <strong class="d-block">{{employee.name}}</strong>
                                  <span>{{employee.function}}</span>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </b-collapse>
                      </CCol>                       
                    </CRow>                                            
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 pb-0 text-center">
                <span>{{$t('profile.no_followed_courses_employees')}}</span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow v-if="checkPermission('connect.sw.overview') && modules.social_wall == 1" class="m-0">
          <CCol cols="12" lg="12" class="text-center">
            <h3><b>{{ $t('sw.latest_posts_from_employees') }}</b></h3>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <CRow v-if="posts.length > 0">
              <CCol v-for="(post, index) in posts" v-bind:key="post.post_id" cols="12" sm="12" md="6" lg="6" class="pt-0 pb-0 post_column">
                <swPostCard :ref="'swPostCard' + index"
                            :postData="post"
                            :modules="modules"
                            :platformPermissions="platformPermissions"
                            :navigatable="true"
                            :editable="false"
                            :previewable="false"
                            :preview="false"
                            :showReplies="false"
                            :showInsights="true"
                            :scrollableContent="false"
                            :openedInExp="true"/>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 pb-0 text-center">
                <span>{{profile.name}} {{ $t('profile.no_sw_posts2') }}</span>
              </CCol>
            </CRow>              
          </CCol>                         
        </CRow>

        <CRow v-if="checkPermission('connect.sw.overview') && modules.social_wall == 1" class="m-0">
          <CCol cols="12" lg="12" class="text-center">
            <h3><b>{{$t('sw.latest_replies_from_employees')}}</b></h3>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <CRow v-if="replies.length > 0">
              <CCol v-for="reply in replies" v-bind:key="reply.post_reply_id" cols="12" sm="12" md="6" lg="6" class="pt-0 pb-0 reply_column">
                <CCard class="socialwall mb-0">
                  <CCardBody>
                    <CRow>
                      <CCol cols="12" sm="12" md="12" lg="12" class="posted_by pt-0">
                        <span style="font-size: 0.8em;">
                          {{reply.posted_by_name}} {{$t('sw.employee_replied_on')}} <a @click="checkPermission('connect.sw.postinsights') ? showPostInsights(reply.post_id_external) : null"><b v-html="reply.post_title"></b></a> {{$t('sw.replied_at')}} {{reply.created | moment("dddd DD-MM HH:mm")}}
                        </span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" sm="12" md="12" lg="12" class="content_text pt-0 pb-0">
                        <p class="m-0" style="font-size: 0.9em;">{{reply.content}}</p>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>  
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" class="pt-0 text-center">
                <span>{{profile.name}} {{ $t('profile.no_replies2') }}</span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import truncate from 'vue-truncate-collapsed';

import swPostCard from '@/components/connect/swPostCard.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'TeamProfile',
  props: ['history', 'teamId'],
  components: {
    QuickEdit,
    Multiselect,
    truncate,
    swPostCard,
    userProfileAvatar
  },
  data() {
    return {
      modules: {
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0
      },
      platformPermissions: [],
      apiBaseUrl: null,
      clientToken: null,
      profile: { employees: [] },
      points: { team_points: 0 },
      courses: [],
      posts: [],
      replies: [],
      maxTeamPoints: 50000,
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxGridImages: 3,
      maxContentLength: 200,
      lightboxImages: [],
      lightboxActiveIndex: null           
    }
  },
  methods: {
    openImageLightbox(images, index) {
      // Set the images
      this.lightboxImages = images;
      // Set the active index
      this.lightboxActiveIndex = index;
    },    
    getTeamProfile() {          
      axios.get('v1/core/profile/team/' + this.teamId)
      .then(res => {
        this.profile = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getTeamPoints() {          
      axios.get('v1/loyalty/points/team/' + this.teamId)
      .then(res => {
        this.points = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getTeamCourses() {          
      axios.get('v1/learn/courses/team/' + this.teamId)
      .then(res => {
        this.courses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    assignCourses (data) {
      let userIdExternal = data.user_id_external;
      let params = {}
      params.assigned_courses = data.assigned_courses;

      axios.post('v1/core/profile/course/assign/user/' + userIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('lms.Course_assigned'), type: 'is-success', duration: 2000 });
        this.resetAppointData();
        this.getTeamProfile();
        this.getTeamCourses();
        this.assignCourseModal = false;
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },    
    getTeamPosts() {          
      axios.get(process.env.VUE_APP_API_URL + 'v1/connect/socialwall/posts/team/' + this.teamId)
      .then(res => {
        this.posts = res.data.data;       
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTeamReplies() {          
      axios.get('v1/connect/socialwall/replies/team/' + this.teamId)
      .then(res => {
        this.replies = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showPostInsights(id) {
      this.$router.push({path: `/insights/connect/post/${id.toString()}`});
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    resetAppointData () {
      this.assignCourseModalData = {
        assigned_courses: []
      };
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;

        // Check Connect Module
        if(this.modules.social_wall == 1) {
          this.getTeamPosts();
          this.getTeamReplies();
        }

        // Check Academy Module
        if(this.modules.academy == 1) {
          this.getTeamCourses();
        }

        // Check Employee Loyalty Programme Module
        if(this.modules.employee_loyalty_programme == 1) {
          this.getTeamPoints();
        }      
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }                       
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.checkModules();
    this.getPlatformPermissions();
    this.getTeamProfile();
  }
}
</script>
