<template>
  <div v-if="platformPermissionsLoaded && checkPermission('connect.sw.postinsights')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Social_wall_post_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top mr-2">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="exportData();">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('insights.Save_as_json') }}</span>              
              </CButton>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="3" xl="3" lg="3" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
                {{$t('insights.engagement_score')}}
              </CCol>
              <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
                <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('insights.engagement_score_help')}"/>
              </CCol>        
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Grade v-else :grade="postInsightsData.engagement_score" :centered="true"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
                {{$t('insights.reach_in_target_group')}}
              </CCol>
              <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
                <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('insights.total_reach_help')}"/>
              </CCol>        
            </CRow>       
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Gauge v-else :gaugeChartSeries="gaugeChartSeries" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="6" sm="12">
        <CCard v-if="postInsightsData.post_type === 'vote'" class="mb-0 h-100">
          <CCardHeader class="pb-0">      
            <CRow>
              <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
                {{$t('insights.social_vote_result')}}
              </CCol>
              <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
                <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('insights.social_vote_help')}"/>
              </CCol>        
            </CRow>      
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <SocialVote v-else :socialVoteResult="postInsightsData.social_vote_result"/>
          </CCardBody>
        </CCard>
        
        <CCard v-else class="mb-0 h-100">
          <CCardHeader class="pb-0">      
            <CRow>
              <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
                {{$t('insights.likes_per_type')}}
              </CCol>
              <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
                <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('insights.likes_given_help2')}"/>
              </CCol>        
            </CRow>      
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LikesGiven v-else :likesCountPerType="postInsightsData.likes.likes_count_per_type" mode="grid"/>
          </CCardBody>
        </CCard>        
      </CCol>    

      <CCol cols="3" xl="3" lg="3" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="9" xl="9" lg="9" md="9" sm="9" class="text-left pt-0 pb-0">
                {{$t('insights.reactions')}}
              </CCol>
              <CCol cols="3" xl="3" lg="3" md="3" sm="3" class="text-right pt-0 pb-0">
                <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'left', content: $t('insights.reaction_count_help2')}"/>
              </CCol>        
            </CRow>       
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="single_number" :count="postInsightsData.reactions.sw_post_reactions_count" :countTitle="null" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="4" md="12" sm="12">
        <CCard v-if="loadingPostData" class="mb-0 h-100">
          <CCardHeader class="pb-0">
            {{$t('insights.Social_wall_post')}} 
          </CCardHeader>
          <CCardBody class="text-center">
            <div class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
          </CCardBody>
        </CCard>

        <swPostCard v-else 
                    ref="swPostCard"
                    :postData="post"
                    :modules="modules"
                    :platformPermissions="platformPermissions"
                    :navigatable="false"
                    :editable="false"
                    :previewable="false"
                    :preview="true"
                    :showReplies="false"
                    :showInsights="false"
                    :scrollableContent="true"
                    :openedInExp="true"/>
      </CCol>
      
      <CCol cols="9" xl="9" lg="8" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.Post_first_hit_trend')}}
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData || loadingChartData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else>
              <CRow>
                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">        
                  <BarChart :barChartSeries="chartSeries" xAxisType="datetime" :companyPrimaryColor="companyPrimaryColor"/>
                </CCol>
              </CRow>
              <CRow class="text-center status">
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" class="pt-0 pb-0">
                  <Count mode="with_icons" :count="postInsightsData.audience.total" :countTitle="$t('insights.Count')" :countIcon="'fas fa-crosshairs'"/>
                </CCol>
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" class="pt-0 pb-0">
                  <Count mode="with_icons" :count="postInsightsData.reach.count.unique_views" :countTitle="$t('insights.Count_seen')" :countIcon="'far fa-user'"/>
                </CCol>
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" class="pt-0 pb-0">
                  <Count mode="with_icons" :count="postInsightsData.reach.count.views" :countTitle="$t('insights.Views_in_total')" :countIcon="'far fa-eye'"/>
                </CCol>            
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            {{$t('insights.Reachability_organisation')}}
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <ReachabilityOrganisation v-else :departments="postInsightsData.departments"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="6" xl="6" lg="6" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            {{$t('insights.reactions')}}
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Reactions v-else :reactionCount="postInsightsData.reactions.sw_post_reactions_count" :reactionDetails="postInsightsData.reactions.sw_post_reactions_details"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="6" xl="6" lg="6" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            {{$t('insights.Likes')}}
          </CCardHeader>
          <CCardBody>
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Likes v-else :likes="postInsightsData.likes.sw_post_like_details"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>   
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import swPostCard from '@/components/connect/swPostCard.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import Grade from '@/components/insights/common/Grade.vue';
import Gauge from '@/components/insights/common/Gauge.vue';
import Count from '@/components/insights/common/Count.vue';
import BarChart from '@/components/insights/common/BarChart.vue';
import LikesGiven from '@/components/insights/common/LikesGiven.vue';

import Likes from '@/components/insights/connect/Likes.vue';
import Reactions from '@/components/insights/connect/Reactions.vue';
import ReachabilityOrganisation from '@/components/insights/connect/ReachabilityOrganisation.vue';
import SocialVote from '@/components/insights/connect/SocialVote.vue';

export default {
  name: 'Post',
  components: {
    loadingSpinner,
    noPermission,
    swPostCard,
    Grade,
    Gauge,
    Count,
    BarChart,
    Likes,
    LikesGiven,
    Reactions,
    ReachabilityOrganisation,
    SocialVote
  },
  watch: { 
    '$route.params.id': {
      handler() {
        let postIdExternal = this.$route.params.id;
        // Get the post data
        this.getPostData(postIdExternal);
        // Get the post insights data
        this.getPostInsightsData(postIdExternal);
        // Get the post insights trend data
        this.getPostInsightsTrendData(postIdExternal);         
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      modules: {
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      loadingPostData: false,
      loadingChartData: false,
      loadingData: false,
      showDownloadButtons: false,
      printingInsights: false,
      gaugeChartSeries: [],
      chartSeries: [],
      post: {},
      companyPrimaryColor: null,
      postInsightsData: {
        likes: { 
          likes_count_per_type: {}
        },
        reactions: {
          sw_post_reactions_count: 0
        },
        audience: {
          total: 0,
          group: null
        },
        reach: { 
          count: {
            unique_views: 0,
            views: 0
          }
        }
      },
      postInsightsTrendData: {},
    }
  },
  methods: {
    exportData(outputData) {      
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
      let exportFileName = 'post-insights';
      
      if(!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL
        let outputData = JSON.stringify(this.postInsightsData);
        url = window.URL.createObjectURL(new Blob([outputData]));
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute
        link.setAttribute('download', exportFileName + '.json');
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL        
        outputData = JSON.stringify(this.postInsightsData);
        url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
      }
    },    
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = {
        type: "dataURL",
        useCORS: true
      };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
      link.setAttribute("download", "Insights_" + this.$route.params.id + "_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },    
    getPostData(postIdExternal){
      // Start the loader
      this.loadingPostData = true;
      // Get the postdata
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + postIdExternal)
      .then(res => {
        if(res.data.data !== 'No post found') {
          this.post = res.data.data;
          // Close the loader
          this.loadingPostData = false;         
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPostInsightsData(postIdExternal){
      // Start the loader
      this.loadingData = true;
      // Clear the gaugeChartSeries array
      this.gaugeChartSeries = [];

      // Get the post insights data
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/post/' + postIdExternal)
      .then(res => {
        if(res.data.data !== 'No post found') {
          // Set the postInsightsData
          this.postInsightsData = res.data.data;
          // Add the reach percentage to the gaugeChartSeries array
          this.gaugeChartSeries.push(res.data.data.reach.percentage);
          // Set the companyPrimaryColor value
          this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
          // Set timeout for 2 seconds
          setTimeout(function(){
            // Close the loader
            this.loadingData = false;              
            // Update the showDownloadButtons value
            this.showDownloadButtons = true;
          }.bind(this), 2000);
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPostInsightsTrendData(postIdExternal){
      // Start the loader
      this.loadingChartData = true;
      // Get the chart data      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/connect/post/' + postIdExternal + '/trend')
      .then(res => {
        if(res.data.data !== 'No post found') {
          // Update the chartSeries
          this.chartSeries = [
            { name: "Unique Views", data: res.data.data.unique_views },
            // { name: "Views",  type: 'line', data: res.data.data.views }
          ];
          // Set timeout for 2 seconds
          setTimeout(function(){
            // Start the loader
            this.loadingChartData = false;            
          }.bind(this), 2000)            
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
  }
}
</script>