<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{!flow.sw_group_id_external ? $t('sw.New_group2') : $t('sw.Edit_group2')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">           
          <CCol cols="10" lg="10" class="pt-0">
            <div class="mb-2">
              <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="flow.sw_group_name">                        
                <h2 class="m-0">{{flow.sw_group_name ? flow.sw_group_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
              </quick-edit>
            </div>
            <div class="mb-1">
              <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="flow.description">
                <span>{{flow.description ? flow.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
              </quick-edit>            
            </div>
          </CCol>
          <CCol cols="2" lg="2" class="pt-0 text-lg-right">
            <b-switch class="m-0 align-middle" v-model="flow.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>                   
        </CRow>
        
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0 pb-0">
            <!-- <label>{{$t('sw.Upload_a_cover_image')}}</label> -->
            <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': coverImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setCoverImage">
              <label for="fileInput" slot="upload-label" class="m-0">
                <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{coverImageUploaded ? $t('common.Replace_image') : $t('sw.Upload_a_cover_image')}}</span>
              </label>
            </image-uploader>
          </CCol>
          <CCol v-if="flow.sw_group_id_external && flow.image_id !== null" cols="6" lg="6" class="pt-0 pb-0">
            <!-- <label>{{$t('common.Current_image')}}</label> -->
            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-flow/' + flow.sw_group_id_external + '/' + flow.image_id + '.jpg' + '/' + clientToken" class="current_image"/>
          </CCol>
        </CRow>

        <hr class="mt-3 mb-3"/>
        
        <CRow v-if="flow.sw_group_id_external" class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <span class="d-flex pointer" @click="openSidebarRight('headcount_details', { headcount_type: 'flow', headcount_id_external: flow.sw_group_id_external });">
              <i class="icon top far fa-user mr-1"/>{{flow.headcount.headcount_total}} {{ $t('common.Employees') }} in {{ $t('sw.Flow_headcount2') }}
            </span>
          </CCol>
        </CRow>

        <CRow class="m-0">      
          <CCol cols="12" lg="12" class="pt-0">
            <!-- <label>{{$t('common.Specified_for_groups')}}</label> -->
            <multiselect
              class="data_table"
              v-model="flow.groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title"
              @input="getFlowMembers();">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect>          
          </CCol>            
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('sw.Contributors2')}}</label>
            <multiselect                        
              class="data_table"
              v-model="flow.contributors" 
              :options="users" 
              :multiple="true"                
              :placeholder="$t('sw.Select_contributors')" 
              :selectLabel="$t('common.Add_user')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_user')"
              track-by="user_id_external" 
              label="label"                        
              @search-change="asyncFindUser">
              <span slot="noResult">{{ $t('common.no_users_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>            
          </CCol>            
        </CRow>

        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('sw.Default_subtopic2')}}</label>
            <multiselect
              class="data_table"
              v-model="flow.default_subtopic" 
              :options="subtopics"
              :multiple="false"
              :placeholder="$t('common.Select_subtopic')" 
              :selectLabel="$t('common.Add_subtopic')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_subtopic')"
              track-by="subtopic_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
            </multiselect>            
          </CCol>            
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="flow.is_followable" size="is-small">{{ $t('sw.Group_is_followable2') }}</b-switch>
          </CCol>
        </CRow>

        <hr class="m-0">
        <div class="group_members">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="text-left">
              <span class="sidebar_subject">
                {{ $t("common.Employees") }} <span v-if="flowMemberTotal > 0">({{flowMemberTotal}})</span>
              </span>
            </CCol>
            <CCol cols="6" lg="6" class="text-right">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="listViewEnabled" @input="getFlowMembers()" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </CCol>
          </CRow>          
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <loadingSpinner mode="auto" v-if="listMembersLoading" :content="$t('common.Loading')"/>
                    <b-table v-else
                             ref="groupTable"
                             class="data_table"
                             :data="flowMembers"
                             :striped="true"
                             :hoverable="true"
                             :mobile-cards="true"
                             :paginated="listMembersPaginated"
                             :per-page="listMembersPerPage"
                             :current-page.sync="listMembersCurrentPage"
                             :pagination-simple="isPaginationSimple"
                             :pagination-position="paginationPosition"
                             :total="listMembersTotal"
                             backend-pagination
                             @page-change="onListPageChange">
                      
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>                            
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="function" :label="$t('common.Function')">
                          <span>{{props.row.function !== null && props.row.function !== '' ? props.row.function : '-'}}</span>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <div v-if="flow.groups.length > 0">
                            {{ $t('groups.No_users_based_on_filters') }}
                          </div>
                          <div v-else>
                            {{ $t('groups.Add_filters_to_show_members') }}
                          </div>
                        </div>                
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <loadingSpinner mode="auto" v-if="gridMembersLoading" :content="$t('common.Loading')"/>
                <div v-if="!gridMembersLoading && flowMembers.length > 0">
                  <CRow class="group_member_container m-0">
                    <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="member in flowMembers" v-bind:key="member.user_id_external" class="member_column pt-0">
                      <CCard class="member mb-0">
                        <CCardBody>
                          <CRow>
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                              <center>
                                <div v-if="member.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + member.user_id_external + '/' + member.profile_image + '/' + clientToken + ')' }"></div>
                                <div v-else class="profile_icon mb-2">
                                  <userProfileAvatar :username="member.name" :size="50"/>
                                </div>
                              </center>
                            </CCol>                      
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                              <a @click="openSidebarRight('user_profile', { user_id_external:  member.user_id_external });" class="d-block"><b>{{member.name}}</b></a>
                              <p v-if="member.team_name" class="m-0">{{member.team_name}} ({{member.department_name}})</p>
                              <p class="m-0">{{member.function !== null && member.function !== '' ? member.function : '-'}}</p>
                            </CCol>                                                
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <CRow v-if="gridMembersPaginated" class="m-0">
                    <CCol cols="12" md="12">
                      <v-pagination class="justify-content-end"
                                    v-model="gridMembersCurrentPage"
                                    @input="onGridPageChange"
                                    :length="gridMemberPages"
                                    :total-visible="9"
                                    prev-icon="mdi-chevron-left"
                                    next-icon="mdi-chevron-right">
                      </v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-if="!gridMembersLoading && gridMembersLoaded && flowMembers.length === 0">
                  <CRow class="m-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <div v-if="flow.groups.length > 0">
                        {{ $t('connect.No_employees_based_on_target_groups') }}
                      </div>
                      <div v-else>
                        {{ $t('connect.Add_target_groups_to_show_members2') }}
                      </div>
                    </CCol>
                  </CRow>                  
                </div>
              </div>
            </CCol>                      
          </CRow>             
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!flow.sw_group_id_external">
              <CButton color="primary" @click="insertFlow();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateFlow(flow);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import ImageUploader from 'vue-image-upload-resize';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'flowDetails',
  props: ['history', 'swGroupIdExternal'],
  components: {
    QuickEdit,
    Multiselect,
    ImageUploader,
    loadingSpinner,
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        this.getFlowDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      flow: {
        headcount: { headcount_total: 0 },
        groups: []
      },
      users: [],
      subtopics: [],
      targetGroups: [],
      coverImageUploaded: false,
      flowMembers: [],
      flowMemberTotal: 0,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      listViewEnabled: false,
      listMembersPaginated: false,
      listMembersCurrentPage: 1,
      listMembersTotal: 0,
      listMembersPerPage: 9,
      listMembersLoaded: false,
      listMembersLoading: false,
      listMembersStartIndex: 0,
      gridMembersPaginated: false,
      gridMembersCurrentPage: 1,
      gridMembersPerPage: 9,      
      gridMemberPages: 0,
      gridMembersLoaded: false,
      gridMembersLoading: false,
      gridMembersStartIndex: 0
    }
  },
  methods: {
    getFlowDetails() {
      if(this.swGroupIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + this.swGroupIdExternal)
        .then(res => {
          this.flow = res.data.data;
          // Get the flow members
          this.getFlowMembers();          
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the group data
        this.resetGroupData();
      }
    },
    insertFlow() {
      let params = {};
      params = this.flow;
      if(params.description === '') params.description = null;

      let sw_group_name = params.sw_group_name;
      let target_groups = params.groups;

      if(sw_group_name && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/flow', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('sw.Social_wall_group_added2'), type: 'is-success', duration: 2000 });
          // Emit the update_flows event
          this.$bus.$emit('update_flows');          
          // Reset the group data
          this.resetGroupData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateFlow(data) {
      let params = {}
      params = data;
      if(params.description === '') params.description = null;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      (params.is_followable === true) ? params.followable = 'Y' : params.followable = 'N';      

      let sw_group_name = params.sw_group_name;     
      let target_groups = params.groups;

      if(sw_group_name && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + this.swGroupIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('sw.Social_wall_group_updated2'), type: 'is-success', duration: 2000 });
          // Emit the update_flow_details event
          this.$bus.$emit('update_flow_details', this.swGroupIdExternal);  
          // Reset the group data
          this.resetGroupData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    asyncFindUser (query) {
      let searchTerm = query;
      this.users = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    resetGroupData () {
      this.flow = {
        group_name: '',
        description: '',
        is_followable: false,
        groups: [],
        users: []
      }
    },
    setCoverImage(output) {
      this.coverImageUploaded = true;
      this.flow.image = output.dataUrl;
    },
    getFlowMembers() {
      // Check if the list view is enabled
      if(this.listViewEnabled) {
        // Reset the listMembersCurrentPage value
        this.listMembersCurrentPage = 1;
        // Reset the listMembersStartIndex value
        this.listMembersStartIndex = 0;
        // Get the members for the list
        this.getListFlowMembers();
      } else {
        // Reset the gridMembersCurrentPage value
        this.gridMembersCurrentPage = 1;
        // Reset the gridMembersStartIndex value
        this.gridMembersStartIndex = 0;
        // Get the members for the grid
        this.getGridFlowMembers();
      }
    },
    onGridPageChange(page) {
      // Set the gridMembersStartIndex value
      (page > 1) ? this.gridMembersStartIndex = (page - 1) * this.gridMembersPerPage : this.gridMembersStartIndex = 0;
      // Get the members for the list view   
      this.getGridFlowMembers();
    },
    getGridFlowMembers() {
      // Start the loader
      if(!this.gridMembersLoaded) this.gridMembersLoading = true;
      // Set the params  
      let params = {};
      params.groups = this.flow.groups;   
      params.member_limit = this.gridMembersPerPage;

      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + this.swGroupIdExternal + '/members/' + this.gridMembersStartIndex, params)
      .then(res => {
        this.flowMembers = [];
        // Update the flowMemberTotal value
        this.flowMemberTotal = res.data.data.total_members;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_members;
        // Update the currentTotal value
        if(currentTotal / this.gridMembersPerPage > 1000) currentTotal = this.gridMembersPerPage * 1000;        
        // Add the members to the flowMembers array
        this.flowMembers = res.data.data.members;
        // Check if the pagination should be activated
        (this.flowMemberTotal > this.gridMembersPerPage) ? this.gridMembersPaginated = true : this.gridMembersPaginated = false;                
        // Reset the gridMemberPages value
        this.gridMemberPages = 0;
        // Define the number of items of the pagination
        for (let i = 0; i < currentTotal; i = i + this.gridMembersPerPage) {
          this.gridMemberPages++;
        }
        // Update the gridMembersLoaded value
        this.gridMembersLoaded = true;
        // Stop the loader
        this.gridMembersLoading = false;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    onListPageChange(page) {
      // Set the listMembersStartIndex value
      (page > 1) ? this.listMembersStartIndex = (page - 1) * this.listMembersPerPage : this.listMembersStartIndex = 0;
      // Get the members for the list view   
      this.getListFlowMembers();
    },
    getListFlowMembers() {
      // Start the loader
      if(!this.listMembersLoaded) this.listMembersLoading = true;
      // Set the params
      let params = {};
      params.groups = this.flow.groups;
      params.member_limit = this.listMembersPerPage;
      // Get the posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + this.swGroupIdExternal + '/members/' + this.listMembersStartIndex, params)
      .then(res => {
        // Clear the flowMembers array
        this.flowMembers = [];
        // Update the flowMemberTotal value
        this.flowMemberTotal = res.data.data.total_members;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_members;
        // Update the currentTotal value
        if(currentTotal / this.listMembersPerPage > 1000) currentTotal = this.listMembersPerPage * 1000;
        // Update the listMembersTotal value
        this.listMembersTotal = currentTotal;
        // Add the members to the flowMembers array
        this.flowMembers = res.data.data.members;
        // Check if the pagination should be activated
        (this.flowMemberTotal > this.listMembersPerPage) ? this.listMembersPaginated = true : this.listMembersPaginated = false;        
        // Update the listMembersLoaded value
        this.listMembersLoaded = true;
        // Stop the loader
        this.listMembersLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getTargetGroups();
    this.getSubtopics();    
  }
}
</script>

<style>
  #fileInput {
    display: none;
  }
</style>