<template>
  <b-modal :can-cancel="['x']" :active.sync="showModal" :width="960" scroll="keep" class="upgrade_license">
    <CCard v-if="modalData" class="mb-0">
      <CCardBody>
        <CRow>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <div class="d-flex align-items-center">
              <img src="img/core/upgrade_license.png"/>
              <div class="flex-grow-1">
                <h2 class="mt-0 mb-2">{{$t('core.Enhance_your_plan_to_unlock_this_feature')}}</h2>
                <span>{{$t('core.This_feature_requires_an_upgrade_to_your_subscription')}}</span>
              </div>
            </div>
          </CCol>
        </CRow>    
      </CCardBody>
      <CCardFooter>        
        <CButton color="primary" @click="openWebsite()"><i class="fa-solid fa-arrow-up-right-from-square mr-2"/>{{$t('core.Visit_our_website')}}</CButton>
        <CButton color="secondary" @click="closeModal()"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
      </CCardFooter>
    </CCard>
  </b-modal>
</template>

<script>
export default {
  name: 'FeatureUnavailableModal',  
  data () {
    return {
      showModal: false,
      modalData: null,
      environmentHelpers: {
        environment_name: null,
        environment_website: null
      }      
    }
  },  
  methods: {
    openWebsite() {
      window.open(`https://${this.environmentHelpers.environment_website}`, '_blank');
    },
    closeModal() {
      this.showModal = false;
    }
  },
  mounted () {
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));
    this.showModal = false;    
    
    this.$bus.$on('open_feature_unavailable_modal', (modal) => {
      if(this.showModal === false) this.showModal = true;
      this.modalData = modal.data;
    });

    this.$bus.$on('close_feature_unavailable_modal', () => {
      this.closeModal();
    });   
  }  
}
</script>