<template>
  <CCard class="app_preview mb-0" v-bind:style="{ backgroundImage: 'url(' + cdnBaseUrl + '/base/app/experience-background.png)' }">
    <CCardBody class="pt-0 pb-0">
      <CRow class="app_header_row align-items-center">
        <div v-if="previewMode === 'connect_social_wall'" class="app_header d-flex w-100 p-3">
          <div class="company">
            <img :src="getLogoSrc()"/>
          </div>         
          <userProfileAvatar v-if="environmentName" :username="environmentName" :size="25"/>
        </div>
        <div v-else-if="previewMode === 'loyalty_moment'" class="popover_header w-100 p-3" v-bind:style="{ backgroundColor: previewData.primary_color }">
          <span>{{$t('sm.New_spark')}}</span>
        </div>
      </CRow>
      
      <CRow>
        <div v-if="previewMode === 'connect_social_wall'" class="post_container">
          <CCard v-for="(post, index) in posts" v-bind:key="index" class="socialwall mt-0">
            <CCardBody class="pt-0 pb-0">                
              <CRow>
                <CCol class="meta">                                     
                  <div class="mr-2">
                    <div class="profile_image " v-bind:style="{ backgroundImage: 'url(' + getLogoSrc() + ')' }"></div>
                  </div>
                  <div class="posted_by pt-0 pb-0">                    
                    <span class="d-block"><b v-line-clamp="1">{{previewData.brand_name ? previewData.brand_name : previewData.company_name ? previewData.company_name : environmentName}}</b></span>
                    <span class="posted_in_at">
                      <span class="material-icons">inbox</span><span v-line-clamp="1">{{previewData.brand_name ? previewData.brand_name : previewData.company_name ? previewData.company_name : environmentName}} - All Employees</span>
                      <span class="material-icons ml-1">alarm_add</span><span v-line-clamp="1">{{new Date | moment("dddd DD-MM HH:mm")}}</span>
                    </span>          
                  </div>
                  <div class="post_actions pl-2">
                    <!-- <span><i class="fas fa-pen"/></span> -->
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <div class="content_picture_column w-100">
                  <CCol class="p-0">
                    <img :src="previewData.default_social_wall_image_url" class="content_picture"/>                
                  </CCol>
                </div>
                <div class="content_text_column w-100">
                  <CCol class="content_text pb-0">
                    <div>
                      <span>{{ $t('registration.example_post2') }} {{previewData.brand_name ? previewData.brand_name : environmentName}}</span>
                    </div>
                    <div class="mt-2">
                      <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" v-bind:style="{ backgroundColor: previewData.secondary_color, borderColor: previewData.secondary_color }">
                        <i class="fa-solid fa-link button_icon"></i>            
                        <span v-line-clamp="1" class="button_text">{{ $t('registration.external_url') }}</span>
                      </CButton>              
                    </div>
                  </CCol>
                </div>
              </CRow>

              <CRow class="pt-0 activity_bar">
                <CCol cols="8" sm="8" md="8" lg="8" class="post_likes">
                  <div class="mr-2 type likes">            
                    <span class="mr-1" v-html="getTwemoji('👍')"></span>
                    <span>{{post.likes}}</span>                         
                  </div>
                  <div class="mr-2 type dislikes">
                    <span class="mr-1" v-html="getTwemoji('👎')"></span>
                    <span>{{post.dislikes}}</span>        
                  </div>
                  <div class="mr-2 type waves">
                    <span class="mr-1" v-html="getTwemoji('👏')"></span>
                    <span>{{post.waves}}</span>           
                  </div>
                  <div class="type hearts">
                    <span class="mr-1" v-html="getTwemoji('💖')"></span>
                    <span>{{post.hearts}}</span>
                  </div>
                </CCol>
                <CCol cols="4" sm="4" md="4" lg="4" class="post_reactions text-right">
                  <span class="material-icons d-inline-block align-middle">chat_bubble_outline</span>   
                  <span class="ml-1">{{post.replies}}</span>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>  
        </div>
        <div v-if="previewMode === 'loyalty_moment'" class="moment_email_preview_container">
          <div class="moment_email_preview" v-html="previewContentData"></div>
        </div>        
      </CRow>

      <CRow class="app_footer_row" v-bind:style="{ backgroundColor: previewData.primary_color }">
        <div v-if="previewMode === 'connect_social_wall'" class="app_footer w-100">
          <div class="w-100 d-flex">
            <div class="toolbar_icon_box text-center">
              <i class="fa-solid fa-inbox"/> 
            </div>
            <div class="toolbar_icon_box text-center active">
              <i class="fa-solid fa-house"></i>
            </div>
            <div class="toolbar_icon_box text-center">
              <i class="fa-solid fa-grip"></i>
            </div>
          </div>
        </div>
        <div v-else-if="previewMode === 'loyalty_moment'" class="popover_footer w-100 p-3 text-center">
          <span><i class="fa-solid fa-gift mr-1"/>{{$t('loyalty.Show_reward')}}</span>
        </div>
      </CRow>      
    </CCardBody>
  </CCard>
</template>

<script>
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'appPreview',
  props: ['environmentName', 'environmentTag', 'previewData', 'previewMode', 'previewContentData'],
  components: {
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        if(this.previewData) {
          this.companyImageUrl = this.previewData.company_image;
          this.headerImageUrl = this.previewData.header_image;
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      cdnBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,      
      companyImageUrl: null,
      headerImageUrl: null,
      posts: [
        { likes: 3, dislikes: 0, waves: 1, hearts: 2, replies: 3 },
        { likes: 2, dislikes: 0, waves: 3, hearts: 2, replies: 2 }
      ]
    } 
  },
  methods: {
    getLogoSrc() {
      if(!this.companyImageUrl) {        
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/logo/${this.environmentTag}_logo.png/${this.clientToken}`;
      } else {
        return this.companyImageUrl;
      }
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    reloadCompanyImage(url) {
      this.companyImageUrl = url;
    },
    reloadHeaderImages(url) {
      this.headerImageUrl = url;
    }    
  },
  mounted: function() {
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>