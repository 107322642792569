<template>
  <div>
    <div v-if="loadingData">
      <loadingSpinner mode="inline" :content="null"/>
    </div>
    <div v-else class="insights">
      <div v-if="modules.social_wall == 1 && checkPermission('connect.topics.topicinsights')">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Connect2')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/connect/topics" :cardTitle="$t('insights.Topics')" :cardDescription="null" :cardIcon="'fas fa-list-ul'"/>
          </CCol>
        </CRow>
      </div>

      <div v-if="modules.satisquestions == 1 && checkPermission('measure.sq.sqinsights')">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Measure')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/measure/categories" :cardTitle="$t('insights.Pillars')" :cardDescription="null" :cardIcon="'fas fa-list-ul'"/>
          </CCol>
        </CRow>       
      </div>

      <div v-if="modules.social_kb == 1 && checkPermission('knowledge.insights.knowledge')">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Knowledge')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/knowledge" :cardTitle="$t('insights.Knowledge_insights')" :cardDescription="null" :cardIcon="'fas fa-list-ul'"/>
          </CCol>
        </CRow>       
      </div>

      <div v-if="modules.academy == 1 && (checkPermission('learn.insights.ldinsights') || checkPermission('learn.insights.courses') || checkPermission('learn.insights.onboarding'))">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Learn')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol v-if="checkPermission('learn.insights.ldinsights')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/learn" :cardTitle="$t('insights.Learn_insights')" :cardDescription="null" :cardIcon="'fas fa-list-ul'"/>
          </CCol>          
          <CCol v-if="checkPermission('learn.insights.courses')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/learn/courses" :cardTitle="$t('insights.Courses')" :cardDescription="null" :cardIcon="'fas fa-book'"/>
          </CCol>
          <CCol v-if="checkPermission('learn.insights.onboarding')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/learn/onboarding" :cardTitle="$t('insights.Onboarding')" :cardDescription="null" :cardIcon="'fas fa-book'"/>
          </CCol>          
        </CRow>        
      </div>            

      <div v-if="modules.employee_loyalty_programme == 1 && (checkPermission('loyalty.insights.store') || checkPermission('loyalty.insights.userpoints') || checkPermission('loyalty.insights.teampoints') || checkPermission('loyalty.insights.employees'))">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Loyalty')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol v-if="checkPermission('loyalty.insights.userpoints')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/loyalty/points/employees" :cardTitle="$t('insights.Current_employee_coins')" :cardDescription="null" :cardIcon="'fas fa-users'"/>
          </CCol>          
          <CCol v-if="checkPermission('loyalty.insights.userpoints')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/loyalty/points/employees/updates" :cardTitle="$t('insights.Coins_transactions')" :cardDescription="null" :cardIcon="'fas fa-arrow-right-arrow-left'"/>
          </CCol>
          <CCol v-if="checkPermission('loyalty.insights.store') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/loyalty/store" :cardTitle="$t('insights.Order_behaviour')" :cardDescription="null" :cardIcon="'fas fa-store-alt'"/>
          </CCol>
          <CCol v-if="checkPermission('loyalty.insights.employees') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/loyalty/employees" :cardTitle="$t('insights.Coins_over_time')" :cardDescription="null" :cardIcon="'fas fa-user'"/>
          </CCol>                              
        </CRow>      
      </div>

      <div v-if="(checkPermission('insights.crossover.audience') || checkPermission('insights.crossover.participation')) && 
                  modules.social_wall == 1 &&
                  modules.academy == 1 &&
                  modules.employee_loyalty_programme == 1">
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">
            <h2 class="dashboard_page_title">{{$t('Crossover')}}</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol v-if="checkPermission('insights.crossover.audience')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/audience" :cardTitle="$t('insights.Audience')" :cardDescription="null" :cardIcon="'fas fa-users'"/>
          </CCol>
          <CCol v-if="checkPermission('insights.crossover.participation')" cols="3" lg="3" class="pb-0">
            <navigationCard path="insights/participation" :cardTitle="$t('insights.Participation')" :cardDescription="null" :cardIcon="'fas fa-hands-helping'"/>
          </CCol>          
        </CRow>      
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner'
import navigationCard from '@/components/common/navigationCard'

export default {
  name: 'Insights',
  components: {
    loadingSpinner,
    navigationCard
  },
  data () {
    return {
      productLicenses: {
        loyalty_licence_tag: null
      },      
      loadingData: false,
      modules: {
        satisquestions: 0,
        social_wall: 0,
        social_kb: 0,
        academy: 0,
        employee_loyalty_programme: 0
      },
      platformPermissions: []      
    }
  },
  methods: {
    checkModules() {
      // Start the loader
      this.loadingData = true;

      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;
        // Stop the loader
        this.loadingData = false; 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  async mounted() {
    this.checkModules();
    this.getPlatformPermissions();

    this.productLicenses = await this.fetchProduuctLicenseData();
  }
}
</script>
