<template>
  <div>
    <div class="sidebar_container profile">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            
            <CDropdown v-if="checkPermission('core.user.resetpassword') || checkPermission('core.user.deactivate') || checkPermission('core.organization.employees')" color="primary" stoggler-text="" :caret="false" class="mr-2 sidebar_header_dropdown" placement="top-start" >
              <div slot="toggler-content">
                <span class="d-flex align-items-center">{{$t('common.Menu')}}<i class="fas fa-caret-down ml-1"></i></span>
              </div>

              <CDropdownItem v-if="checkRole('super_admin') && checkDateLeft(profile.date_left)">
                <div class="d-flex align-items-center" @click="openSidebarRight('reset_employee_password', { user_id_external:  profile.user_id_external, employee_name: profile.name })">
                  <div class="post_action_icon text-center mr-1">
                    <i class="fas fa-lock"/>
                  </div>
                  <div class="flex-grow-1">
                    <span>{{$t('admin.Reset_employee_password')}}</span>
                  </div>
                </div>
              </CDropdownItem>             
              <CDropdownItem v-if="profile.is_active && checkPermission('core.user.deactivate')">
                <div class="d-flex align-items-center" @click="employeeLeftModal = true; employeeLeftModalData = profile;">
                  <div class="post_action_icon text-center mr-1">
                    <i class="fa-solid fa-door-open"></i>
                  </div>
                  <div class="flex-grow-1">
                    <span>{{$t('users.Employee_left_organization')}}</span>
                  </div>
                </div>
              </CDropdownItem>             
              <CDropdownItem v-if="checkPermission('core.organization.employees')">
                <div class="d-flex align-items-center" @click="openSidebarRight('user', { user_id_external: userIdExternal })">
                  <div class="post_action_icon text-center mr-1">
                    <i class="fas fa-pen"/>
                  </div>
                  <div class="flex-grow-1">
                    <span>{{$t('users.Edit_employee_details')}}</span>
                  </div>
                </div>
              </CDropdownItem>                                                                
            </CDropdown>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <div class="d-flex align-items-center justify-content-lg-end">
              <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
            </div>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow v-if="profileLoaded && modulesLoaded" class="m-0">
          <CCol md="12" lg="4" class="pt-0">
            <center>
              <div v-if="profileImageUrl" 
                   @click="pickImageToUpload()" 
                   v-bind:style="{ backgroundImage: 'url(' + profileImageUrl + ')' }"
                   class="profile_image top pointer">
              </div>
              <div v-else-if="profile.name" @click="pickImageToUpload()" class="profile_icon top pointer">
                <userProfileAvatar :username="profile.name" :size="150"/>
              </div>
            </center>

            <CInputFile id="imageUpload" accept="image/x-png,image/gif,image/jpeg" :value="profile.image" @change="onImageChange" hidden class="m-0"/>            
          </CCol>
          <CCol md="12" lg="8" class="pt-0 text-center profile_summary">
            <CRow>
              <CCol cols="12" lg="12" md="12" sm="12" class="pt-0 pb-1">
                <span class="font-2xl"><b>{{profile.name}}</b></span>
              </CCol>
            </CRow>               
            <CRow>
              <CCol cols="12" lg="12" md="12" sm="12" class="pt-0 pb-1">
                <span class="font-lg">{{ profile.function !== null && profile.function !== '' ? profile.function : $t('profile.empty_function_user') }}</span>
              </CCol>
            </CRow>  
            <CRow>
              <CCol cols="12" class="pt-0">
                <span class="font-lg">{{profile.bio !== null && profile.bio !== '' ? profile.bio : $t('profile.empty_bio_user')}}</span>
              </CCol>
            </CRow>          
            <CRow :gutters="false">
              <CCol v-if="modules.profile_include_phone == 1 && profile.phone" 
                    class="pt-0"
                    v-bind:class="{'pr-1_5' : (modules.profile_include_email == 1 && profile.email) || modules.social_wall == 1}">
                <CButton :href="'callto:' + profile.phone" color="primary" class="m-0 w-100 d-flex align-items-center justify-content-center">
                  <span><i class="fas fa-phone m-0 mr-1"/>{{$t('profile.Call')}}</span>
                </CButton>                  
              </CCol>
              <CCol v-if="modules.profile_include_email == 1 && profile.email" 
                    class="pt-0" 
                    v-bind:class="{'pl-1_5' : modules.profile_include_phone == 1 && profile.phone,
                                   'pr-1_5' : modules.social_wall == 1}">
                <CButton :href="'mailto:' + profile.email" color="primary" class="m-0 w-100 d-flex align-items-center justify-content-center">
                  <span><i class="fas fa-envelope m-0 mr-1"/>{{$t('profile.Email')}}</span>
                </CButton>                  
              </CCol>
              <CCol v-if="modules.social_wall == 1 && modules.connect_chats == 1 && checkPermission('connect.chats')" 
                    class="pt-0" 
                    v-bind:class="{'pl-1_5' : (modules.profile_include_phone == 1 && profile.phone) || (modules.profile_include_email == 1 && profile.email)}">
                <CButton @click="createChat()" color="primary" class="m-0 w-100 d-flex align-items-center justify-content-center">
                  <span><i class="fas fa-message m-0 mr-1"/>{{$t('profile.Chat')}}</span>
                </CButton>                  
              </CCol>              
            </CRow>
          </CCol>          
        </CRow>

        <CRow v-if="profileLoaded && ((profile.department_name && profile.team_name) || profile.dob || profile.years_of_service || profile.months_of_service)" class="m-0">
          <CCol v-if="profile.department_name && profile.team_name" class="text-center">
            <i class="fas fa-users fa-2x mb-1"/><br/>            
            <a v-if="profile.team_id !== null" @click="openSidebarRight('team_profile', { team_id: profile.team_id });" class="d-block">
              <span>{{profile.team_name}} ({{profile.department_name}})</span>
              <span v-if="profile.work_location" class="work_location"><i class="fas fa-map-marker-alt mr-1"/>{{profile.work_location}}</span>
            </a>
            <span v-else>-</span>
          </CCol>
          <CCol v-if="profile.dob" class="text-center">
            <i class="fas fa-birthday-cake fa-2x mb-1"/><br/>
            <span>{{ profile.dob | moment("DD-MM") }}</span>
          </CCol>
          <CCol v-if="profile.years_of_service || profile.months_of_service" class="text-center">
            <i class="fas fa-user-plus fa-2x mb-1"/><br/>
            <span v-if="profile.years_of_service > 0">{{profile.years_of_service}} {{ $t('common.years_of_service') }}</span>
            <span v-else>{{profile.months_of_service}} {{ $t('common.months_of_service') }}</span>
          </CCol>                               
        </CRow>

        <b-tabs v-if="profileLoaded && platformPermissionsLoaded && modulesLoaded" class="profile_tabs no_borders mb-0" type="is-boxed" :animated="false">
          <b-tab-item v-if="modules.employee_loyalty_programme == 1">
            <template slot="header">
              <span>Spark Shop Orders</span>
            </template>
            <template>
              <div v-if="platformPermissionsLoaded && checkPermission('loyalty.insights.userpoints')">
                <CRow class="w-100 m-0">
                  <CCol cols="6" lg="6">
                    <b>{{ $t('profile.Current_points') }}</b>
                    <div class="d-flex mt-1">
                      <div class="d-flex align-items-center currency">
                        <img v-show="currentPointsCurrency === 'points'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                        <i v-show="currentPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                        <span class="ml-1 points">{{currentPointsCurrency === 'points' ? currentPoints : formatToCurrency(currentPoints)}}</span>
                      </div>
                      <!-- <div @click="switchCurrentPointsCurrency()" class="ml-2 d-flex align-items-center pointer">
                        <i class="fa-solid fa-repeat"></i>
                      </div> -->
                    </div>
                  </CCol>
                  <CCol cols="6" lg="6">
                    <b>{{ $t('profile.Total_points_gathered') }}</b>
                    <div class="d-flex mt-1">
                      <div class="d-flex align-items-center currency">
                        <img v-show="totalPointsCurrency === 'points'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                        <i v-show="totalPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>    
                        <span class="ml-1 points">{{totalPointsCurrency === 'points' ? totalPoints : formatToCurrency(totalPoints)}}</span>
                      </div>
                      <!-- <div @click="switchTotalPointsCurrency()" class="ml-2 d-flex align-items-center pointer">
                        <i class="fa-solid fa-repeat"></i>
                      </div> -->
                    </div>                      
                  </CCol>
                </CRow>
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <b>{{$t('profile.Products_ordered_by2')}} {{profile.name}}</b>
                  </CCol>
                  <CCol cols="12" lg="12" v-bind:class="{'p-0' : orders.length > 0}">
                    <CRow v-if="orders.length > 0" class="products_ordered" :gutters="false">
                      <CCol v-for="order in orders" v-bind:key="order.post_id" cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                        <loyaltyStoreOrder :order="order"/>
                      </CCol>
                    </CRow>
                    <CRow v-else>
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{profile.name}} {{ $t('profile.no_orders2') }}</span>
                      </CCol>
                    </CRow>              
                  </CCol>                         
                </CRow>
              </div>
              <div v-else-if="platformPermissionsLoaded" class="pt-0 pb-0">
                <noPermission class="m-0" trigger="permission"/>
              </div>
            </template>
          </b-tab-item>
          <b-tab-item v-if="modules.employee_loyalty_programme == 1">
            <template slot="header">
              <span>Transactions</span>
            </template>
            <template>
              <div v-if="platformPermissionsLoaded && checkPermission('loyalty.insights.userpoints')">
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" v-bind:class="{'p-0' : pointUpdates.length > 0}">
                    <CRow v-if="pointUpdates.length > 0" class="products_ordered" :gutters="false">
                      <CCol v-for="(update, index) in pointUpdates" v-bind:key="index" cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                        <loyaltyPointsUpdate :update="update"/>
                      </CCol>
                    </CRow>
                    <CRow v-else class="w-100 m-0">
                      <CCol cols="12" lg="12">
                        <span>{{$t('profile.No_transactions')}}</span>
                      </CCol>
                    </CRow>              
                  </CCol>                         
                </CRow>
              </div>
              <div v-else-if="platformPermissionsLoaded" class="pt-0 pb-0">
                <noPermission class="m-0" trigger="permission"/>
              </div>
            </template>
          </b-tab-item>                 
          <b-tab-item v-if="modules.social_wall == 1">
            <template slot="header">
              <span>Company Topics</span>
            </template>
            <template>
              <div v-if="platformPermissionsLoaded && checkPermission('connect.sw.overview')">
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pb-0">
                    <b>{{$t('sw.latest_posts_from_user')}} {{profile.name}}</b>
                  </CCol>
                  <CCol cols="12" lg="12" v-bind:class="{'p-0' : posts.length > 0}">
                    <CRow v-if="posts.length > 0" class="latest_posts" :gutters="false">
                      <CCol v-for="(post, index) in posts" v-bind:key="post.post_id" cols="12" sm="12" md="6" lg="6" class="pt-0 pb-0">
                        <swPostCard :ref="'swPostCard' + index"
                                    :postData="post"
                                    :modules="modules"
                                    :platformPermissions="platformPermissions"
                                    :navigatable="true"
                                    :editable="false"
                                    :previewable="false"
                                    :preview="false"
                                    :showReplies="false"
                                    :showInsights="true"
                                    :scrollableContent="false"
                                    :openedInExp="true">
                        </swPostCard>
                      </CCol>
                    </CRow>
                    <CRow v-else>
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{profile.name}} {{ $t('profile.no_sw_posts2') }}</span>
                      </CCol>
                    </CRow>              
                  </CCol>                         
                </CRow>
                <CRow class="w-100 m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <b>{{$t('sw.latest_replies_from_user')}} {{profile.name}}</b>
                  </CCol>
                  <CCol cols="12" lg="12" v-bind:class="{'p-0' : replies.length > 0}">
                    <CRow v-if="replies.length > 0" class="latest_replies" :gutters="false">
                      <CCol v-for="reply in replies" v-bind:key="reply.post_reply_id" cols="12" sm="12" md="6" lg="6" class="pt-0 pb-0">
                        <CCard class="socialwall">
                          <CCardBody>
                            <CRow>
                              <CCol cols="12" sm="12" md="12" lg="12" class="posted_by pt-0">
                                <span style="font-size: 0.8em;">
                                  {{$t('sw.user_replied_on')}} <a @click="checkPermission('connect.sw.postinsights') ? showPostInsights(reply.post_id_external) : null"><b v-html="reply.post_title"></b></a> {{$t('sw.replied_at')}} {{reply.created | moment("dddd DD-MM HH:mm")}}
                                </span>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol cols="12" sm="12" md="12" lg="12" class="content_text pt-0 pb-0">
                                <p class="m-0" style="font-size: 0.9em;">{{reply.content}}</p>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      </CCol>  
                    </CRow>
                    <CRow v-else>
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{profile.name}} {{ $t('profile.no_replies2') }}</span>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
              <div v-else-if="platformPermissionsLoaded" class="pt-0 pb-0">
                <noPermission class="m-0" trigger="permission"/>
              </div>
            </template>
          </b-tab-item>
          <b-tab-item v-if="modules.academy == 1">
            <template slot="header">
              <span>Learn</span>
            </template>
            <template>
              <CRow v-if="profile.finished_onboarding === 0" class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <b>{{$t('profile.Unfinished_onboarding')}}</b>
                  <span class="d-block mt-1">{{profile.name}} {{$t('profile.has_not_finished_onboarding')}}</span>
                  <div class="d-flex align-items-center mt-2">
                    <CButton class="m-0" color="primary" @click="markOnboardingFinished();"><i class="fas fa-check mr-1"/>{{$t('profile.Mark_onboarding_finished')}}</CButton>
                  </div>                         
                </CCol>
              </CRow>
    
              <CRow v-if="checkPermission('learn.catalog.overview')" class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <b>{{$t('profile.followed_courses')}}</b>
                </CCol>
                <CCol cols="12" lg="12" v-bind:class="{'p-0' : courses.length > 0}">
                  <CRow v-if="courses.length > 0" class="lms courses followed_courses" :gutters="false">
                    <CCol v-for="course in courses" v-bind:key="course.lms_course_id_external" cols="6" lg="6" class="pt-0 pb-0">
                      <CCard class="mb-0">
                        <CCardBody class="pt-0 pb-0">
                          <CRow>
                            <CCol class="meta">
                              <div class="pr-2">
                                <div v-if="course.image_id && course.from_marketplace === 'N'" 
                                     class="content_picture" 
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + course.lms_course_id_external + '/' + course.image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>
                                <div v-else-if="course.image_id && course.from_marketplace === 'Y'" 
                                     class="content_picture" 
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + course.lms_course_id_external + '/' + course.image_id + '.jpg' + '/' + clientToken + ')' }">
                                </div>                                
                                <div v-else class="course_icon mb-2 text-center">
                                  <f7-icon material="school"></f7-icon>
                                </div>                                        
                              </div>
                              <div class="details">
                                <p v-line-clamp="1" class="title m-0">{{course.course_name}}</p>
                                <p class="category mt-1 mb-0">{{course.course_category_name}}</p>                  
                              </div>
                            </CCol>
                          </CRow>                                             
                          <CRow>
                            <CCol cols="12" lg="12" class="pt-0">
                              <CProgress :max="100" show-value>
                                <CProgressBar :value="(course.duration / course.total_duration) * 100">
                                </CProgressBar>              
                              </CProgress>
                            </CCol>                       
                          </CRow>                                            
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <CRow v-else>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <span>{{profile.name}} {{ $t('profile.no_followed_courses_user') }}</span>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow v-if="checkPermission('learn.assigncourses')" class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <CButton color="primary" class="m-0" @click="assignCollapseOpen = true">
                    {{ $t('profile.assign_new_courses') }}
                  </CButton>                  
                                    
                  <CRow v-if="assignCollapseOpen">
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <CRow v-if="dropdownCourses.length > 0">
                        <CCol cols="12" lg="12" class="pb-0">
                          <label>{{$t('common.Which_courses')}}</label>
                          <multiselect
                            class="data_table"
                            v-model="assignedCourses" 
                            :options="dropdownCourses" 
                            :multiple="true"
                            :placeholder="$t('Search_for_a') + ' ' + $t('common.course')" 
                            :selectLabel="$t('common.Add_course')" 
                            :selectedLabel="$t('Added')"
                            :deselectLabel="$t('common.Remove_course')"
                            track-by="course_name" 
                            label="course_name">
                          </multiselect>                        
                        </CCol>             
                      </CRow>
                      <CRow v-else>
                        <CCol cols="12" lg="12" class="pb-0">
                          <span>{{$t('profile.no_assignable_courses')}} {{profile.name}}</span>                      
                        </CCol>             
                      </CRow>
                      <CRow>
                        <CCol cols="12" lg="12" class="pb-0 text-left">
                          <CButton v-if="assignedCourses.length > 0" color="primary" @click="assignCourses(assignedCourses);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
                          <CButton color="secondary" @click="assignCollapseOpen = false; resetAppointData();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>                          
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </template>
          </b-tab-item>
          <b-tab-item>
            <template slot="header">
              <span>{{$t('profile.Target_groups')}}</span>
            </template>
            <template>
              <CRow v-if="targetGroups.length > 0">
                <CCol cols="12" lg="12">
                  <b-table class="data_table"
                           :data="targetGroups"
                           :striped="true"
                           :hoverable="true"
                           :mobile-cards="true"
                           :paginated="isPaginated"
                           :per-page="perPage"
                           :current-page.sync="currentPage"
                           :pagination-simple="isPaginationSimple"
                           :pagination-position="paginationPosition">
                    
                    <template slot-scope="props">
                      <b-table-column field="title" :label="$t('groups.Target_group')">
                        <span @click="openSidebarRight('target_group', { group_id_external: props.row.group_id_external });" class="pointer">{{props.row.title}}</span>
                      </b-table-column>
                      <b-table-column field="users_included" :label="$t('groups.Users_included')" width="20%">
                        <span>{{props.row.group_members}} {{ props.row.group_members == 1 ? $t('common.user') : $t('common.users') }} </span>
                      </b-table-column>
                      <b-table-column field="actions" width="10%" :visible="checkPermission('core.targetgroups.add')">
                        <div class="d-flex justify-content-lg-end">
                          <CDropdown color="primary" stoggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('target_group', { group_id_external: props.row.group_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-pen"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('groups.Edit_target_group')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('target_group_content', { group_id_external: props.row.group_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fas fa-chevron-right"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('groups.Show_accessible_content')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>                                                            
                          </CDropdown>
                        </div>
                      </b-table-column>                                      
                    </template>           
                  </b-table>
                </CCol>
              </CRow>
              <CRow v-else class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <span>{{profile.name}} {{ $t('profile.no_member_of_target_group') }}</span>
                </CCol>
              </CRow>             
            </template>
          </b-tab-item>          
          <b-tab-item v-if="profile.work_location || profile.hours_on_contract || profile.meyer_briggs || profile.disc || profile.office_based || profile.division || profile.language">
            <template slot="header">
              <span>{{$t('profile.Attributes')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol v-if="profile.work_location" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Work_location') }}</b><br/>
                  <span>{{ profile.work_location }}</span>
                </CCol>
                <CCol v-if="profile.hours_on_contract" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Hours_on_contract') }}</b><br/>
                  <span>{{ profile.hours_on_contract }} <span v-if="profile.hours_on_contract">{{profile.hours_on_contract !== '1' ? $t('common.hours') : $t('common.hour')}}</span></span>              
                </CCol>
                <CCol v-if="profile.meyer_briggs" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Meyer_briggs') }}</b><br/>
                  <span>{{ profile.meyer_briggs }}</span>
                </CCol>
                <CCol v-if="profile.disc" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Disc') }}</b><br/>
                  <span>{{ profile.disc }}</span>
                </CCol>
                <CCol v-if="profile.office_based" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Office_based') }}</b><br/>
                  <span>{{ profile.office_based }}</span>
                </CCol>
                <CCol v-if="profile.division" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Division') }}</b><br/>
                  <span>{{ profile.division }}</span>
                </CCol>
                <CCol v-if="profile.division" cols="4" lg="4" class="pb-0">
                  <b> {{ $t('profile.Language') }}</b><br/>
                  <span>{{ profile.language }}</span>
                </CCol>
              </CRow>           
            </template>
          </b-tab-item>
          <b-tab-item v-if="profile.persona_1 || profile.persona_2 || profile.persona_3">
            <template slot="header">
              <span>{{$t('profile.Personas')}}</span>
            </template>
            <template>
              <CRow v-if="profile.persona_1" class="m-0">      
                <CCol cols="12" lg="12" class="pb-0">
                  <b>{{ $t('profile.Persona_1') }}</b><br/>
                  <span>{{ profile.persona_1 }}</span>
                </CCol>
              </CRow>
              <CRow v-if="profile.persona_2" class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <b>{{ $t('profile.Persona_2') }}</b><br/>
                  <span>{{ profile.persona_2 }}</span>
                </CCol>
              </CRow>
              <CRow v-if="profile.persona_3" class="m-0">
                <CCol cols="12" lg="12" class="pb-0">
                  <b>{{ $t('profile.Persona_3') }}</b><br/>
                  <span>{{ profile.persona_3 }}</span>
                </CCol>                                                
              </CRow>              
            </template>
          </b-tab-item>                                                                 
        </b-tabs>

        <b-modal :can-cancel="['x']" :active.sync="employeeLeftModal" :width="960" scroll="keep">
          <CCard class="mb-0">
            <CCardHeader class="pb-0">
              {{$t('users.Set_date_left_for_employee', { employee_name : employeeLeftModalData.name})}}
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <p class="mb-2_5">{{$t('users.Select_date_left_for_employee')}}</p>
                  <b-datepicker v-model="employeeDateLeft" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions">
                    <template #left>
                      <CButton color="primary" @click="employeeDateLeft = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                    </template>
                    <template #right>
                      <CButton color="secondary" @click="employeeDateLeft = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                    </template>                    
                  </b-datepicker>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton color="primary" @click="updateUserDateLeft(employeeLeftModalData.user_id_external);" :disabled="!employeeDateLeft">
                <i class="fas fa-check mr-1"/>{{$t('Save')}}
              </CButton>
              <CButton color="secondary" @click="employeeLeftModal = false">
                <i class="fas fa-times mr-1"/>{{ $t('cancel') }}
              </CButton>
            </CCardFooter>
          </CCard>
        </b-modal>        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import VueMasonryWall from "vue-masonry-wall";
// import VueMasonry from 'vue-masonry-css'
import truncate from 'vue-truncate-collapsed';

import loadingSpinner from '@/components/common/loadingSpinner.vue'
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import noPermission from '@/components/common/noPermission.vue';

import swPostCard from '@/components/connect/swPostCard.vue';

import loyaltyStoreOrder from '@/components/loyalty/loyaltyStoreOrder.vue';
import loyaltyPointsUpdate from '@/components/loyalty/loyaltyPointsUpdate.vue';
import loyaltyMomentCard from '@/components/loyalty/loyaltyMomentCard.vue';

export default {
  name: 'UserProfile',
  props: ['history', 'userIdExternal'],
  components: {
    Multiselect,
    VueMasonryWall,
    // VueMasonry,
    truncate,
    loadingSpinner,
    userProfileAvatar,
    noPermission,
    swPostCard,
    loyaltyStoreOrder,
    loyaltyPointsUpdate,
    loyaltyMomentCard
  },
  filters: {
    abbr: function(count) {
      let countString = String(count);
      let dotIndex = countString.indexOf(".");

      if(dotIndex > 0) countString = countString.substring(0, dotIndex);

      if(countString.length >= 7) {
        if(count % 1 != 0) {
          return (count/1000000).toFixed(1) + 'M';
        } else {
          return (count/1000000) + 'M';
        }
      } else if(countString.length >= 4) {
        if(count % 1 != 0) {
          return (count/1000).toFixed(1) + 'K';
        } else {
          return (count/1000) + 'K';
        }        
      } else {
        return count;
      }
    }
  },
  watch: {
    $props: {
      handler() {
        this.getUserProfile();
        // Reset data
        this.resetAppointData();
        // Get user target groups
        this.getUserTargetGroups();
        // Check the modules if they are not already loaded
        if(!this.modulesLoaded) this.checkModules();
        // If they are loaded, get the content based on active modules
        if(this.modulesLoaded) {          
          // Get user points and orders if employee_loyalty_programme module is active
          if(this.modules.employee_loyalty_programme == 1) this.getUserPoints(); this.getUserOrders(); this.getUserPointsUpdates();
          // Get user posts and replies if social_wall module is active
          if(this.modules.social_wall == 1) this.getUserPosts(); this.getUserReplies();
          // Get followed and assignable courses if academy module is active
          if(this.modules.academy == 1) this.getUserCourses(); this.getAssignableCourses();
        }
      },
      deep: true,
      immediate: true,
    }
  },   
  data() {
    return {
      modules: {
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0,
        profile_include_phone: 0,
        profile_include_email: 0,
        connect_chats: 0
      },
      modulesLoaded: false,   
      platformPermissions: [],
      platformPermissionsLoaded: false,
      platformRoles: [],
      platformRolesLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      profile: { work_location: null },
      profileImageUrl: null,
      profileLoaded: false,
      targetGroups: [],
      currentPointsCurrency: 'points',
      currentPoints: 0,
      totalPointsCurrency: 'points',
      totalPoints: 0,
      orders: [],
      pointUpdates: [],   
      courses: [],
      dropdownCourses: [],
      posts: [],
      replies: [],
      newChat: {
        thread_type_tag: 'connect_chat',
        subject: null,
        description: null,
        employee: null
      },      
      maxPoints: 5000,
      maxTeamPoints: 50000,
      assignedCourses: [],
      assignCollapseOpen: false,
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxGridImages: 3,
      maxContentLength: 200,
      lightboxImages: [],
      lightboxActiveIndex: null,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 8,
      employeeLeftModal: false,
      employeeLeftModalData: {},
      employeeDateLeft: null,
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      }          
    }
  },
  methods: {
    getUserProfile() {
      axios.get('v1/core/profile/user/' + this.userIdExternal)
      .then(res => {
        this.profile = res.data.data;
        // Set the profile image if available
        if(this.profile.profile_image && this.profile.original_company_id_external && this.profile.original_company_id_external !== this.profile.company_id_external) {
          this.profileImageUrl = this.apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + this.profile.user_id_external + '/' + this.profile.profile_image + '/' + this.clientToken;
        } else if(this.profile.profile_image !== null) {
          this.profileImageUrl = this.apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + this.profile.user_id_external + '/' + this.profile.profile_image + '/' + this.clientToken;
        } 
        // Update the profileLoaded value
        this.profileLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserTargetGroups() {
      axios.get('v1/core/targetgroups/user/' + this.userIdExternal)
      .then(res => {
        this.targetGroups = res.data.data;
        // Check if the pagination should be activated
        (this.targetGroups.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserPoints() {
      axios.get('v1/loyalty/points/user/' + this.userIdExternal)
      .then(res => {
        this.currentPoints = res.data.data.personal_points;
        this.totalPoints = res.data.data.total_points;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserOrders() {
      axios.get('v1/loyalty/orders/user/' + this.userIdExternal)
      .then(res => {
        this.orders = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserPointsUpdates() {
      axios.get('/v1/loyalty/points/updates')
      .then(res => {
        this.pointUpdates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },              
    getUserCourses() {
      axios.get('v1/learn/courses/user/' + this.userIdExternal)
      .then(res => {
        this.courses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserPosts() {
      axios.get(process.env.VUE_APP_API_URL + 'v1/connect/socialwall/posts/user/' + this.userIdExternal)
      .then(res => {
        this.posts = res.data.data;      
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserReplies() {
      axios.get('v1/connect/socialwall/replies/user/' + this.userIdExternal)
      .then(res => {
        this.replies = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    getAssignableCourses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/courses/assignable/user/' + this.userIdExternal)
      .then(res => {
        this.dropdownCourses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    assignCourses(data) {
      let params = {}
      params.assigned_courses = data;

      axios.post('v1/core/profile/course/assign/user/' + this.userIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('lms.Course_assigned'), type: 'is-success', duration: 2000 });
        this.resetAppointData();
        this.getUserCourses();
        this.assignCollapseOpen = false;
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    resetAppointData() {
      this.assignedCourses = [];
    },
    markOnboardingFinished() {
      axios.put(process.env.VUE_APP_API_URL + '/v1/learn/progress/user/' + this.userIdExternal + '/onboarding/finish')
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('learn.Marked_onboarding_finished'), type: 'is-success', duration: 2000 });        
        // Emit marked_user_onboarding_finished event
        this.$bus.$emit('marked_user_onboarding_finished', this.userIdExternal);
        // Update the finished_onboarding value
        this.profile.finished_onboarding = 1;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    createChat() {
      // Set the employee of the new chat
      this.newChat.employee = { name: this.profile.name, user_id_external: this.profile.user_id_external };
      // Set the params
      let params = {};
      params.chatData = this.newChat;

      if(params.chatData.thread_type_tag === 'connect_chat' && params.chatData.employee) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('connect.Chat_created'), type: 'is-success', duration: 2000 });
          // Check if the connect page is the current page
          if(this.$router.currentRoute.path !== '/connect/chats') {
            // Set connectChatToActivate in localStorage
            localStorage.setItem('connectChatToActivate', res.data.data.thread_id_external)
            // Navigate to connect chats page
            this.$router.push({path: '/connect/chats'});
          } else {
            // Emit the update_connect_chats event
            this.$bus.$emit('update_connect_chats');
            // Emit the open_connect_chat event
            this.$bus.$emit('open_connect_chat', res.data.data.thread_id_external);                        
          }        
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          if(err.response.data.code === 'Chat already exists') {
            // Check if the connect page is the current page
            if(this.$router.currentRoute.path !== '/connect/chats') {
              // Set connectChatToActivate in localStorage
              localStorage.setItem('connectChatToActivate', err.response.data.data)
              // Navigate to connect chats page
              this.$router.push({path: '/connect/chats'});
            } else {
              // Emit the update_connect_chats event
              this.$bus.$emit('update_connect_chats');
              // Emit the open_connect_chat event
              this.$bus.$emit('open_connect_chat', err.response.data.data);                        
            }
            // Close the sidebar
            this.closeSidebarRight();                  
          } else {
            console.error(err);
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      }
    },      
    updateUserDateLeft(userIdExternal) {
      let params = {};
      params.date_left = this.$luxon(this.employeeDateLeft.toISOString(), "yyyy-MM-dd");

      axios.put(process.env.VUE_APP_API_URL + '/v1/core/user/' + userIdExternal + '/left-company', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.User_updated'), type: 'is-success', duration: 2000 });
        // Close the modal
        this.employeeLeftModal = false;
        // Reset the employeeDateLeft value
        this.employeeDateLeft = null;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    resetUserPassword(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/resetpassword/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('users.Password_reset_link_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    sendVerificationEmail(userIdExternal) {
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/verifiction/email/user/' + userIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('organisation.Welcome_email_sent'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((points/10));
    },
    switchCurrentPointsCurrency() {
      (this.currentPointsCurrency === 'points') ? this.currentPointsCurrency = 'euro' : this.currentPointsCurrency = 'points';
    },
    switchTotalPointsCurrency() {
      (this.totalPointsCurrency === 'points') ? this.totalPointsCurrency = 'euro' : this.totalPointsCurrency = 'points';
    },
    openImageLightbox(images, index) {
      // Set the images
      this.lightboxImages = images;
      // Set the active index
      this.lightboxActiveIndex = index;
    },
    pickImageToUpload() {
      document.getElementById("imageUpload").click()
    },
    onImageChange(files) {
      const file = files[0];
      this.profileImageUrl = URL.createObjectURL(file);
      
      const formData = new FormData();
      const imagefile = document.querySelector('#imageUpload');
      formData.append("image", imagefile.files[0]);

      axios.post(process.env.VUE_APP_API_URL + '/v1/common/upload/image/profiles/' + this.profile.user_id_external, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        // Update the image ID
        this.profile.profile_image = res.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    showPostInsights(id) {
      this.$router.push({path: `/insights/connect/post/${id.toString()}`});
    },     
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },  
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;
        // Update modulesLoaded value
        this.modulesLoaded = true;
        // Get user points and orders if employee_loyalty_programme module is active
        if(this.modules.employee_loyalty_programme == 1) this.getUserPoints(); this.getUserOrders(); this.getUserPointsUpdates();
        // Get user posts and replies if social_wall module is active
        if(this.modules.social_wall == 1) this.getUserPosts(); this.getUserReplies();
        // Get followed and assignable courses if academy module is active
        if(this.modules.academy == 1) this.getUserCourses(); this.getAssignableCourses();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkDateLeft(dateLeft) {      
      if(!dateLeft || (dateLeft && (Date.parse(new Date()) <= Date.parse(dateLeft)))) {
        return true;
      } else{
        return false;
      }
    },    
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },        
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }                   
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getPlatformPermissions();
    this.getPlatformRoles();
  }
}
</script>
