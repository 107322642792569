<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <CSidebarBrand>
      <div class="w-100 h-100 text-center d-table" @click="showHomePage()">
        <div class="d-table-cell align-middle p-3" style="background-color: white;">
          <img :src="getLogoSrc()" style="max-height: 50px;"/>
        </div>
      </div>
    </CSidebarBrand>    
    <TheSidebarMenu ref="sidebarMenu"/>
  </CSidebar>
</template>

<script>
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import TheSidebarMenu from './TheSidebarMenu.vue';

export default {
  name: 'TheSidebar',
  props: ['platformRoles'],
  components: {
    loadingSpinner,
    TheSidebarMenu
  },
  watch: {
    platformRoles: {
      handler() {
        for(var r = 0; r < this.platformRoles.length; r++) {
          if(['loyalty'].includes(this.platformRoles[r])) {
            this.show = false;
          } else {
            this.show = 'responsive';
          }
        }
      },
      deep: true,
      immediate: true,
    }
  },  
  data () {
    return {
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,
      environmentTag: null,
      minimize: false,
      show: 'responsive',
      openQuestionAmount: 0
    }
  },
  methods: {
    getLogoSrc() {
      if(!this.companyImageId) {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/logo/${this.environmentTag}_logo.png/${this.clientToken}`;
      } else {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/${this.companyIdExternal}/${this.companyImageId}.png/${this.clientToken}`;
      }
    },    
    toggleSidebar(e) {
      console.log(e);
    },
    showHomePage() {
      if(this.$router.currentRoute.path !== '/dashboard') {
        this.$router.push({path: '/'});
      }
    }
  },  
  mounted () {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })

    this.$bus.$on('theming_updated', (e) => {
      if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    });

    this.$bus.$on('module_status_updated', () => {      
      if(this.$refs && this.$refs.sidebarMenu) this.$refs.sidebarMenu.reloadSidebarMenu();
    });
  },
  beforeDestroy() {
    this.$bus.$off('theming_updated');
    this.$bus.$off('module_status_updated');
  }
}
</script>
