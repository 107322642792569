<template>
  <div>  
    <CCard class="mb-0 events moments">
      <CCardHeader v-if="showHeader" class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
            <span>{{$t('sm.Smart_sparks')}}</span>
          </CCol>        
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow class="moment_list">
          <CCol cols="12" lg="12" md="12" :class="{'pt-0 pb-0' : !momentsLoading}">
            <div class="card_container" id="masonryWall">
              <loadingSpinner mode="auto" v-if="momentsLoading" :content="$t('common.Loading')"/>

              <vue-masonry-wall v-else-if="!momentsLoading && moments.length > 0" :items="moments" :options="{width: 400}" :ssr="{columns: 2}" class="pt-0 pb-0">
                <template #default="{item}">
                  <loyaltySmartMomentCard v-if="item"
                                          :platformPermissions="platformPermissions"
                                          :modules="modules"
                                          :momentData="item" 
                                          :showEditButton="checkPermission('loyalty.smartmoments.edit')"
                                          :showDeleteButton="checkPermission('loyalty.smartmoments.delete')">
                  </loyaltySmartMomentCard>
                </template>                
              </vue-masonry-wall>

              <div v-else-if="!momentsLoading && moments.length === 0">
                <CRow class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('sm.No_smart_sparks_available')}}</span>
                  </CCol>
                </CRow>
              </div>              
            </div>

            <CButton v-if="showScrollToTopButton" class="scroll_top m-0" color="primary" @click="scrollToTop()">
              <span><i class="fas fa-arrow-up mr-1"/>{{$t('common.Scroll_to_top')}}</span>
            </CButton>            
          </CCol>                  
        </CRow>
      </CCardBody>
    </CCard>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('sm.Remove_smart_spark')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0" v-html="$t('sm.Are_you_sure_to_delete_smart_spark')"></p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deleteSmartMoment(confirmModalData.loyalty_smart_moment_id_external);"><i class="fas fa-trash mr-1"/>{{$t('Delete')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import VueMasonryWall from "vue-masonry-wall";

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import targetGroupFiltersPopover from '@/components/common/targetGroupFiltersPopover.vue'
import loyaltySmartMomentCard from '@/components/loyalty/loyaltySmartMomentCard.vue';
import createLoyaltySmartMomentCard from '@/components/loyalty/createLoyaltySmartMomentCard.vue';

export default {
  name: 'loyaltyMoments',
  props: ['platformPermissions', 'modules', 'showHeader', 'itemsPerPage'],
  components: {
    VueMasonryWall,
    loadingSpinner,
    userProfilePopover,
    targetGroupFiltersPopover,
    loyaltySmartMomentCard,
    createLoyaltySmartMomentCard
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      company: {},
      moments: [],      
      momentsLoading: false,
      momentsLoaded: false,
      showScrollToTopButton: false,
      allMomentsLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      searchEnabled: true,
      hideDelivered: false,
      confirmModal: false,
      confirmModalData: {}      
    }
  },
  methods: {    
    getSmartMoments() {
      // Start the loader
      if(this.momentsLoaded === false) this.momentsLoading = true;
      // Set default moments array
      this.moments = [];
      // Get the current number of items      
      let currentItemNumber = this.moments.length;
      // Get the Moments
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moments/' + currentItemNumber)
      .then(res => {              
        let moments = res.data.data.moments;
        // Add the Moments to the Moments array
        for (let m = 0; m < moments.length; m++) {     
          this.moments.push(moments[m]);    
        }
        // Update the momentsLoaded value
        this.momentsLoaded = true;
        // Stop the loader
        this.momentsLoading = false;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMoreSmartMoments() {
      // Get the current number of items      
      let currentItemNumber = this.moments.length;
      // Get the Moments
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moments/' + currentItemNumber)
      .then(res => {
        let additionalMoments = res.data.data.moments;
        // Check if there are additional Moments available
        if(additionalMoments.length > 0) {
          // If so, add them to the Moments array
          for (let m = 0; m < additionalMoments.length; m++) {      
            this.moments.push(additionalMoments[m]);    
          }
          // Update the showScrollToTopButton value
          this.showScrollToTopButton = true;          
        } else {
          // Update allMomentsLoaded value
          this.allMomentsLoaded = true;
          // Update the showScrollToTopButton value
          this.showScrollToTopButton = true;          
        }     
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSmartMomentDetails(smartMomentIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moment/' + smartMomentIdExternal)
      .then(res => {
        let updatedMoment = res.data.data;
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_smart_moment_id_external == updatedMoment.loyalty_smart_moment_id_external);
        // Update the data    
        this.moments[momentIndex].grant_points = updatedMoment.grant_points;
        this.moments[momentIndex].points = updatedMoment.points;
        this.moments[momentIndex].total_filters = updatedMoment.total_filters;
        this.moments[momentIndex].custom_message = updatedMoment.custom_message;
        this.moments[momentIndex].sender = updatedMoment.sender;
        this.moments[momentIndex].scheduled_for = updatedMoment.scheduled_for;        
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    deleteSmartMoment(momentIdExternal) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moment/' + momentIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('sm.Scheduled_spark_deleted'), type: 'is-success', duration: 2000 });
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_moment_id_external == momentIdExternal);
        // Remove the deleted Moment from the moments array
        this.moments.splice(momentIndex, 1);
        // Close the modal
        this.confirmModal = false;        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    scrollToTop() {
      const appContainer = document.querySelector('.c-app');  
      // Scroll to the top of the element
      appContainer.scrollTo({top: 0, behavior: 'smooth'})
    }                      
  },
  mounted: function() {
    this.getSmartMoments();    

    setTimeout(function(){
      const appContainer = document.querySelector('.c-app');

      appContainer.addEventListener('scroll', e => {
        if(appContainer.scrollTop + appContainer.clientHeight >= appContainer.scrollHeight) {
          this.getMoreSmartMoments();
        }
      });
    }.bind(this), 100);

    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getCompanyDetails();    

    this.$bus.$on('update_loyalty_smart_moments', () => {
      this.getSmartMoments();
    });

    this.$bus.$on('update_loyalty_smart_moment_details', (smartMomentIdExternal) => {
      this.getSmartMomentDetails(smartMomentIdExternal);
    });

    this.$bus.$on('delete_loyalty_smart_moment', (momentData) => {      
      this.confirmModalData = momentData;
      this.confirmModal = true;
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_loyalty_smart_moments');
    this.$bus.$off('update_loyalty_smart_moment_details');
    this.$bus.$off('delete_loyalty_smart_moment');
  }  
}
</script>