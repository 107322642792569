<template>
  <div class="w-100 dropdown language">
    <div class="w-100 locale-changer">
      <multiselect class="data_table"
                   v-model="selectedLanguage" 
                   :options="languages" 
                   :multiple="false"
                   :hide-selected="true"
                   :close-on-select="true"
                   :searchable="false"
                   track-by="language_iso" 
                   label="name"
                   :show-labels="false"
                   @input="updateLanguage();">

        <template slot="singleLabel" slot-scope="language">
          <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
          <span class="language_name">{{ language.option.name }}</span>
        </template>

        <template slot="option" slot-scope="language">
          <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
          <span class="language_name">{{ language.option.name }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'TheHeaderLanguage',
  components: {
    Multiselect
  },
  data () {
    return {
      selectedLanguage: null,
      languages: []
    }
  },
  methods: {
    updateLanguage() {
      setTimeout(function() {
        this.$i18n.locale = this.selectedLanguage.language_iso;
        // Update userPlatformLanguage in localStorage
        localStorage.setItem('userPlatformLanguage', this.$i18n.locale);
        // Emit the language_changed event
        this.$bus.$emit('language_changed');
      }.bind(this), 100);
    },
    getPlatformLanguages() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/languages')
      .then(res => {      
        this.languages = res.data.data;
        // Set default languageCode variable
        let languageCode = null;
        // Set the languageCode value based on local storage of env value
        (localStorage.getItem('userPlatformLanguage') !== null) ? languageCode = localStorage.getItem('userPlatformLanguage') : languageCode = process.env.VUE_APP_I18N_LOCALE;    
        // Get the array index of the selected language
        let languageIndex = this.languages.findIndex(obj => obj.language_iso === languageCode);
        // Set the selectedLanguage value
        this.selectedLanguage = this.languages[languageIndex];         
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }   
  },
  mounted: function() {
    this.getPlatformLanguages();   
  }
}
</script>