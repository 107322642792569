<template>
  <div class="position-relative">
    <div class="sidebar_container socialwall">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{!post.post_id_external ? $t('sw.new_post') : $t('sw.edit_post')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow v-if="post.post_id_external === null" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect
              class="data_table"
              v-model="post.sw_group" 
              :options="swGroups" 
              :multiple="false"
              :close-on-select="true"
              :placeholder="$t('connect.Select_flow2')" 
              :selectLabel="$t('connect.Add_flow2')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('connect.Remove_flow2')"
              track-by="sw_group_id" 
              label="sw_group_name">
              <span slot="noResult">{{$t('sw.no_groups_found2')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <!-- <label>{{$t('sw.Select_subtopic')}}</label> -->
            <multiselect
              class="data_table"
              v-model="post.subtopic" 
              :options="subtopics"
              :multiple="false"
              :placeholder="$t('common.Select_subtopic')" 
              :selectLabel="$t('common.Add_subtopic')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_subtopic')"
              track-by="subtopic_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
            </multiselect>            
          </CCol>            
        </CRow>

        <CRow v-if="senderAliases.length > 0" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('connect.Posting_as')}}</label>
            <multiselect
              class="data_table"
              v-model="post.sender" 
              :options="senderAliases"
              :multiple="false"
              :hide-selected="false"
              :close-on-select="true"
              :placeholder="$t('common.Select_alias')" 
              :selectLabel="$t('common.Add_alias')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_alias')"
              track-by="deliver_sender_id_external" 
              label="sender_alias">
              <span slot="noResult">{{ $t('common.No_aliases_found') }}</span>
            </multiselect>            
          </CCol>            
        </CRow>

        <CRow v-if="post.post_id_external === null && postTypesLoaded" class="m-0">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <span>{{$t('connect.Pick_a_post_type')}}</span>
          </CCol>

          <CCol>
            <div @click="setPostType('article', false)" class="text-center pointer post_type" v-bind:class="{'selected' : post.post_type_tag === 'article'}">
              <div class="mb-1">
                <span class="material-icons">description</span>
              </div>
              <span><b>{{$t('connect.Post_type_article')}}</b></span>
            </div>
          </CCol>
          <CCol v-for="type in postTypes" v-bind:key="type.post_type_tag">
            <div @click="setPostType(type.post_type_tag, true)" class="text-center pointer post_type" v-bind:class="{'selected' : post.post_type_tag === type.post_type_tag}">
              <div class="mb-1">
                <span v-if="type.post_type_tag === 'normal'" class="material-icons">image</span>
                <span v-if="type.post_type_tag === 'video'" class="material-icons">videocam</span>
                <span v-if="type.post_type_tag === 'vote'" class="material-icons">poll</span>
              </div>
              <span><b>{{$t('connect.Post_type_' + type.post_type_tag)}}</b></span>
            </div>
          </CCol>
        </CRow>
        
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.is_breaking_news" size="is-small">{{ $t('sw.breaking_news') }}?</b-switch>                
          </CCol>
        </CRow>

        <div v-if="!post.post_id_external && post.is_breaking_news === true">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.send_email" size="is-small">{{ $t('connect.Send_breaking_news_email') }}?</b-switch>                
            </CCol>
          </CRow>
          <CRow v-if="post.is_breaking_news === true && post.send_email === true"  class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
						  <CTextarea @input="countdown()" rows="3" maxlength="200" :label="$t('communications.Email_content')" class="mb-0" v-model="post.email_content"/>
              <span class="mt-1 float-none countdown" v-if="remainingCount.email_content > 0">{{remainingCount.email_content}} {{ $t('common.characters_remaining') }}</span>
              <div class="w-100 mt-2">                  
                <CButton color="primary" @click="showEmailPreview('breaking_news');" :disabled="!post.email_content">
                  <i class="fas fa-search mr-1"/>{{$t('common.Show_preview')}}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.send_push_notification" size="is-small">{{ $t('connect.Send_breaking_news_push_notification') }}?</b-switch>                
            </CCol>
          </CRow>
          <CRow v-if="modules.push_notifications == 1 && post.is_breaking_news === true && post.send_push_notification === true" class="m-0">
            <CCol cols="4" lg="4" class="pt-0 pb-0">
              <CInput @input="countdown()" rows="2" maxlength="20" :label="$t('communications.Title_for_push')" class="mb-0" v-model="post.push_notification_title"/>
              <p class="countdown mt-1 mb-0" v-if="remainingCount.push_title > 0">{{remainingCount.push_title}} {{ $t('common.characters_remaining') }}</p>
            </CCol>            
            <CCol cols="8" lg="8" class="pt-0">
              <CInput @input="countdown()" rows="2" maxlength="60" :label="$t('communications.Message_for_push')" class="mb-0" v-model="post.push_notification_message"/>
              <p class="countdown mt-1 mb-0" v-if="remainingCount.push_message > 0">{{remainingCount.push_message}} {{ $t('common.characters_remaining') }}</p>
            </CCol>
          </CRow>          
        </div>

        <CRow class="m-0" v-if="showHideButton">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.is_hidden" size="is-small">{{ $t('sw.Hide_post') }}?</b-switch>                
          </CCol>
        </CRow>
        
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">            
            <div class="wysiwyg">                  
              <ckeditor ref="contentEditor" @input="countdown()" :editor="editor" v-model="post.post_content" :config="editorConfig"></ckeditor>
            </div>

            <transition name="slide">
              <div v-if="showMentionField" class="mt-2">
                <multiselect
                  class="data_table"
                  :options="mentionables"
                  :multiple="false"
                  :placeholder="$t('connect.Select_employee_to_mention')" 
                  :selectLabel="$t('connect.Mention_employee')"                   
                  track-by="subtopic_id_external" 
                  label="label"
                  @input="addToMentionList"
                  @search-change="asyncFindMentionables">
                  <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>                   
              </div>
            </transition>

            <div class="mt-2 d-flex align-items-top">
              <div class="flex-grow-1">
                <div>
                  <CDropdown color="primary" toggler-text="" :caret="false" class="post_actions">
                    <div slot="toggler-content">
                      <span class="d-flex align-items-center">
                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                      </span>
                    </div>                                   

                    <CDropdownItem v-if="post.post_type_tag === 'article' || post.post_type_tag === 'normal'">                      
                      <div class="d-flex align-items-center" @click="pickPostImages();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-camera"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('sw.upload_pictures')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>

                    <CDropdownItem>
                      <div class="d-flex align-items-center" @click="post.pdf_attachment === null && post.new_pdf_attachments.length == 0 ? pickPostPdf() : removePDFAttachment();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-file-pdf' : post.pdf_attachment === null && post.new_pdf_attachments.length == 0, 'fa-times' : post.pdf_attachment || post.new_pdf_attachments.length > 0}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{post.pdf_attachment === null && post.new_pdf_attachments.length == 0 ? $t('sw.upload_pdf_attachment') : $t('sw.remove_pdf_attachment')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>

                    <CDropdownItem>
                      <div class="d-flex align-items-center" @click="post.audio_attachment === null && post.new_audio_attachments.length == 0 ? pickPostAudio() : removeAudioAttachment();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-file-audio' : post.audio_attachment === null && post.new_audio_attachments.length == 0, 'fa-times' : post.audio_attachment || post.new_audio_attachments.length > 0}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{post.audio_attachment === null && post.new_audio_attachments.length == 0 ? $t('sw.upload_audio_attachment') : $t('sw.remove_audio_attachment')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>

                    <CDropdownItem>
                      <div class="d-flex align-items-center" @click="toggleExternalUrlPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-link' : !post.add_external_url, 'fa-times' : post.add_external_url}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.add_external_url ? $t('sw.add_external_url') : $t('sw.Remove_external_url')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>                    

                    <CDropdownItem v-if="modules.social_kb == 1 && post.post_type_tag !== 'vote'">
                      <div class="d-flex align-items-center" @click="toggleKbItemPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-folder-open' : !post.add_reference_to_kb_item, 'fa-times' : post.add_reference_to_kb_item}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.add_reference_to_kb_item ? $t('sw.add_reference_to_kb_item') : $t('sw.Remove_knowledge_reference')}}</span>
                        </div>
                      </div>                      
                    </CDropdownItem>

                    <CDropdownItem v-if="modules.academy == 1 && post.post_type_tag !== 'vote'">
                      <div class="d-flex align-items-center" @click="toggleLearningPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-user-graduate' : !post.add_reference_to_lms_course, 'fa-times' : post.add_reference_to_lms_course}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.add_reference_to_lms_course ? $t('sw.add_reference_to_lms_course') : $t('sw.Remove_learn_reference')}}</span>
                        </div>
                      </div>                                     
                    </CDropdownItem>

                    <CDropdownItem v-if="modules.employee_loyalty_programme == 1 && post.post_type_tag !== 'vote'">
                      <div class="d-flex align-items-center" @click="toggleStoreProductPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-store-alt' : !post.add_reference_to_loyalty_store_product, 'fa-times' : post.add_reference_to_loyalty_store_product}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.add_reference_to_loyalty_store_product ? $t('sw.Add_reference_to_loyalty_store_product2') : $t('sw.Remove_loyalty_store_product_reference2')}}</span>
                        </div>
                      </div>                                     
                    </CDropdownItem>

                    <CDropdownItem v-if="modules.satisquestions == 1 && modules.social_wall_satisquestions == 1 && post.post_type_tag !== 'vote'">
                      <div class="d-flex align-items-center" @click="toggleSatisQuestionPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-question-circle' : !post.add_satisquestion_to_answer, 'fa-times' : post.add_satisquestion_to_answer}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.add_satisquestion_to_answer ? $t('sw.Add_satisquestion_to_post') : $t('sw.Remove_satisquestion_from_post')}}</span>
                        </div>
                      </div>                                     
                    </CDropdownItem>                    

                    <CDropdownDivider></CDropdownDivider>

                    <CDropdownItem>
                      <div class="d-flex align-items-center" @click="toggleSchedulePost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-calendar"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.schedule_post ? $t('sw.schedule_post') : $t('sw.Publish_directly')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>

                    <CDropdownItem v-if="!post.post_id_external || post.dashboard_post === 1">
                      <div class="d-flex align-items-center" @click="toggleReactionsPost();">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas" v-bind:class="{'fa-comment' : !post.disable_reactions, 'fa-comment-slash' : post.disable_reactions}"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{!post.disable_reactions ? $t('sw.post_reactions_enabled') : $t('sw.post_reactions_disabled')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                    
                    <CDropdownItem v-if="post.sw_group && 
                                         ((post.post_type_tag === 'article' && post.post_content) ||
                                         (post.post_type_tag === 'normal' && (post.post_content || post.new_post_images.length > 0)) ||  
                                         (post.post_type_tag === 'video' && (post.video_id || postVideos.new_post_videos.length > 0)) ||
                                         (post.post_type_tag === 'vote' && post.post_content))"
                                   @click="postPreviewModal = true;">
                      <div class="d-flex align-items-center">
                        <div class="post_action_icon text-center mr-1">
                          <i class="fas fa-search"/>
                        </div>
                        <div class="flex-grow-1">
                          <span>{{$t('common.Show_preview')}}</span>
                        </div>
                      </div>
                    </CDropdownItem>
                  </CDropdown>
                  <CButton :disabled="post.post_id_external === null && !post.sw_group" color="primary" @click="toggleMentionField();">
                    <i class="fas mr-1" v-bind:class="{'fa-at' : !showMentionField, 'fa-times' : showMentionField}"></i>
                    <span>{{ !showMentionField ? $t('connect.Mention_an_employee') : $t('connect.Do_not_mention_an_employee') }}</span>
                  </CButton>
                </div>                
              </div>
              <div class="flex-grow-1">
                <span class="countdown" v-if="remainingCount.post_content > 0">
                  {{remainingCount.post_content}} {{ $t('common.characters_remaining') }}
                </span>
                <span class="countdown warning" v-if="remainingCount.post_content < 0">
                  {{post.post_content.length - maxCount.post_content}} {{$t('common.characters_too_much')}}
                </span>
              </div>                              
            </div>                                   
          </CCol>
        </CRow>

        <div v-if="post.post_type_tag === 'article' || post.post_type_tag === 'normal'">
          <CRow v-if="post.post_uses_gallery === false" class="m-0">
            <CCol cols="6" lg="6" class="pt-0" v-bind:class="{'pb-0' : post.image === null}">
              <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': postImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setPostDataImage">
                <label for="fileInput" slot="upload-label" class="m-0">
                  <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{postImageUploaded ? $t('sw.replace_picture') : $t('sw.upload_picture')}}</span>
                </label>
              </image-uploader>
            </CCol>
            <CCol v-if="post.post_id_external && post.image !== null" cols="6" lg="6" class="pt-0">
              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-post/' + post.post_id_external + '/' + post.image + '/' + clientToken" class="current_image"/>
            </CCol>
          </CRow>                          
          <CRow v-else class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="post.new_post_images.length > 0" class="images_preview d-flex">
                <div v-for="(image, index) in post.new_post_images" v-bind:key="index" class="image">
                  <img :src="image.image_url">
                  <i class="fas fa-times" @click="removePostImage(image, index)"/>
                </div>
              </div>
              <div>
                <input id="imageUpload" multiple type="file" accept="image/*" :value="post.uploaded_post_images" @change="onPostImagesChange" hidden>
              </div>
            </CCol>
            <CCol v-if="post.post_id_external && post.post_images.length > 0" cols="12" lg="12" class="pt-0 pb-0">
              <div class="current_images d-flex">
                <div v-for="(image, index) in post.post_images" v-bind:key="image.post_image_id" class="image">
                  <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-post/' + post.post_id_external + '/' + image.file + '/' + clientToken"/>
                  <i class="fas fa-times" @click="removePostImage(image, index)"/>
                </div>
              </div>
            </CCol>              
          </CRow>
        </div>

        <div v-if="post.post_type_tag === 'video'">
          <div v-if="post.post_id_external === null">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="postVideos.new_post_videos.length > 0" class="videos_preview">
                  <div v-for="(video, index) in postVideos.new_post_videos" v-bind:key="index" class="video">
                    <vue-plyr ref="plyr_preview">
                      <video controls crossorigin playsinline>
                        <source :src="video.video_url"/>
                      </video>
                    </vue-plyr>              
                    <i class="fas fa-times" @click="removePostVideo(index)"/>
                  </div>
                </div>                
                <div v-if="postVideos.new_post_videos.length == 0">
                  <CRow v-if="!post.add_youtube_video">
                    <CCol cols="12" lg="12" class="pt-0">                      
                      <input id="videoUpload" type="file" accept="video/*" :value="post.uploaded_post_videos" @change="onPostVideosChange" hidden>
                      <CButton color="primary" @click="pickPostVideos();"><i class="fas fa-video mr-1"/>{{$t('sw.upload_video')}}</CButton>
                      <CButton color="primary" @click="post.add_youtube_video = true;"><i class="fab fa-youtube mr-1"/>{{$t('sw.Add_youtube_video')}}</CButton>
                    </CCol>
                  </CRow>
                  <CRow v-if="post.add_youtube_video">
                    <CCol cols="12" lg="12" class="pt-0">
                      <label>{{$t('sw.youtube_video_id_input')}}</label>
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <CInput type="text" class="mb-0" v-model="post.video_id" required was-validated/>
                        </div>
                        <div class="pl-2 pointer">
                          <i class="fas fa-times" @click="removePostYoutubeVideo()"/>
                        </div>                        
                      </div>
                      <div class="meta mt-1">
                        <span>{{ $t('sw.youtube_video_id_input_explainer') }}</span>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            </CRow>              
          </div>
          <div v-else-if="post.post_id_external && post.post_videos.length > 0">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <label>{{ $t('sw.current_video') }}</label>
                <div class="current_videos" v-bind:class="{ 'mb-2' : post.post_videos.length > 0 }">
                  <div v-for="video in post.post_videos" v-bind:key="video.post_video_id" class="video">                        
                    <div v-if="video.video_available === 'Y'">
                      <div v-if="video.video_provider_tag === 'youtube'">
                        <youtube :video-id="video.provider_video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
                      </div>
                      <div v-if="video.video_provider_tag === 'dyntube'" class="dyntube_iframe_container" v-bind:style="{ paddingTop: video.video_container_padding_percentage + '%' }">
                        <iframe class="dyntube_responsive_iframe" webkitallowfullscreen mozallowfullscreen allowfullscreen :src="'https://play.dyntube.io/iframe/' + video.provider_video_private"></iframe>                      
                      </div>
                    </div>
                    <videoPlaceholder v-else emoji="📹" :content="$t('common.Video_almost_available')"/>                        
                  </div>
                </div>
              </CCol>
            </CRow>              
          </div>
        </div>

        <CRow v-if="post.schedule_post === true" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">                     
            <p v-if="postScheduledDatetime !== null" class="mb-2">{{$t('sw.post_scheduled_for')}}: {{postScheduledDatetime | moment("DD-MM-YYYY HH:mm")}}</p>
            <b-datetimepicker v-model="postScheduledDatetime" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions">
              <template #left>
                <CButton color="primary" @click="postScheduledDatetime = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
              </template>
              <template #right>
                <CButton color="secondary" @click="postScheduledDatetime = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
              </template>                    
            </b-datetimepicker>
          </CCol>
        </CRow>

        <CRow v-if="modules.social_kb == 1 && post.add_reference_to_kb_item === true && post.post_type_tag !== 'vote'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect                        
              class="data_table"
              v-model="post.intent_reference" 
              :options="intents" 
              :multiple="false"                
              :placeholder="$t('sw.select_kb_item')" 
              :selectLabel="$t('common.Add_item')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_item')"
              track-by="kb_intent_id_external" 
              label="kb_intent_title"
              @search-change="asyncFindIntent">
              <span slot="noResult">{{ $t('common.no_items_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                    
            </multiselect>            
          </CCol>
        </CRow>

        <CRow v-if="modules.academy == 1 && post.add_reference_to_lms_course === true && post.post_type_tag !== 'vote'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect                        
              class="data_table"
              v-model="post.course_reference" 
              :options="courses" 
              :multiple="false"                
              :placeholder="$t('sw.select_learn_item')" 
              :selectLabel="$t('common.Add_course')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_course')"
              track-by="lms_course_id_external" 
              label="course_name"
              @search-change="asyncFindCourse">
              <span slot="noResult">{{ $t('common.no_courses_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>               
          </CCol>
        </CRow>

        <CRow v-if="modules.employee_loyalty_programme == 1 && post.add_reference_to_loyalty_store_product === true && post.post_type_tag !== 'vote'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect                        
              class="data_table"
              v-model="post.product_reference" 
              :options="products" 
              :multiple="false"                
              :placeholder="$t('sw.select_loyalty_store_product2')" 
              :selectLabel="$t('common.Add_product')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_product')"
              track-by="loyalty_store_product_id_external" 
              label="product_name"
              @search-change="asyncFindStoreProduct">
              <span slot="noResult">{{ $t('common.no_products_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>               
          </CCol>
        </CRow>        

        <CRow v-if="modules.satisquestions == 1 && modules.social_wall_satisquestions == 1 && post.add_satisquestion_to_answer === true && post.post_type_tag !== 'vote'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect class="data_table"
                          v-model="post.satisquestion_to_answer" 
                         :options="satisquestions" 
                         :multiple="false"
                         :placeholder="$t('sw.Select_satisquestion')" 
                         :selectLabel="$t('sq.Add_satisquestion')" 
                         :selectedLabel="$t('Added')"
                         :deselectLabel="$t('sq.Remove_satisquestion')"
                         track-by="sq_question_id_external" 
                         label="label"
                         @search-change="asyncFindSatisQuestion">
              <span slot="noResult">{{ $t('sq.no_satisquestions_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>
          </CCol>
        </CRow>      

        <CRow v-if="post.add_external_url === true" class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('sw.external_url')" class="mb-0" v-model="post.external_url"/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('sw.external_url_name')" class="mb-0" v-model="post.external_url_name"/>
          </CCol>
        </CRow>
        
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <input id="pdfUpload" type="file" accept="application/pdf" :value="post.uploaded_pdf_file" @change="onPostPdfChange" hidden>

            <CRow v-if="post.new_pdf_attachments.length > 0 || post.pdf_attachment">
              <CCol v-if="post.pdf_attachment !== null" cols="12" lg="12" class="pt-0">
                <CButton color="primary" @click="openExternalLink(apiBaseUrl + '/v1/common/cdn/file/pdf/connect-post/' + post.post_id_external + '/' + post.pdf_attachment + '/' + clientToken);">
                  <i class="fas fa-file-pdf mr-1"/>{{$t('common.open_pdf')}}
                </CButton>                  
              </CCol>              
              <CCol cols="12" lg="12" class="pt-0">
                <CInput type="text" :label="$t('sw.pdf_attachment_name')" class="m-0" v-model="post.pdf_attachment_name"/>
              </CCol>
              <CCol cols="12" lg="12" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.pdf_available_for_download" size="is-small">{{ $t('common.Available_for_download') }}?</b-switch>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <input id="audioUpload" type="file" accept="audio/mpeg" :value="post.uploaded_audio_file" @change="onPostAudioChange" hidden>

            <CRow v-if="post.new_audio_attachments.length > 0 || post.audio_attachment">
              <CCol v-if="apiBaseUrl && clientToken && post.audio_attachment" cols="12" lg="12" class="pt-0">
                <audioPlayer :standalone="true"
                             :audioFileName="null"
                             :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/connect-post/' + post.post_id_external + '/' + post.audio_attachment + '/' + clientToken">
                </audioPlayer>                 
              </CCol>
              <CCol v-if="post.new_audio_attachments.length > 0" cols="12" lg="12" class="pt-0">
                <audioPlayer v-for="(audio, index) in post.new_audio_attachments" 
                             v-bind:key="index" 
                             :standalone="true"
                             :audioFileName="null"
                             :audioSourceUrl="audio.audio_url">
                </audioPlayer>               
              </CCol>
              <CCol cols="12" lg="12" class="pt-0">
                <CInput type="text" :label="$t('sw.audio_attachment_name')" class="m-0" v-model="post.audio_attachment_name"/>
              </CCol>
              <CCol cols="12" lg="12" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0" v-model="post.audio_available_for_download" size="is-small">{{ $t('common.Available_for_download') }}?</b-switch>
              </CCol>
            </CRow>
          </CCol>
        </CRow>        
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!post.post_id_external">
              <CButton color="primary" @click="insertPost(post);"><i class="fas fa-check mr-1"/>{{$t('sw.Create_post')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updatePost(post);"><i class="fas fa-check mr-1"/>{{$t('sw.Update_post')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>  
          </CCol>
        </CRow>
      </div>
    </div>
    <div v-if="uploadingFiles === true" class="spinner w-100 h-100 d-table position-absolute uploading_files" style="top: 0; left: 0">
      <div class="d-table-cell align-middle text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
        <p class="mt-2 mb-0"><b>{{ $t('sw.uploading_files') }}</b></p>
      </div>
    </div>

    <b-modal class="email_preview position-absolute" :can-cancel="['x']" :active.sync="emailPreviewModal" :width="600" scroll="keep">
      <CCard class="mb-0">
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-html="emailPreviewModalData.email_content"></div>           
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="emailPreviewModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal class="post_preview position-absolute" :can-cancel="['x']" :active.sync="postPreviewModal" :width="450" scroll="keep">
      <CCard class="mb-0">
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <swPostPreviewCard :postData="post" :videoData="postVideos" :modules="modules" :preview="true" :scrollableContent="true"/>             
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="postPreviewModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>     
  </div>  
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import ImageUploader from 'vue-image-upload-resize';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';
import { v4 as uuidv4 } from 'uuid';

import videoPlaceholder from '@/components/common/videoPlaceholder.vue';
import audioPlayer from '@/components/common/audioPlayer.vue';

import swPostPreviewCard from '@/components/connect/swPostPreviewCard.vue';

export default {
  name: 'swPostDetails',
  props: ['history', 'postIdExternal', 'scheduledForDatetime', 'backToPreview'],
  components: {
    Multiselect,
    ImageUploader,
    videoPlaceholder,
    swPostPreviewCard,
    audioPlayer
  },
  watch: {
    $props: {
      handler() {
        this.getPostDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      modules: {
        academy: 0,
        social_kb: 0,
        push_notifications: 0
      },
      apiBaseUrl: null,
      clientToken: null,    
      post: {
        post_images: [],
        new_post_images: [],
        new_pdf_attachments: [],
        new_audio_attachments: [],
        post_type_tag: 'article',
        add_external_url: false
      },
      postVideos: {
        post_videos: [],
        new_post_videos: []
      },
      showMentionField: false,
      showHideButton: false,
      postImageUploaded: false,
      mentionables: [],  
      postTypesLoaded: false,
      postTypes: [],
      swGroups: [],
      defaultSWGroupId: null,
      defaultSWGroupIdExternal: null,
      defaultSWGroupName: null,   
      subtopics: [],
      senderAliases: [],
      courses: [],
      intents: [],
      products: [],
      satisquestions: [],
      postScheduledDatetime: null,        
      uploadingFiles: false,
      sendPushNotifications: true,
      maxCount: {
        post_content: 5000,
        push_title: 20,
        push_message: 60,
        email_content: 200
      },
      remainingCount: {
        post_content: 5000,
        push_title: 20,
        push_message: 60,
        email_content: 200
      },      
      editor: ClassicEditor,
      editorConfig: {
        placeholder: this.$t('sw.Write_your_content_here'),
        toolbar: [ 'undo', 'redo', 'bulletedList', 'numberedList' ],
      },
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },      
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      maxVideoFilesize: 5368709120,
      maxImageFilesize: 10485760,
      maxAttachmentFilesize: 10485760,
      emailPreviewModal: false,
      emailPreviewModalData: {},
      postPreviewModal: false
    }
  },
  methods: {
    getPostDetails() {
      if(this.postIdExternal !== null) {  
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + this.postIdExternal)
        .then(res => {
          this.post = res.data.data;
          // Set the post content
          (this.post.content === null) ? this.post.post_content = "" : this.post.post_content = this.post.content;        
          // Trigger countdown function to calculate remaining characters for content
          this.countdown();
          // Check if the post is already scheduled
          if(this.post.scheduled !== null) {
            // If the schedule post switch is set to false, set it to true
            if(this.post.schedule_post === false) this.post.schedule_post = true;
            // Set the value of the datetime picker
            this.postScheduledDatetime = new Date(this.post.scheduled);
          }

          this.showHideButton = true;
        })
        .catch(err => {
          console.error(err); 
        });   
      } else {
        // Reset the post
        this.resetPostData()
        // Check if the post is scheduled for a certain date
        if(this.scheduledForDatetime) {
          // Update the schedule_post value of the post
          this.post.schedule_post = true;
          // Set the postScheduledDatetime value
          this.postScheduledDatetime = this.scheduledForDatetime;
        }
      }
    },
    insertPost() {
      let params = {};
      params = this.post;
      params.is_created_from_dashboard = true;                                            
      // Update the content param
      params.content = this.post.post_content;

      if(this.post.post_type_tag === 'article') params.post_type_tag = 'normal';

      (this.post.is_hidden == false) ? params.active = 'Y' : params.active = 'N';
      (this.post.pdf_available_for_download == false) ? params.pdf_attachment_downloadable = 0 : params.pdf_attachment_downloadable = 1;
      (this.post.audio_available_for_download == false) ? params.audio_attachment_downloadable = 0 : params.audio_attachment_downloadable = 1;
      (this.post.is_breaking_news === false) ? params.breaking_news = 0 : params.breaking_news = 1;
      (this.post.disable_reactions === false) ? params.reactions_disabled = 0 : params.reactions_disabled = 1;
      (this.post.add_reference_to_kb_item === true && this.post.intent_reference !== undefined) ? params.kb_intent_id_external = params.intent_reference.kb_intent_id_external : params.kb_intent_id_external = null;
      (this.post.add_reference_to_lms_course === true && this.post.course_reference !== undefined) ? params.lms_course_id_external = params.course_reference.lms_course_id_external : params.lms_course_id_external = null;
      (this.post.add_reference_to_loyalty_store_product === true && this.post.product_reference !== undefined) ? params.loyalty_store_product_id_external = params.product_reference.loyalty_store_product_id_external : params.loyalty_store_product_id_external = null;
      (this.post.add_satisquestion_to_answer === true && this.post.satisquestion_to_answer !== undefined) ? params.sq_question_id_external = params.satisquestion_to_answer.sq_question_id_external : params.sq_question_id_external = null;
      (this.post.schedule_post === true) ? params.scheduled = params.scheduled_datetime : params.scheduled = null;

      if(!params.sw_group) {
        params.sw_group = {
          sw_group_id: this.defaultSWGroupId,
          sw_group_id_external: this.defaultSWGroupIdExternal,
          sw_group_name: this.defaultSWGroupName
        };
      }

      // Set external ID for post in flow
      params.sw_group.post_external_id_for_group = uuidv4();
    
      // Check the mentioned employees
      if(params.mentions) {
        for(var m = 0; m < params.mentions.length; m++) {
          let mention = '@' + params.mentions[m].name;
          // Check if the mention still exists in the content
          if(params.content.includes(mention) === false) {
            // If not, get the index of the mention
            let mentionIndex = params.mentions.findIndex(obj => obj.name === params.mentions[m].name);          
            // Remove the mention from the mentions array
            if(mentionIndex >= 0) params.mentions.splice(mentionIndex, 1);
          }
        }
      }

      let videoParams = {};
      videoParams = this.postVideos;

      // Get scheduled datetime elements to create the correct datetime string
      if(this.post.schedule_post === true && this.postScheduledDatetime !== null) {
        params.scheduled_datetime = this.$luxon(this.postScheduledDatetime.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }

      if(this.validatePost(params, videoParams) === true) {
        if(this.post.content.length <= this.maxCount.post_content) {
          // Check if the post type tag is 'video'
          if(params.post_type_tag === 'video') {
            // Set the insert_video param
            params.insert_video = 1;
            // Check if the post contains new videos
            if(videoParams.new_post_videos.length > 0) {
              // Set the video_provider_tag value to 'dyntube'
              params.video_provider_tag = 'dyntube';            
            } else {
              // Set the video_provider_tag value to 'youtube'
              params.video_provider_tag = 'youtube';
            }
          } else {
            // Delete the video_provider_tag and insert_video params
            delete params['video_provider_tag'];
            delete params['insert_video'];
          }
 
          axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post', params)
          .then(res => {
            let createdPostTypeTag = res.data.data.created_post_type_tag;

            if(createdPostTypeTag === 'video' && res.data.data.video.active == 'N') {
              // Video upload start after SW Post created and the video is not active (so it should be uploaded first)
              // Emit the new_video_uploading event
              this.$bus.$emit('new_video_uploading', res.data.data.video.external_id);
            }

            // Handle the file upload if the new post contains files
            if(params.new_post_images.length > 0 || params.new_pdf_attachments.length > 0 || params.new_audio_attachments.length > 0) {
              // Open the loader
              this.uploadingFiles = true;
              // Get the external ID for the post in the sw group
              let externalPostId = params.sw_group.post_external_id_for_group;
              // Create new formdata              
              const formData = new FormData();
              // Loop through the new post images and append the them to the formdata
              for(var i = 0; i < params.new_post_images.length; i++) {
                formData.append("image_" + i, params.new_post_images[i]);
              }
              // Loop through the new pdf attachments and append the them to the formdata
              for(var i = 0; i < params.new_pdf_attachments.length; i++) {
                formData.append("pdf_" + i, params.new_pdf_attachments[i]);
              }
              // Loop through the new audio attachments and append the them to the formdata
              for(var i = 0; i < params.new_audio_attachments.length; i++) {
                formData.append("audio_" + i, params.new_audio_attachments[i]);
              }
              // Upload the images and/or pdf attachments
              axios.post('v1/common/upload/files/sw/' + externalPostId, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Finish the post insert
                this.finishPostAction(true, 'insert');
              })
              .catch(err => {
                console.error(err); 
              });              
            } else {
              // Finish the post insert
              this.finishPostAction(false, 'insert');
            }

            if(createdPostTypeTag === 'video' && res.data.data.video.active === 'N') {
              // Video upload start after SW Post created and the video is not active (so it should be uploaded first.)
              // Create new formdata              
              const videoFormData = new FormData();
              // Loop through the new post videos and append them to the video formdata
              for(var i = 0; i < videoParams.new_post_videos.length; i++) {
                videoFormData.append("video_" + i, videoParams.new_post_videos[i]);
              }            
              // Upload the videos
              axios.post('v1/common/upload/videos/connect/' + res.data.data.video.external_id, videoFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Emit the video_upload_finished event
                this.$bus.$emit('video_upload_finished', res.data.data.uploaded_post_video_id_external);
              })
              .catch(err => {
                console.error(err); 
              });
            }                                     
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })        
        } else {
          this.$buefy.toast.open({ message: this.$t('sw.Content_too_long'), type: 'is-danger', duration: 2000 });
        }    
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updatePost() {
      let params = {};
      params = this.post;
      let videoParams = {};

      if(this.post.post_type_tag === 'article') params.post_type_tag = 'normal';

      (params.is_hidden == false) ? params.active = 'Y' : params.active = 'N';
      (this.post.pdf_available_for_download == false) ? params.pdf_attachment_downloadable = 0 : params.pdf_attachment_downloadable = 1;
      (this.post.audio_available_for_download == false) ? params.audio_attachment_downloadable = 0 : params.audio_attachment_downloadable = 1;
      (params.is_breaking_news === false) ? params.breaking_news = 0 : params.breaking_news = 1;
      (params.disable_reactions === false) ? params.reactions_disabled = 0 : params.reactions_disabled = 1;
      (params.add_reference_to_kb_item === true && params.intent_reference !== undefined) ? params.kb_intent_id_external = params.intent_reference.kb_intent_id_external : params.kb_intent_id_external = null;
      (params.add_reference_to_lms_course === true && params.course_reference !== undefined) ? params.lms_course_id_external = params.course_reference.lms_course_id_external : params.lms_course_id_external = null;
      (params.add_reference_to_loyalty_store_product === true && params.product_reference !== undefined) ? params.loyalty_store_product_id_external = params.product_reference.loyalty_store_product_id_external : params.loyalty_store_product_id_external = null;
      (params.add_satisquestion_to_answer === true && params.satisquestion_to_answer !== undefined) ? params.sq_question_id_external = params.satisquestion_to_answer.sq_question_id_external : params.sq_question_id_external = null;

      params.content = params.post_content; 

      // Check the mentioned employees
      if(params.mentions) {
        for(var m = 0; m < params.mentions.length; m++) {
          let mention = '@' + params.mentions[m].name;
          // Check if the mention still exists in the content
          if(params.content.includes(mention) === false) {
            // If not, get the index of the mention
            let mentionIndex = params.mentions.findIndex(obj => obj.name === params.mentions[m].name);          
            // Remove the mention from the mentions array
            if(mentionIndex >= 0) params.mentions.splice(mentionIndex, 1);
          }
        }
      }
      
      if(params.add_external_url === false) {
        params.external_url = null;
        params.external_url_name = null;        
      }            

      // Get scheduled datetime elements to create the correct datetime string
      if(params.schedule_post === true && this.postScheduledDatetime !== null) {
        params.scheduled_datetime = this.$luxon(this.postScheduledDatetime.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }

      if(this.validatePost(params, videoParams) === true) {
        if(params.content.length <= this.maxCount.post_content) {
          axios.put(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + this.postIdExternal, params)
          .then(res => {
            // Handle the file upload if the post contains files
            if(params.new_post_images.length > 0 || params.new_pdf_attachments.length > 0 || params.new_audio_attachments.length > 0) {
              // Open the loader
              this.uploadingFiles = true;              
              // Create new formdata              
              const formData = new FormData();
              // Loop through the images and append the images to the formdata
              for(var i = 0; i < params.new_post_images.length; i++) {
                formData.append("image_" + i, params.new_post_images[i]);
              }
              // Loop through the attachments and append the attachments to the formdata
              for(var i = 0; i < params.new_pdf_attachments.length; i++) {
                formData.append("pdf_" + i, params.new_pdf_attachments[i]);
              }
              // Loop through the new audio attachments and append the them to the formdata
              for(var i = 0; i < params.new_audio_attachments.length; i++) {
                formData.append("audio_" + i, params.new_audio_attachments[i]);
              }              
              // Upload the files
              axios.post('v1/common/upload/files/sw/' + params.post_id_external, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Finish the post update
                this.finishPostAction(true, 'update');
              })
              .catch(err => {
                console.error(err); 
              });
            } else {
              // Finish the post update
              this.finishPostAction(false, 'update');
            }
          })
          .catch(err => {
            console.error(err);
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('sw.Content_too_long'), type: 'is-danger', duration: 2000 });
        }
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    validatePost (params, videoParams) {
      let post_id = params.post_id;
      // let title = params.title;
      let post_type_tag = params.post_type_tag;
      let video_id = params.video_id;
      let sw_group = params.sw_group;
      let content = params.content;

      let post_videos = [];
      let new_post_videos = [];

      // Check if the external post ID is available and update the (new)_post_videos value with the correct values
      (this.postIdExternal) ? post_videos = params.post_videos : post_videos = videoParams.post_videos;
      (this.postIdExternal) ? new_post_videos = params.new_post_videos : new_post_videos = videoParams.new_post_videos;

      // Check if base information for the post is available
      if(post_id !== null || (post_id === null && sw_group)) {        
        // If tag is 'video', check if there is a post video available or a new post video is uploaded
        if(post_type_tag === 'article' || post_type_tag === 'normal') {
          return true;
        } else if(post_type_tag === 'video' && (video_id || post_videos.length > 0 || (post_videos.length == 0 && new_post_videos.length > 0))) {
          return true;        
        } if(post_type_tag === 'vote' && content) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    finishPostAction(loaderActive, action) {
      // Close the loader if it is active
      if(loaderActive) this.uploadingFiles = false;      
      // Check the action
      if(action === 'insert') {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sw.Post_added2'), type: 'is-success', duration: 2000 });
        // Emit the sw_post_inserted event
        this.$bus.$emit('sw_post_inserted');

      } else if(action === 'update') {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sw.Post_updated2'), type: 'is-success', duration: 2000 });
        // Emit the sw_post_updated event    
        this.$bus.$emit('sw_post_updated', this.postIdExternal);
      }

      if(this.backToPreview === true) {
        this.removeSidebarHistoryStep();
      } else {
        // Reset the post
        this.resetPostData();
        // Reset the postScheduledDatetime value
        this.postScheduledDatetime = null;
        // Close the sidebar
        this.closeSidebarRight();
      }
    },
    getPostTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/posts/types/dashboard')
      .then(res => {
        this.postTypes = res.data.data;
        // Update the postTypesLoaded value
        this.postTypesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getSWGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flows/multiselect')
      .then(res => {
        this.swGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCompanyDefaultSWGroup() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flow/default')
      .then(res => {
        this.defaultSWGroupId = res.data.data.sw_group_id;
        this.defaultSWGroupIdExternal = res.data.data.sw_group_id_external;        
        this.defaultSWGroupName = res.data.data.sw_group_name;
      })
      .catch(err => {
        console.error(err); 
      });   
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSenderAliases() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender/aliases/my')
      .then(res => {
        this.senderAliases = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },     
    resetPostData() {
      this.post = {
        post_id_external: null,
        title: null,
        post_content: '',
        post_type_tag: 'article',
        sw_group: null,
        is_breaking_news: false,
        // send_push_notification: this.sendPushNotifications,
        send_push_notification: false,
        push_notification_title: 'Breaking News',
        push_notification_message: '',
        add_reference_to_kb_item: false,
        add_reference_to_lms_course: false,
        add_reference_to_loyalty_store_product: false,
        add_satisquestion_to_answer: false,
        kb_intent_id_external: null,
        lms_course_id_external: null,
        post_uses_gallery: true,
        add_youtube_video: false,
        video_id: null,
        post_images: [],
        new_post_images: [],
        new_pdf_attachments: [],
        new_audio_attachments: [],
        satisquestion: null,
        add_external_url: false,        
        external_url: null,
        external_url_name: null,
        pdf_attachment: null,
        pdf_attachment_name: null,
        audio_attachment: null,
        audio_attachment_name: null,
        scheduled: null,
        schedule_post: false,
        scheduled_datetime: null,
        disable_reactions: false      
      };

      this.postVideos = {
        post_videos: [],
        new_post_videos: []
      };

      this.postImageUploaded = false;
    },
    asyncFindIntent(searchTerm) {
      this.intents = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/knowledge/items/' + searchTerm)
        .then(res => {      
          this.intents = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindCourse(searchTerm) {
      this.courses = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/learn/courses/' + searchTerm)
        .then(res => {      
          this.courses = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindStoreProduct(searchTerm) {
      this.products = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/loyalty/store/products/' + searchTerm)
        .then(res => {      
          this.products = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindSatisQuestion(searchTerm) {
      this.satisquestions = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/measure/satisquestions/' + searchTerm)
        .then(res => {      
          this.satisquestions = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindMentionables(searchTerm) {
      let params = {};
      params.search_term = searchTerm;
      // Reset the swGroupIdExternal value
      let swGroupIdExternal = null;
      // Set the swGroupIdExternal value
      (this.post.post_id_external) ? swGroupIdExternal = this.post.sw_group_id_external : swGroupIdExternal = this.post.sw_group.sw_group_id_external;
      // Search for mentionables
      if(searchTerm.length >= 2 && swGroupIdExternal) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/common/search/users/mentionable/flow/' + swGroupIdExternal, params)
        .then(res => {
          this.mentionables = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }
    },
    toggleMentionField() {
      // Reset the mentionables array
      this.mentionables = [];
      // Update the showMentionField value
      (!this.showMentionField) ? this.showMentionField = true : this.showMentionField = false;
    },
    addToMentionList(mention) {
      // Create the mentions array if not already available
      if(!this.post.mentions) this.post.mentions = [];
      // Add the mention to the metions array
      this.post.mentions.push(mention);
      // Define the instance of the ckEditor
      let ckEditor = (this.$refs.contentEditor).$_instance;
      // Add the mention to the current position of the cursus
      ckEditor.model.change((writer) => {
        // Get the current cursor position
        const insertPosition = ckEditor.model.document.selection.getFirstPosition();
        // Insert the mention on the current cursor position
        writer.insertText("@" + mention.name, insertPosition);
      });
      // Reset the showMentionField value
      this.showMentionField = false;
    },       
    pickPostImages() {
      document.getElementById("imageUpload").click()
    },
    onPostImagesChange(e) {
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an image URL for the file
        files[i].image_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxImageFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_post_images array of the post
          this.post.new_post_images.push(files[i]);
        }
      }
    },
    setPostDataImage(output) {
      this.postImageUploaded = true;
      this.post.post_image = output.dataUrl;      
    },
    removePostImage(data, index) {
      if(data.post_image_id !== null && data.post_image_id !== undefined) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/deleteImage', data)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Image_removed'), type: 'is-success', duration: 2000 });
          this.post.post_images = res.data.data;
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.post.new_post_images.splice(index, 1);
      }
    },
    pickPostVideos() {
      document.getElementById("videoUpload").click()
    },
    removePostVideo(index) {
      this.postVideos.new_post_videos.splice(index, 1);
    },
    removePostYoutubeVideo() {
      this.post.add_youtube_video = false;
      this.post.video_id = null;
    },
    onPostVideosChange(e) {
      // Get the files
      let files = e.target.files;
      // Loop through the files
      for(var i = 0; i < files.length; i++) {                
        // Create an video URL for the file
        files[i].video_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxVideoFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxVideoFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_post_videos array of the postVideos
          this.postVideos.new_post_videos.push(files[i]);
        }
      }
    },
    pickPostPdf() {
      document.getElementById("pdfUpload").click()
    },
    onPostPdfChange(e) {
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an image URL for the file
        files[i].pdf_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxAttachmentFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxAttachmentFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_pdf_attachments array of the post
          this.post.new_pdf_attachments.push(files[i]);
        }
      }
    },
    removePDFAttachment() {
      if(this.postIdExternal && this.post.pdf_attachment) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + this.postIdExternal + '/attachment/remove/pdf')
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.PDF_removed'), type: 'is-success', duration: 2000 });
          this.post.pdf_attachment = null;
          this.post.pdf_attachment_name = null;
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.post.new_pdf_attachments = [];
        this.post.pdf_attachment = null;
        this.post.pdf_attachment_name = null;
      }
    },
    pickPostAudio() {
      document.getElementById("audioUpload").click()
    },
    onPostAudioChange(e) {
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an image URL for the file
        files[i].audio_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxAttachmentFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxAttachmentFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_audio_attachments array of the post
          this.post.new_audio_attachments.push(files[i]);
        }
      }
    },
    removeAudioAttachment() {
      if(this.postIdExternal && this.post.audio_attachment) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + this.postIdExternal + '/attachment/remove/audio')
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Audio_file_removed'), type: 'is-success', duration: 2000 });
          this.post.audio_attachment = null;
          this.post.audio_attachment_name = null;
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.post.new_audio_attachments = [];
        this.post.audio_attachment = null;
        this.post.audio_attachment_name = null;
      }
    },
    toggleReactionsPost() {
      if(this.post.disable_reactions === false) {
        this.post.disable_reactions = true;
      } else {
        this.post.disable_reactions = false;
      }
    },
    toggleKbItemPost() {
      if(this.post.add_reference_to_kb_item === false) {
        this.post.add_reference_to_kb_item = true;
      } else {
        this.post.add_reference_to_kb_item = false;
      }
    },
    toggleLearningPost() {
      if(this.post.add_reference_to_lms_course === false) {
        this.post.add_reference_to_lms_course = true;
      } else {
        this.post.add_reference_to_lms_course = false;
      }
    },
    toggleStoreProductPost() {
      if(this.post.add_reference_to_loyalty_store_product === false) {
        this.post.add_reference_to_loyalty_store_product = true;
      } else {
        this.post.add_reference_to_loyalty_store_product = false;
      }
    },
    toggleSatisQuestionPost() {
      if(this.post.add_satisquestion_to_answer === false) {
        this.post.add_satisquestion_to_answer = true;
      } else {
        this.post.add_satisquestion_to_answer = false;
      }
    },        
    toggleExternalUrlPost() {
      if(this.post.add_external_url === false) {
        this.post.add_external_url = true;
      } else {
        this.post.add_external_url = false;
      }
    },
    toggleSchedulePost() {
      if(this.post.schedule_post === false) {
        this.post.schedule_post = true;
        this.postScheduledDatetime = new Date();
      } else {
        this.post.schedule_post = false;
        this.postScheduledDatetime = null;
      }
    },
    setPostType(postTypeTag, triggerFilePicker) {
      if(postTypeTag !== this.post.post_type_tag)  {
        this.post.post_type_tag = postTypeTag;

        if(postTypeTag === 'article' || postTypeTag === 'normal' || postTypeTag === 'vote') {
          this.postVideos.new_post_videos = [];
          this.post.video_id = null;
          this.post.add_youtube_video = false;    
        }
        
        if(postTypeTag === 'article' || postTypeTag === 'normal' || postTypeTag === 'video') {
          this.maxCount.post_content = 5000;
          this.remainingCount.post_content = 5000;
        }

        if(postTypeTag === 'video' || postTypeTag === 'vote') {
          this.post.new_post_images = [];
        }

        if(postTypeTag === 'vote') {
          this.maxCount.post_content = 200;
          this.remainingCount.post_content = 200;        
        }

        if(postTypeTag === 'normal') {
          if(triggerFilePicker) {
            setTimeout(function(){
              this.pickPostImages();
            }.bind(this), 250);
          }        
        }
      }
    },
    formatFileSize(bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    },
    countdown() {
      this.remainingCount.post_content = this.maxCount.post_content - this.post.post_content.length;

      if(!this.post.post_id_external) { 
        if(this.post.send_push_notification) {
          this.remainingCount.push_title = this.maxCount.push_title - this.post.push_notification_title.length;
          this.remainingCount.push_message = this.maxCount.push_message - this.post.push_notification_message.length;
        }
        if(this.post.send_email) this.remainingCount.email_content = this.maxCount.email_content - this.post.email_content.length;
      }
    },           
    wysiwygUpload(editor) {
      var args = editor.config._config.upload;

      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    },          
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;        
        // Check Push Notifications Module
        (this.modules.push_notifications == 1) ? this.sendPushNotifications = true : this.sendPushNotifications = false;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showEmailPreview(eventTypeTag) {
      let params = {};
      params.email_preview_content = this.post.email_content;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/connect/' + eventTypeTag + '/preview', params)
      .then(res => {
        // Set the emailPreviewModalData
        this.emailPreviewModalData = res.data.data;
        // Open the modal
        this.emailPreviewModal = true;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    // Check the modules
    this.checkModules();
    this.getPostTypes();
    this.getSWGroups();
    this.getCompanyDefaultSWGroup();
    this.getSubtopics();
    this.getSenderAliases();
  }
}
</script>